import * as React from 'react';
import { Ability, AbilityBuilder, AbilityClass, CanParameters } from '@casl/ability';
import { PartialDeep } from 'type-fest';
import { ClientObject } from 'api/endpoints/clients';
import { DatasetObject } from 'api/endpoints/datasets';
import { UsergroupObject } from 'api/endpoints/usergroups';
import { DashboardObject } from 'api/endpoints/dashboards';
import { UserObject, UserRoleTypes, roleForUser } from 'api/endpoints/users';
import { createContextualCan } from '@casl/react';


export type Actions = 'create' | 'read' | 'update' | 'delete' | 'list' | 'manage';
export type Subjects =
  | (PartialDeep<ClientObject> & { __caslSubjectType__: 'Client' })
  | 'Client'
  | (PartialDeep<DatasetObject> & { __caslSubjectType__: 'Dataset' })
  | 'Dataset'
  | (PartialDeep<UsergroupObject> & { __caslSubjectType__: 'Usergroup' })
  | 'Usergroup'
  | (PartialDeep<DashboardObject> & { __caslSubjectType__: 'Dashboard' })
  | 'Dashboard'
  | (PartialDeep<UserObject> & { __caslSubjectType__: 'User' })
  | 'User'
  | 'all';

export type TAppAbility = Ability<[Actions, Subjects]>;
export type TAppAbilityCanParams = CanParameters<[Actions, Subjects]>;
const AppAbility = Ability as AbilityClass<TAppAbility>;

export const defineAbilityFor = (user?: UserObject) => {
  const {
    can: allow,
    cannot: forbid,
    build,
    } = new AbilityBuilder(AppAbility);

  if (user) {
    const clientIds: string[] = user.clients.map(client => client.id);
    const usergroupIds: string[] = user.usergroups.map(usergroup => usergroup.id);

    const userPermissions = () => {
      // TODO: dahboard permissions should leverage usergroups
      allow('list', 'Dashboard', { client_id: { $in: clientIds }});
      allow('read', 'Dashboard', { client_id: { $in: clientIds }});

      // Can update its own profile
      allow('update', 'User', { id: user.id });
    }

    const siteAdminPermissions = () => {
      // Can modify users belonging to same client and dataset
      allow('manage', 'Client', { id: { $in: clientIds } });
      //allow('manage', 'User', { clients: { $in: user.clients } });
      allow('manage', 'User', { datasets: { $in: user.datasets } });
      forbid('manage', 'User', { role: UserRoleTypes.SuperAdmin });
      forbid('manage', 'User', { role: UserRoleTypes.GroupAdmin });
      forbid('manage', 'User', { role: UserRoleTypes.SiteAdmin });
    }

    const groupAdminPermissions = () => {
      // Can modify users and usergroups belonging to same client
      allow('manage', 'Client', { id: { $in: clientIds } });
      allow('manage', 'User', { clients: { $in: user.clients } });
      allow('manage', 'Usergroup', { client_id: { $in: clientIds } });
      forbid('manage', 'User', { role: UserRoleTypes.SuperAdmin });
    }

    const superAdminPermissions = () => {
      allow('manage', 'all');
      // Cannot delete self
      forbid('delete', 'User', { id: user.id });
    }

    if (roleForUser(user).role === UserRoleTypes.User) {
      userPermissions();
    } else if (roleForUser(user).role === UserRoleTypes.SiteAdmin) {
      userPermissions();
      siteAdminPermissions();
    } else if (roleForUser(user).role === UserRoleTypes.GroupAdmin) {
      userPermissions();
      groupAdminPermissions();
    } else if (roleForUser(user).role === UserRoleTypes.SuperAdmin) {
      superAdminPermissions();
    }
  }

  return build();
};

export const AuthorizationContext = React.createContext<TAppAbility>(new AbilityBuilder(AppAbility).build());
export const Can = createContextualCan<TAppAbility>(AuthorizationContext.Consumer);
