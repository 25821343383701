import * as React from 'react';
import { ReactComponent as IconDashboardSearch } from 'assets/svg/dashboard-search.svg';
import { ReactComponent as IconWarning } from 'assets/svg/warning.svg';


export enum ErrorBlockStyle {
  General = "general",
  NoDashboards = "nodashboards",
}

export interface IErrorBlockProps {
  title: string;
  message: string;
  style?: ErrorBlockStyle;
}

function iconForErrorBlockStyle(style: ErrorBlockStyle | undefined) {
  let iconStyle = style ?? ErrorBlockStyle.General;
  switch (iconStyle) {
    case ErrorBlockStyle.NoDashboards: {
      return <IconDashboardSearch className="o-svg-icon" />;
    }

    case ErrorBlockStyle.General:
    default: {
      return <IconWarning className="o-svg-icon" />;
    }
  }
}

const ErrorBlock = function (props: IErrorBlockProps) {
  return (
    <div className="c-page-message__wrapper">
      <div className="c-page-message">
        <div className="c-page-message__figure">
          {iconForErrorBlockStyle(props.style)}
        </div>
        <p className="c-page-message__title">{props.title}</p>
        <p dangerouslySetInnerHTML={{__html: props.message}}></p>
      </div>
    </div>
  );
}

export default ErrorBlock;
