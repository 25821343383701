import api from 'api';
import { DeviceObject } from 'api/endpoints/devices';
import { HttpError } from 'api/http-client';
import config from '../../../../../src/client/src/configuration';
import Cookies from 'js-cookie';
import * as React from 'react';
import { createContext, ReactElement, useEffect, useState, useContext } from 'react';
import { store } from 'store';
import { PageContext } from 'components/layout/page-context';

const deviceKeyCookieName = config.DEVICE_KEY_COOKIE_NAME;
const darkModeCookieName = config.DARKMODE_COOKIE_NAME;
const cookieOptions:Cookies.CookieAttributes = { secure: true, sameSite: "None" };

export type IDeviceContext = {
  defaultTheme: number;
  setDefaultTheme: (theme: number) => void;
}

export const DeviceContext = createContext({} as IDeviceContext);

export type IDeviceContextProvider = {
  children: ReactElement;
}

export const DeviceContextProvider = ({ children }: IDeviceContextProvider) => {
  let [refreshTimer, setRefreshTimer] = useState<NodeJS.Timeout>();
  let [defaultTheme, setDefaultTheme] = useState<number>(1);
  const page = useContext(PageContext);

  const initialContext = {
    defaultTheme,
    setDefaultTheme,
  }

  let getDeviceData = () => {
    let deviceKey = Cookies.get(deviceKeyCookieName);
    if (deviceKey && deviceKey !== '') {
      api.devices.getDeviceByKey(deviceKey).fetch()
      .then((device) => {
        if (device) {
          processDeviceData(device);
          setRefreshTimer(setTimeout(getDeviceData, 15000));
        }
      })
      .catch((error: HttpError) => {
        console.log(error);
      });
    }
  };

  let processDeviceData = (device: DeviceObject) => {
    setDefaultTheme(device.theme);
    page.setUsesDarkMode(!!device.theme);
    Cookies.set(darkModeCookieName, device.theme ? '1' : '0', cookieOptions);
  };

  useEffect(()=>{
    getDeviceData();
    
    return () => {
      if (refreshTimer) {
        clearTimeout(refreshTimer);
        setRefreshTimer(undefined);
      }
    };
  }, []);


  return (
    <DeviceContext.Provider value={initialContext}>
      {children}
    </DeviceContext.Provider>
  );
};
