import * as React from 'react';
// Icons
import { ReactComponent as IconAdd } from 'assets/svg/add.svg';

// Components
import DashboardCard from 'components/cards/dashboard';
import Listing from 'components/cards/listing';
import { listingContext, ListingSortOption, SortDirection } from 'components/cards/listing-context';

import api from 'api';
import useRequest from 'api/use-request';
import { useState, Suspense, useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { PageContext } from 'components/layout/page-context';

const DashboardsIndexPage: React.FunctionComponent = () => {
  const page = useContext(PageContext);

  useEffect(() => {
    page.setTitle('Manage Shared Dashboards');
    page.setBackLink();
    page.setShowsClientSwitcher(false);
  });

  let history = useHistory();
  let location = useLocation();
  let searchArgs = queryString.parse(location.search, { parseNumbers: true });

  if (searchArgs.limit === undefined) {
    searchArgs.limit = 12;
  }

  let newDashboardLink = '/dashboards/new/general';

  let [selection, setSelection] = useState<string[]>([]);

  let dashboards = useRequest(api.dashboards.getSharedDashboards(searchArgs));

  let sort_options: Array<ListingSortOption> = [
    // { key: 'order', title: 'Order, ascending', direction: SortDirection.Ascending },
    // { key: 'order', title: 'Order, descending', direction: SortDirection.Descending },
    { key: 'name', title: 'Name, ascending', direction: SortDirection.Ascending },
    { key: 'name', title: 'Name, descending', direction: SortDirection.Descending },
    { key: 'created_at', title: 'Created At, ascending', direction: SortDirection.Ascending },
    { key: 'created_at', title: 'Created At, descending', direction: SortDirection.Descending },
    // { key: 'type', title: 'Type, ascending', direction: SortDirection.Ascending },
    // { key: 'type', title: 'Type, descending', direction: SortDirection.Descending },
  ];

  function deleteDashboards(ids: string[]) {
    let updates: Promise<unknown>[] = [];
    ids.forEach((id) => {
      updates.push(api.dashboards.deleteDashboard({ id }).fetch());
    });
    Promise.all(updates).finally(() => {
      dashboards.mutate();
      setSelection([]);
    });
  }

  function toggleSelectDashboards(ids: string[]) {
    ids.forEach((id) => {
      const index = selection.indexOf(id);
      if (index > -1) {
        selection.splice(index, 1);
      } else {
        selection.push(id);
      }
    });
    setSelection([...selection]);
  }

  function selectAll(shouldSelect: boolean) {
    if (dashboards.data && dashboards.data?.data.length > 0) {
      dashboards.data?.data.forEach((dashboard) => {
        const index = selection.indexOf(dashboard.id);
        if (shouldSelect && index < 0) {
          selection.push(dashboard.id);
        }
        if (!shouldSelect && index > -1) {
          selection.splice(index, 1);
        }
      });
      setSelection([...selection]);
    }
  }

  return (
            <div className="c-page-container o-container-fluid o-container--full">
              <div className="c-page-title">
                <h2 className="c-title--large">Shared Dashboards</h2>
                {dashboards.data && dashboards.data?.data.length > 0 &&
                <>
                  <div onClick={(e) => { e.preventDefault(); history.push(newDashboardLink) }} className="c-button c-button--stroke c-button--primary c-button--icon u-hidden@md"><IconAdd className="o-svg-icon" /></div>
                  <div onClick={(e) => { e.preventDefault(); history.push(newDashboardLink) }} className="c-button c-button--stroke c-button--primary u-hidden u-block@md"><IconAdd className="o-svg-icon" /><span>Add new dashboard</span></div>
                </>
                }
              </div>
              {(dashboards.data && dashboards.data?.data.length < 1 && !searchArgs.q) ? (
                <NoDashboardsPlaceholder />
              ) : (
                <>
                  <listingContext.Provider value={{ ...dashboards, selection, sort_options }}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Listing
                        deleteHandler={deleteDashboards}
                        selectionHandler={toggleSelectDashboards}
                        selectAllHandler={selectAll}
                        settingsKey={'Dashboards'}
                      >
                        {dashboards.data?.data.map((dashboard) =>
                          <DashboardCard
                            deleteHandler={deleteDashboards}
                            selectHandler={toggleSelectDashboards}
                            key={dashboard.id}
                            dashboard={dashboard}
                          />
                        )}
                      </Listing>
                    </Suspense>
                  </listingContext.Provider>
                </>
              )}
            </div>
  );
};

function NoDashboardsPlaceholder() {
  let history = useHistory();
  let location = useLocation();
  let newDashboardLink = location.pathname + '/new/general';

  return (
    <div className="u-mb-spacer-base-large">
      <div className="c-add c-add--link u-flex-nowrap@md">
        <div className="c-add__icon">
          <IconAdd className="o-svg-icon" />
        </div>
        <div className="c-add__body">
          <div className="c-add__title">
            <div onClick={() => { history.push(newDashboardLink) }} className="c-link-cta c-add__link"><span>Add new dashboard</span></div>
          </div>
          <div className="c-add__desc">
            <p>You currently don’t have any shared dashboards added, click here to start.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardsIndexPage;
