import * as React from 'react';

import { Field as FormikField, FieldConfig, useFormikContext } from 'formik';
import { useEffect } from 'react';
import classNames from 'classnames';

import ErrorMessage from './error-message';
import { FormFieldSizing, FormFieldVariant } from './common';

export type SelectFieldProps<T> = JSX.IntrinsicElements['select'] &
  Omit<FieldConfig<T>, 'component' | 'as' | 'render' | 'children' | 'type'> & {
    name: string;
  } & {
    validateOnInit?: boolean;
    label?: React.ReactNode;
    help?: string;
    children: React.ReactChild | React.ReactFragment;
    variant?: FormFieldVariant;
    sizing?: FormFieldSizing;
    isLoading?: boolean;
    valueTransform?: (value: any) => any;
  };

export const SelectField = <T extends any>({
  name,
  validateOnInit,
  label,
  children,
  disabled,
  required = false,
  placeholder,
  multiple,
  help,
  variant = FormFieldVariant.fill,
  sizing,
  valueTransform,
  isLoading = false,
  ...formikFieldProps
}: SelectFieldProps<T>): React.ReactElement => {
  const { setFieldTouched, isSubmitting, getFieldMeta, setFieldValue } = useFormikContext<any>();

  const fieldMeta = getFieldMeta(name);
  const value = fieldMeta.value;
  const errorMsg = fieldMeta.error;

  const hasError = typeof errorMsg !== 'undefined' && fieldMeta.touched;
  const isPlaceholderSelected = !multiple && Boolean(placeholder) && typeof value === 'undefined';

  useEffect(() => {
    if (validateOnInit) {
      setFieldTouched(name, true, true);
    }
  }, [validateOnInit, setFieldTouched, name]);

  return (
    <div className={classNames(['c-form-element', 'c-form-element--select', { ['c-form-element' + variant]: variant, 'c-form-element--error': hasError, ['c-form-element' + sizing]: sizing }])}>
      {label && (
        <label htmlFor={name} className="c-form-label">
          {label}
          {required && ' *'}
        </label>
      )}
      <div className="c-form-element__field">
        { isLoading && (
          <div className="c-form-element__loader"></div>
        )}
        <FormikField
          {...formikFieldProps}
          multiple={multiple}
          name={name}
          as="select"
          disabled={isSubmitting ? true : disabled}
          defaultValue={isPlaceholderSelected ? '' : undefined}
          onChange={(event: { target: { value: string; }; }) => {
            if (valueTransform) {
              setFieldValue(name, valueTransform(event.target.value), false);
            } else {
              setFieldValue(name, event.target.value, false);
            }
          }}
        >
          {!multiple && Boolean(placeholder) && (
            <option value={''} disabled key="__placeholder">
              {placeholder}
            </option>
          )}
          {children}
        </FormikField>
      </div>
      <ErrorMessage name={name} />
      {help && (
        <p className="c-note">{help}</p>
      )}
    </div>
  );
};

export default SelectField;
