import { FieldProps } from 'formik';
import React from 'react';
import Select from "react-select";
import { OptionsType, ValueType } from 'react-select/src/types';

interface Option {
  label: string;
  value: string;
}

interface MultiselectProps extends FieldProps {
  options: OptionsType<Option>;
  isMulti?: boolean;
  onChange?: (selectedValues: string | string[]) => void;
}

export const Multiselect = ({
  field,
  form,
  options,
  isMulti = false,
  onChange
}: MultiselectProps) => {
  const onChangeHandler = (option: ValueType<Option | Option[], boolean>) => {
    let newFieldValue = isMulti
                          ? (option as Option[]).map((item: Option) => item.value)
                          : (option as Option).value
    form.setFieldValue(
      field.name,
      newFieldValue
    );

    if (onChange) {
      onChange(newFieldValue);
    }
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(option => field.value.indexOf(option.value) >= 0)
        : options.find(option => option.value === field.value);
    } else {
      return isMulti ? [] : ("" as any);
    }
  };

  return (
    <Select
      className='c-multiselect-container'
      classNamePrefix='c-multiselect'
      name={field.name}
      value={getValue()}
      onChange={onChangeHandler}
      options={options}
      isMulti={isMulti}
    />
  );
};