import HttpClient from 'api/http-client';
import * as z from 'zod';
import { GetListingRequestSchema, LinksSchema, MetaSchema } from 'api/endpoints/common';
import { DashboardObjectSchema } from '../dashboards';

const dateRegex = /^(?<fullyear>\d{4})-(?<month>0[1-9]|1[0-2])-(?<mday>0[1-9]|[12][0-9]|3[01])T(?<hour>[01][0-9]|2[0-3]):(?<minute>[0-5][0-9]):(?<second>[0-5][0-9]|60)(?<secfrac>\.[0-9]+)?(Z|(\+|-)(?<offset_hour>[01][0-9]|2[0-3]):(?<offset_minute>[0-5][0-9]))$/i;

export const ClientObjectSchema = z.object({
  id: z.string(),
  name: z.string().nonempty(),
  created_at: z.string().regex(dateRegex),
  updated_at: z.string().regex(dateRegex),
  users_count: z.number().int(),
  dashboards_count: z.number().int(),
  datasets_count: z.number().int(),
  logo_url: z.string(),
  logo_dark_url: z.string(),
  first_dashboard_id: z.string().optional(),
  first_dataset_id: z.string().optional(),
  sisense_id: z.string().optional(),
  dashboards: z.array(DashboardObjectSchema).optional()
});

export const CreateClientRequestSchema = z.object({
  id: z.string(),
  name: z.string().nonempty(),
  logo: z.string(),
  logo_dark: z.string(),
  logo_url: z.string().optional(),
  logo_dark_url: z.string().optional(),
});
export const CreateClientResponseSchema = ClientObjectSchema;

export const GetClientsResponseSchema = z.object({
  data: z.array(ClientObjectSchema),
  links: LinksSchema,
  meta: MetaSchema,
});

export const GetClientsRequestSchema = GetListingRequestSchema.extend({
  expand: z.string().optional()
});

export const ClientObjectIdSchema = z.object({
  id: z.string(),
});


export type ClientObject = z.infer<typeof ClientObjectSchema>
export type CreateClientRequest = z.infer<typeof CreateClientRequestSchema>
export type GetClientsRequest = z.infer<typeof GetClientsRequestSchema>
export type GetClientsResponse = z.infer<typeof GetClientsResponseSchema>
export type ClientObjectId = z.infer<typeof ClientObjectIdSchema>

export function getClients(data: GetClientsRequest) {
  return new HttpClient().request<GetClientsResponse>({
    method: 'get',
    url: '/clients',
    validator: GetClientsResponseSchema,
    params: data,
  });
}

export function newClient(data: CreateClientRequest) {
  return new HttpClient().request<ClientObject>({
    method: 'post',
    url: '/clients',
    validator: CreateClientResponseSchema,
    data,
  });
}

export function getClient(data: ClientObjectId) {
  return new HttpClient().request<ClientObject>({
    method: 'get',
    url: `/clients/${data.id}`,
    validator: ClientObjectSchema,
  });
}

export function deleteClient(data: ClientObjectId) {
  return new HttpClient().request({
    method: 'delete',
    url: `/clients/${data.id}`,
    data,
  });
}

export function updateClient(data: CreateClientRequest) {
  return new HttpClient().request<ClientObject>({
    method: 'put',
    url: `/clients/${data.id}`,
    data,
  });
}
