import * as React from 'react';

import { ReactComponent as IconTrash } from 'assets/svg/trash.svg';
import { ReactComponent as IconClose } from 'assets/svg/close.svg';
import { ReactComponent as IconAlertError } from 'assets/svg/alert-error.svg';

import * as z from 'zod';
import api from 'api';
import { Suspense, useContext, useEffect, useState } from 'react';

import useRequest from 'api/use-request';
import queryString from 'query-string';


import { Form, FormNotification } from 'components/form/form';
import { FormFieldVariant } from 'components/form/common';
import InputField, { InputFieldType } from 'components/form/input';
import { SubmitButton } from 'components/form/submit-button';

import { useParams, useHistory, Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import _, { zip } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { debug } from 'utils/debug';
import DeleteConfirmationAlert, { DeleteConfirmationAlertType } from 'components/form/delete-confirmation';
import * as quartzite from 'quartzite';

import { DashboardObject, DashboardObjectRequest, DashboardObjectRequestSchema, DashboardObjectSchema, DashboardType } from 'api/endpoints/dashboards';
import { DashboardInfo } from 'components/dashboard/api-dashboards-provider';
import SelectField from 'components/form/select-field';
import { PageContext } from 'components/layout/page-context';
import { TabsNav, TabsPanel } from 'components/layout/tabs';
import { listingContext, ListingSortOption, ListingType, SortDirection } from 'components/cards/listing-context';
import Listing from 'components/cards/listing';
import ClientToggleCard from 'components/cards/client-toggle';


function formatDate(input: string) {
  const date = new Date(input);
  return quartzite.dateString(date);
}

export interface IDashboardDetailsProps {
  isNew: boolean,
  nextLocation: string,
  onUpdate: () => void,
  isProfile: boolean,
  dashboards: DashboardInfo[] | undefined,
}

function newDashboardObj(index: number = 0): DashboardObjectRequest {
  return _.clone({
    id: '',
    name: '',
    type: DashboardType.powerbi,
    source: '',
    powerbi: '',
    order: index,
    shared: true,
    parent_id: '',
  });
}

export function DashboardDetails({ isNew, nextLocation, onUpdate, isProfile, dashboards}: IDashboardDetailsProps) {
  dashboards = [];
  dashboards.unshift({
    oid: 'PowerBiPages',
    title: 'PowerBiPages',
  })
  dashboards.unshift({
    oid: 'PowerBi',
    title: 'PowerBi',
  })
  let [initialValue, setInitialValue] = useState<DashboardObjectRequest>();
  let [isUploading, setIsUploading] = useState(false);
  let [isDeleting, setIsDeleting] = useState(false);
  let [currentSelection, setCurrentSelection] = useState('');
  let [editedDashboard, setEditedDashboard] = useState({} as DashboardObject);
  let [shouldShowWarning, setShouldShowWarning] = useState(false);

  const page = useContext(PageContext);

  useEffect(() => {
    page.setTitle(isNew ? 'New dashboard' : 'Edit dashboard');
    page.setBackLink();
    page.setShowsClientSwitcher(false);
  });

  let validationSchema = DashboardObjectRequestSchema
    .omit({ id: isNew ? true : undefined, client_id: true, client_logo_url: true, client_logo_dark_url: true, client_name: true, created_at: true, updated_at: true, name: true, type: true, source: true, powerbi: true, shared: true, parent_id: true, number_of_clients: true, })
    .nonstrict();

  let history = useHistory();
  let { dashboardId } = useParams() as {
    dashboardId: string | undefined,
  };

  const submitHandler = (values: any, formikHelpers: any) => {
    values.source = values.powerbi ? values.powerbi : currentSelection;
    const payload: DashboardObjectRequest = {
      id: isNew ? '' : dashboardId,
      name: values.name,
      source: values.source,
      type: currentSelection == 'PowerBi' ? 2 : currentSelection == 'PowerBiPages' ? 3 : DashboardType.sisense,
      order: 0,
      shared: true,
      parent_id: '',
      number_of_clients: 0,
    };

    let update: Promise<DashboardObject>;
    if (isNew) {
      update = api.dashboards.newDashboard(payload).fetch();
    } else {
      update = api.dashboards.updateDashboard(payload).fetch();
    }
    setIsUploading(true);

    return update
      .then((response) => {
        history.push(nextLocation);
        onUpdate();
      })
      .finally(() => {
        setIsUploading(false);
      })
  };

  const loadDashboard = async (dashboardId: string) => {
    let dashboard = await api.dashboards.getDashboard({ id: dashboardId }).fetch();
    if (dashboard) {
      // @ts-ignore
      if(dashboard.type == 2){
        setCurrentSelection('PowerBi');
        // @ts-ignore
        dashboard['powerbi'] = dashboard.source;
      }
      // @ts-ignore
      if(dashboard.type == 3){
        setCurrentSelection('PowerBiPages');
        // @ts-ignore
        dashboard['powerbi'] = dashboard.source;
        dashboard['source'] = 'PowerBiPages';
      }
      setEditedDashboard(dashboard);
      setInitialValue({ ...dashboard });
    }
  }

  const initForm = () => {
    if (!dashboardId) {
      setInitialValue(newDashboardObj());
    } else {
      loadDashboard(dashboardId);
    }
  }

  useEffect(initForm, []);

  useEffect(() => {
    document.body.classList.add('has-modal-open');

    return () => {
      document.body.classList.remove('has-modal-open');
    };
  });

  function deleteEditedDashboard() {
    if (dashboardId) {
      let update = api.dashboards.deleteDashboard({ id: dashboardId }).fetch();
      onUpdate();
      update.then((response) => {
        setIsDeleting(false);
        history.push(nextLocation);
      }).catch((error) => {
        setIsDeleting(false);
        debug(error);
      });
    }
  }

  let match = useRouteMatch();
  let location = useLocation();
  let [selection, setSelection] = useState<string[]>([]);

  let searchArgs = queryString.parse(location.search, { parseNumbers: true });
  let clients = useRequest(api.clients.getClients(dashboardId
    ? { ...searchArgs, expand: `dashboards(parent_id=${dashboardId})`}
    : { ...searchArgs }
  ));

  useEffect(() => {
    setSelection(clients.data?.data.filter((client) => {
      return (client.dashboards?.length ?? []) > 0;
    }).map((client) => client.id) ?? []);
  }, [clients.data])

  let sort_options: Array<ListingSortOption> = [
    { key: 'name', title: 'Name, ascending', direction: SortDirection.Ascending },
    { key: 'name', title: 'Name, descending', direction: SortDirection.Descending },
  ];

  const tabs = [
    { label: 'General', path: `${match.url}/general` },
  ];

  if (editedDashboard && editedDashboard.shared) {
    tabs.push({ label: 'Clients', path: `${match.url}/clients` });
  }

  function editClientDashboardsLink(dashboardId: string, datasetId: string): string {
    return `/dashboard/${dashboardId}/${datasetId}`;
  }

  function selectClient(id: string) {
    setSelection([...selection, id]);
  }

  function selectClients(ids: string[]) {
    setSelection([...selection, ...ids]);
  }

  function unselectClient(id: string) {
    setSelection(selection.filter((item) => {
      return item != id;
    }));
  }

  function unselectClients(ids: string[]) {
    setSelection(selection.filter((item) => {
      return ids.indexOf(item) === -1;
    }));
  }

  function handleToggleOn(id: string) {
    if (!editedDashboard) return;

    selectClient(id);

    const req = api.dashboards.newDashboard({
      id: '',
      name: editedDashboard.name,
      source: editedDashboard.source,
      type: editedDashboard.type || DashboardType.powerbi,
      client_id: id,
      parent_id: editedDashboard.id,
      order: 0,
      shared: false,
    }).fetch()

    req.then(() => {}, () => {
      unselectClient(id);
    });
  }

  function handleToggleOff(id: string) {
    if (!editedDashboard) return;

    unselectClient(id);

    const dashboards = clients.data?.data.find(client => client.id == id)?.dashboards;

    if ((dashboards ?? []).length == 0) return;

    let reqs: Promise<unknown>[] = [];

    dashboards?.forEach(({id}) => {
      reqs.push(api.dashboards.deleteDashboard({id}).fetch())
    })

    Promise.all(reqs).then(() => {}, () => {
      selectClient(id);
    });
  }

  function handleEnableForAll() {
    if (!dashboardId) return;

    const unselectedClients = clients.data?.data.filter((client) => {
      return selection.indexOf(client.id) === -1;
    }).map((client) => client.id);

    if (unselectedClients) {
      selectClients(unselectedClients);
    }

    api.dashboards.enableSharedDashboardForAllClients({id: dashboardId}).fetch().then(() => {}, () => {
      if (unselectedClients) {
        unselectClients(unselectedClients);
      }
    });
  }

  function handleDisableForAll() {
    if (!dashboardId) return;

    const selectedClients = clients.data?.data.filter((client) => {
      return selection.indexOf(client.id) != -1;
    }).map((client) => client.id);

    if (selectedClients) {
      unselectClients(selectedClients);
    }

    api.dashboards.disableSharedDashboardForAllClients({id: dashboardId}).fetch().then(() => {}, () => {
      if (selectedClients) {
        selectClients(selectedClients);
      }
    });
  }

  return (
    <>
      {initialValue ? (
        <div className="c-modal__root">
          <div className="c-modal__wrapper c-modal__wrapper--md">
            <div className="c-modal c-modal--overflow">
              <div className="c-modal__header">
                <div className="c-block c-block--spacing-t-extra-small">
                  <div className="o-container-fluid">
                    <div className="o-row o-row--fluid c-block__row u-flex-row-reverse">
                      <div className="o-col">
                        <div onClick={() => { history.push(nextLocation);  }} className="c-link-cta-basic"><span>Close</span><IconClose className="o-svg-icon" /></div>
                      </div>
                      <div className="o-col u-flex-grow">
                        <p className="c-modal__headline">{isNew ? 'Add' : 'Edit'} dashboard</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="c-modal__main">
                <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
                  <div className="o-container-fluid">
                    <TabsNav tabs={tabs} replaceUrl={false} compact={true}>
                        <Switch>
                          <Route path={tabs[0].path}>
                            <TabsPanel>
                              <Form
                                validationSchema={validationSchema}
                                initialValues={initialValue}
                                onSubmit={submitHandler}
                                enableReinitialize
                              >
                                {({ values, errors, setFieldValue }) => (
                                  < div className="o-row">
                                    <div className="o-col-6@sm o-col-7@md">
                                      <FormNotification />
                                      <div className="u-mb-spacer-base-large">
                                        <h6>General info</h6>
                                        <div className="o-row o-row--small-gutters">
                                          <div className="o-col-12@md o-col-12@lg o-col-12@xl">
                                              <InputField
                                                type={InputFieldType.text}
                                                name={`name`}
                                                placeholder="Name"
                                                label="Name"
                                                variant={FormFieldVariant.fill}
                                                autocomplete={false}
                                              />
                                          </div>
                                        </div>
                                        <div className="o-row o-row--small-gutters">
                                          <div className="o-col-12@md o-col-12@lg o-col-12@xl">
                                            {shouldShowWarning &&
                                              <div className="c-alert c-alert--small@xs c-alert--row@sm c-alert--warning c-color--invert">
                                                <div className="c-alert__col c-alert__icon">
                                                  <IconAlertError className="o-svg-icon" />
                                                </div>
                                                <div className="c-alert__col c-alert__body">
                                                  <p><strong>Warning</strong></p>
                                                  <p>The selected Sisense dashboard is not available. Please select another or <a href="https://locusrobotics.com/get-started/support/">contact your administrator</a>.</p>
                                                </div>
                                                {/* <a href="#" className="c-alert__close"><IconClose className="o-svg-icon" /></a> */}
                                              </div>
                                            }
                                            {dashboards &&
                                              <SelectField
                                                name={`source`}
                                                placeholder="Select a dashboard type"
                                                label="Dashboard"
                                                variant={FormFieldVariant.fill}
                                                valueTransform={(selectedValue) => {
                                                  setCurrentSelection(selectedValue);
                                                }}
                                              >
                                                {/* {shouldShowWarning &&
                                                  <option value=''></option>
                                                } */}
                                                {dashboards.map((dashboard, index) => {
                                                  return (
                                                    <option key={index} value={dashboard.oid}>{dashboard.title}</option>
                                                  )
                                                })}
                                              </SelectField>
                                            }
                                              <InputField
                                              type={InputFieldType.text}
                                              name={`powerbi`}
                                              placeholder="PowerBi Report Id"
                                              label="PowerBi report Id"
                                              variant={FormFieldVariant.fill}
                                              autocomplete={false}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="o-col-6@sm o-col-5@md">
                                      <div className="c-card c-card--bg-light">
                                        <div className="c-card__body">
                                          <div className="c-card__header">
                                            <h6>{isNew ? 'Create' : 'Update'}</h6>
                                            {!isNew && (
                                              <div className="c-card__desc">
                                                {editedDashboard && editedDashboard.updated_at ? (
                                                  <p>This dashboard was last updated {formatDate(editedDashboard.updated_at)}.</p>
                                                ) : (
                                                  <p></p>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                          <div className="o-row o-row--fluid c-button-group">
                                            <div className="o-col">
                                              <SubmitButton disabled={isUploading}><span>{isNew ? 'Create' : 'Update'} dashboard</span></SubmitButton>
                                            </div>
                                            {!isNew &&
                                              <div className="o-col c-button-group__inline">
                                                <div className="c-link-cta-basic" onClick={(event) => { event.preventDefault(); setIsDeleting(true); }}>
                                                  <IconTrash className="o-svg-icon" /><span>Delete dashboard</span>
                                                </div>
                                              </div>
                                            }
                                          </div>
                                        </div>
                                        <DeleteConfirmationAlert
                                          onDelete={deleteEditedDashboard}
                                          onCancel={() => {
                                            setIsDeleting(false);
                                          }}
                                          resource_label={editedDashboard.name}
                                          show={isDeleting}
                                          type={DeleteConfirmationAlertType.Card}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Form>
                            </TabsPanel>
                          </Route>
                          {tabs[1] !== undefined &&
                            <Route path={tabs[1].path}>
                              <TabsPanel>
                                <>
                                  <h6>Clients</h6>
                                  <listingContext.Provider value={{ ...clients, selection, sort_options }}>
                                    <Suspense fallback={<div>Loading...</div>}>
                                      <Listing
                                        customActions={[
                                          {
                                            label: 'Enable for all',
                                            action: () => handleEnableForAll()
                                          },
                                          {
                                            label: 'Disable for all',
                                            action: () => handleDisableForAll()
                                          }
                                        ]}
                                        shouldShowSearch={true}
                                        shouldHideListingTypes={true}
                                        settingsKey={'Clients'}
                                        defaultListingType={ListingType.list}
                                        compact={true}
                                      >
                                        {clients.data?.data.map((client) =>
                                          <ClientToggleCard
                                            compact={true}
                                            client={client}
                                            action={{
                                              label: 'View dashboard',
                                              link: editClientDashboardsLink(client.first_dashboard_id ?? "", client.first_dataset_id ?? "")
                                            }}
                                            handleToggle={(id, nextState) => {
                                              if (nextState == 'on') {
                                                handleToggleOn(id);
                                              } else {
                                                handleToggleOff(id);
                                              }
                                            }}
                                          />
                                        )}
                                      </Listing>
                                    </Suspense>
                                  </listingContext.Provider>
                                </>
                              </TabsPanel>
                            </Route>
                          }
                        </Switch>
                    </TabsNav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <UserEditModalSkeleton />
      )
      }
    </>
  );
}

export function UserEditModalSkeleton() {
  return (
    <div className="c-modal__root">
      <div className="c-modal__wrapper c-modal__wrapper--md">
        <div className="c-modal c-modal--overflow">
          <div className="c-modal__header">
            <div className="c-block c-block--spacing-t-extra-small">
              <div className="o-container-fluid">
                <div className="o-row o-row--fluid c-block__row u-flex-row-reverse">
                  <div className="o-col">
                    <Skeleton width={250} />
                  </div>
                  <div className="o-col u-flex-grow">
                    <p className="c-modal__headline"><Skeleton width={250} /></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="c-modal__main">
            <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
              <div className="o-container-fluid">
                <div className="o-row">
                  <div className="o-col-6@sm o-col-7@md">
                    <div className="u-mb-spacer-base-large">
                      <h6><Skeleton width={250} /></h6>
                      <div className="o-row o-row--small-gutters">
                        <div className="o-col-12 u-mb-spacer-base">
                          <Skeleton width={480} height={60} />
                        </div>
                        <div className="o-col-12 u-mb-spacer-base">
                          <Skeleton width={480} height={60} />
                        </div>
                        <div className="o-col-12 u-mb-spacer-base">
                          <Skeleton width={480} height={60} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="o-col-6@sm o-col-5@md">
                    <div className="c-card c-card--bg-light">
                      <div className="c-card__body">
                        <div className="c-card__header">
                          <h6><Skeleton width={250} /></h6>
                          <div className="c-card__desc">
                            <p><Skeleton count={2} /></p>
                          </div>
                        </div>
                        <div className="o-row o-row--fluid c-button-group">
                          <div className="o-col">
                            <Skeleton width={250} height={50} />
                          </div>
                          <div className="o-col c-button-group__inline">
                            <Skeleton width={250} height={50} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
