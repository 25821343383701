import * as React from 'react';

import { ReactComponent as IconTrash } from 'assets/svg/trash.svg';
import { ReactComponent as IconClose } from 'assets/svg/close.svg';
import { ReactComponent as IconAlertSuccess } from 'assets/svg/alert-success.svg';

import api from 'api';
import { UserObject, UserFormSchema, UserFormObject, UserRoleTypes, allowedRolesForUser, roleForUser, UserRoles } from 'api/endpoints/users';
import { ClientObject } from 'api/endpoints/clients/index'
import { useEffect, useState } from 'react';
import useRequest from 'api/use-request';

import { Form, FormNotification } from 'components/form/form';
import { FormFieldVariant } from 'components/form/common';
import InputField, { InputFieldType } from 'components/form/input';
import { SubmitButton } from 'components/form/submit-button';
import SelectField from 'components/form/select-field';
import FileDropzoneField from 'components/form/file-dropzone-field'
import FilePreview from 'components/form/file-preview'
import FileUploadIndicator from 'components/cards/file-upload-indicator'

import { useParams, useHistory, useRouteMatch, Switch, Route } from 'react-router-dom';
import _, { update } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { sizedPreviewUserProfileImageURL } from 'utils/imgix-helpers';
import { debug } from 'utils/debug';
import DeleteConfirmationAlert, { DeleteConfirmationAlertType } from 'components/form/delete-confirmation';
import * as quartzite from 'quartzite';

import { Multiselect } from 'components/controls/multiselect-control';
import { Field } from 'formik';
import classNames from 'classnames';
import { UsergroupObject } from 'api/endpoints/usergroups';
import { store } from 'store';
import { TabsNav, TabsPanel } from 'components/layout/tabs';
import DevicesTab from 'components/tabs/devices';
import { SendResetLinkRequest } from 'api/endpoints/password';
import { DatasetObject } from 'api/endpoints/datasets';

function formatDate(input: string) {
  const date = new Date(input);
  return quartzite.dateString(date);
}

export interface IUserDetailsProps {
  isNew: boolean,
  nextLocation: string,
  onUpdate: () => void,
  isProfile: boolean,
  showAllClients: boolean
}

function newUserObj(clientId: string | undefined): UserFormObject {
  return _.clone({
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    role: 'user',
    new_password: '',
    new_password_confirm: '',
    profile_image: '',
    clients: (clientId ? [clientId] : []),
    usergroups: [],
    datasets: [],
  });
}

export function UserDetails({ isNew, nextLocation, onUpdate, isProfile, showAllClients }: IUserDetailsProps) {
  let [initialValue, setInitialValue] = useState<UserFormObject>();
  let [isUploading, setIsUploading] = useState(false);
  let [isDeleting, setIsDeleting] = useState(false);
  let [shouldOpenBrowseDialog, setShouldOpenBrowseDialog] = useState(false);
  let [uploadedFilePath, setUploadedFilePath] = useState('');
  let [uploadingFileName, setUploadingFileName] = useState('');
  let [editedUser, setEditedUser] = useState({} as UserObject);
  let currentUser = useRequest(api.users.getCurrentUser());

  let allClients = useRequest(api.clients.getClients({ 'page': 1, 'limit': 1000 }), api.users.userFulfillsRole(currentUser.data, UserRoleTypes.SuperAdmin));
  let [availableUsergroups, setAvailableUsergroups] = useState<UsergroupObject[]>([]);
  let [passwordResetLinkSent, setPasswordResetLinkSent] = useState(false);

  let [selectedRole, setSelectedRole] = useState(editedUser.role);

  let validationSchema = UserFormSchema
    .omit({ id: true, profile_image: true, profile_image_url: true, client_id: true, clients: true, usergroups: true })
    .nonstrict();

  let history = useHistory();
  let { clientId, userId } = useParams() as {
    clientId: string | undefined,
    userId: string | undefined,
  };

  let [selectedClientId, setSelectedClientId] = useState(clientId);


  if (isProfile && currentUser.data) {
    if (roleForUser(currentUser.data).role !== UserRoleTypes.SuperAdmin) {
      clientId = currentUser.data.clients[0].id;
    }
    userId = currentUser.data.id.toString();
  }

  let shouldShowAllClients = showAllClients || (currentUser.data && roleForUser(currentUser.data).role === UserRoleTypes.SuperAdmin);
  let usergroupRequestParams = { 'page': 1, 'limit': 1000, 'client_id': shouldShowAllClients ? undefined : clientId };
  let allUsergroups = useRequest(api.usergroups.getUsergroups(usergroupRequestParams), api.users.userFulfillsRole(currentUser.data, UserRoleTypes.GroupAdmin));

  let datasetsRequestParams = { 'page': 1, 'limit': 1000, 'client_id': selectedClientId };
  let allDatasets = useRequest(api.datasets.getDatasets(datasetsRequestParams), (selectedClientId ? true : false) && api.users.userFulfillsRole(currentUser.data, UserRoleTypes.GroupAdmin));

  function updateAvailableUsergroupsForClients(clients: string[]) {
    if (allUsergroups.data) {
      let newGroups = allUsergroups.data.data.filter(function (usergroup: UsergroupObject) {
        return clients.indexOf(usergroup.client_id) !== -1;
      });
      setAvailableUsergroups(newGroups);
      return newGroups;
    }
  }

  useEffect(() => {
    if (initialValue) {
      setSelectedRole(initialValue.role);
      updateAvailableUsergroupsForClients(initialValue.clients);
    }
  }, [initialValue, allUsergroups.data]);

  const submitHandler = (values: any, formikHelpers: any) => {
    const payload: UserFormObject = {
      id: userId ? userId : '',
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      role: values.role,
      profile_image: uploadedFilePath,
      clients: values.clients,
      usergroups: values.usergroups,
      client_id: clientId,
      datasets: values.datasets,
    };

    let update: Promise<UserObject>;
    if (isNew) {
      update = api.users.newUser(payload).fetch();
    } else {
      update = api.users.updateUser(payload, userId ?? '').fetch();
    }

    return update
      .then((response) => {
        store.notifications.presentNotification('Your profile changes were saved.');

        if (isNew) {
          api.users.sendWelcomeEmail({
            user_id: response.id,
            return_url: window.location.origin + '/verify-email',
          }).fetch();
        }
        onUpdate();

        if (nextLocation !== '') {
          history.replace(nextLocation);
        } else {
          history.goBack();
        }
      })
  };

  const shouldShowRolesSelector = () => {
    return (
      currentUser.data &&
      currentUser.data.role !== UserRoleTypes.User &&
      allowedRolesForUser(currentUser.data, editedUser).length > 0
    );
  }

  const shouldShowClientSelector = () => {
    return (
      currentUser &&
      currentUser.data?.role === UserRoleTypes.SuperAdmin &&
      selectedRole !== UserRoleTypes.SuperAdmin &&
      shouldShowAllClients === true &&
      allClients
    );
  }

  const shouldShowUsergroupSelector = () => {
    return (
      currentUser &&
      currentUser.data?.role !== UserRoleTypes.User &&
      currentUser.data?.role !== UserRoleTypes.SiteAdmin &&
      selectedRole !== UserRoleTypes.SuperAdmin &&
      selectedRole !== UserRoleTypes.GroupAdmin &&
      selectedRole !== UserRoleTypes.SiteAdmin
    );
  }

  const shouldShowSiteSelector = () => {
    return (
      currentUser &&
      currentUser.data?.role !== UserRoleTypes.User &&
      selectedRole === UserRoleTypes.SiteAdmin
    );
  }


  const loadUser = async (userId: string) => {
    let user = await api.users.getUser({ id: userId }).fetch();
    if (user) {
      setEditedUser(user);
      let clientsIds = user.clients.map(client => {
        return client.id;
      });
      let usergroupsIds = user.usergroups.map(usergroup => {
        return usergroup.id;
      });
      let datasetsIds = user.datasets.map(dataset => {
        return dataset.id;
      });
      setInitialValue({ profile_image: '', ...user, clients: clientsIds, usergroups: usergroupsIds, datasets: datasetsIds });
    }
  }

  const initForm = () => {
    if (!userId) {
      if (isProfile && currentUser.data) {
        userId = currentUser.data.id.toString();
        setEditedUser(currentUser.data);
        let clientsIds = currentUser.data.clients.map(client => {
          return client.id;
        });
        let usergroupsIds = currentUser.data.usergroups.map(usergroup => {
          return usergroup.id;
        });
        let datasetsIds = currentUser.data.datasets.map(dataset => {
          return dataset.id;
        });
        setInitialValue({ profile_image: '', ...currentUser.data, clients: clientsIds, usergroups: usergroupsIds, datasets: datasetsIds });
      } else {
        setInitialValue(newUserObj(clientId));
      }
    } else {
      loadUser(userId);
    }
  }

  useEffect(initForm, []);

  useEffect(() => {
    document.body.classList.add('has-modal-open');
    return () => {
      document.body.classList.remove('has-modal-open');
    };
  });

  function uploadNewPicture() {
    let user = editedUser;
    user.profile_image_url = '*';
    setEditedUser(user);
    setShouldOpenBrowseDialog(true);
  }

  function removePicture() {
    setUploadedFilePath('*');
    let user = editedUser;
    user.profile_image_url = '*';
    setEditedUser(user);
  }

  function deleteEditedUser() {
    if (userId) {
      let update = api.users.deleteUser({ id: userId }).fetch();
      onUpdate();
      update.then((response) => {
        setIsDeleting(false);
        history.push(nextLocation);
      }).catch((error) => {
        setIsDeleting(false);
        debug(error);
      });
    }
  }

  let match = useRouteMatch();
  const tabs = [
    { label: 'General', path: `${match.url}/general` },
    { label: 'Devices', path: `${match.url}/devices` },
  ];

  function closeModal() {
    history.goBack();
  }


  function sendResetPasswordEmail() {
    const payload: SendResetLinkRequest = {
      email: editedUser.email,
      reset_url: window.location.origin + '/password/reset'
    };
    api.password.sendResetLink(payload).fetch().then((response) => {
      setPasswordResetLinkSent(true);
    });
  }

  return (
    <>
      {initialValue ? (

        <div className="c-modal__root">
          <div className="c-modal__wrapper c-modal__wrapper--lg">
            <div className="c-modal c-modal--overflow">
              <div className="c-modal__header">
                <div className="c-block c-block--spacing-t-extra-small">
                  <div className="o-container-fluid">
                    <div className="o-row o-row--fluid c-block__row u-flex-row-reverse">
                      <div className="o-col">
                        <div onClick={() => { closeModal() }} className="c-link-cta-basic"><span>Close</span><IconClose className="o-svg-icon" /></div>
                      </div>
                      <div className="o-col u-flex-grow">
                        <p className="c-modal__headline">{isNew ? 'Add' : 'Edit'} user</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="c-modal__main">
                <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
                  <div className="o-container-fluid">
                            <Form
                              validationSchema={validationSchema}
                              initialValues={initialValue}
                              onSubmit={submitHandler}
                              enableReinitialize={true}
                            >
                              {({ values, errors, setFieldValue }) => (
                                < div className="o-row">
                                  <div className="o-col-12@sm o-col-7@md o-col-8@lg">
                                    <FormNotification />
                                    <div className="o-row">
                                      <div className="o-col-6@lg o-col-5@xl">
                                        <div className="u-mb-spacer-base-large">
                                          <h6>Profile picture</h6>

                                          {isUploading ? (
                                            <FileUploadIndicator
                                              fileName={uploadingFileName}
                                            />
                                          ) : (
                                            (editedUser.profile_image_url && editedUser.profile_image_url !== '*') ? (
                                              <FilePreview
                                                filePreviewUrl={sizedPreviewUserProfileImageURL(editedUser.profile_image_url)}
                                                newButtonLabel={"Upload new picture"}
                                                removeButtonLabel={"Remove picture"}
                                                descriptionLabel={undefined}
                                                newHandler={uploadNewPicture}
                                                removeHandler={removePicture}
                                                compactLayout={true}
                                              />
                                            ) : (
                                              <FileDropzoneField
                                                name={"profile_image"}
                                                shouldOpenBrowseDialog={shouldOpenBrowseDialog}
                                                setFieldValue={setFieldValue}
                                                dropHandler={(acceptedFiles: any[]) => {
                                                  if (acceptedFiles.length > 0) {
                                                    let formData = new FormData();
                                                    let file = acceptedFiles[0];
                                                    setUploadingFileName(file['name']);
                                                    formData.append('file', file);

                                                    setIsUploading(true);
                                                    api.files.uploadFile(formData, 'images').fetch().then((response) => {
                                                      let uploaded_file = response;
                                                      setUploadedFilePath(uploaded_file.file_path);
                                                      let user = editedUser;
                                                      user.profile_image_url = uploaded_file.file_url;
                                                      setEditedUser(user);
                                                    }).catch((error) => {
                                                      console.error(error);
                                                    }).finally(() => {
                                                      setIsUploading(false);
                                                    });
                                                  }
                                                }
                                                }
                                                description="Use a JPEG/PNG image no larger than 300x300 pixels."
                                              />
                                            )
                                          )}
                                        </div>
                                      </div>
                                      <div className="o-col-6@lg o-col-7@xl">
                                        <div className="u-mb-spacer-base-large">
                                          <h6>About</h6>
                                          <div className="o-row o-row--small-gutters">
                                            <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                                              <InputField
                                                type={InputFieldType.text}
                                                name={`first_name`}
                                                placeholder="First Name"
                                                label="First Name"
                                                variant={FormFieldVariant.fill}
                                                autocomplete={false}
                                              />
                                            </div>
                                            <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                                              <InputField
                                                type={InputFieldType.text}
                                                name={`last_name`}
                                                placeholder="Last Name"
                                                label="Last Name"
                                                variant={FormFieldVariant.fill}
                                                autocomplete={false}
                                              />
                                            </div>
                                            <div className="o-col-12">
                                              <InputField
                                                type={InputFieldType.text}
                                                name={`email`}
                                                placeholder="Email Address"
                                                label="Email Address"
                                                variant={FormFieldVariant.fill}
                                                autocomplete={false}
                                              />
                                            </div>

                                            {currentUser.data && shouldShowRolesSelector() ? (
                                              <div className="o-col-12">
                                                <label htmlFor={"role"} className={classNames('c-form-label')}>User Role</label>
                                                <Field
                                                  name="role"
                                                  id="role"
                                                  component={Multiselect}
                                                  placeholder="User Role"
                                                  options={allowedRolesForUser(currentUser.data, editedUser).map((role) => { return { value: role.role, label: role.title } })}
                                                  isMulti={false}
                                                  onChange={(selectedValue: string) => {
                                                    setSelectedRole(selectedValue);
                                                  }}
                                                />
                                                <br />
                                              </div>
                                            ) : (<div />)}

                                            {allClients.data && shouldShowClientSelector() ? (
                                              <div className="o-col-12">
                                                <label htmlFor={"clients"} className={classNames('c-form-label')}>Clients</label>
                                                <Field
                                                  name="clients"
                                                  id="clients"
                                                  component={Multiselect}
                                                  placeholder="Clients"
                                                  options={allClients.data.data.map((client: ClientObject) => { return { value: client.id, label: client.name } })}
                                                  isMulti={true}
                                                  onChange={(selectedValues: string[]) => {
                                                    setSelectedClientId(selectedValues.length > 0 ? selectedValues[0] : undefined);
                                                    let availableUserGroups = updateAvailableUsergroupsForClients(selectedValues);
                                                    if (availableUserGroups) {
                                                      let availableUserGroupsIds = availableUserGroups.map(usergroup => {
                                                        return usergroup.id;
                                                      });

                                                      let usergroupFieldValues = values.usergroups;
                                                      let newGroups = usergroupFieldValues.filter(function (usergroupId: string) {
                                                        return availableUserGroupsIds.indexOf(usergroupId) !== -1;
                                                      });

                                                      setFieldValue('usergroups', newGroups);
                                                    }
                                                  }}
                                                />
                                                <br />
                                              </div>
                                            ) : (<div />)}

                                            {availableUsergroups && shouldShowUsergroupSelector() ? (
                                              <div className="o-col-12">
                                                <label htmlFor={"usergroups"} className={classNames('c-form-label')}>User Groups</label>
                                                <Field
                                                  name="usergroups"
                                                  id="usergroups"
                                                  component={Multiselect}
                                                  placeholder="User Groups"
                                                  options={availableUsergroups.map((usergroup: UsergroupObject) => { return { value: usergroup.id, label: usergroup.name } })}
                                                  isMulti={true}
                                                />
                                                <br />
                                              </div>
                                            ) : (<div />)}

                                            {selectedClientId && allDatasets && allDatasets.data?.data && shouldShowSiteSelector() ? (
                                              <div className="o-col-12">
                                                <label htmlFor={"datasets"} className={classNames('c-form-label')}>Site</label>
                                                <Field
                                                  name="datasets"
                                                  id="datasets"
                                                  component={Multiselect}
                                                  placeholder="Sites"
                                                  options={allDatasets.data.data.map((dataset: DatasetObject) => { return { value: dataset.id, label: dataset.name } })}
                                                  isMulti={true}
                                                />
                                                <br />
                                              </div>
                                            ) : (<div />)}

                                          </div>
                                        </div>
                                        {!isNew && (
                                          <div className="u-mb-spacer-base-large">
                                            <h6>Change password</h6>
                                            <div className="o-row o-row--small-gutters">
                                              <div className="o-col-12@md o-col-12@lg o-col-12@xl">
                                                {passwordResetLinkSent ? (
                                                  <div className="c-alert c-alert--small@xs c-alert--row@md c-alert--success c-color--invert">
                                                    <div className="c-alert__col c-alert__icon">
                                                      <IconAlertSuccess className="o-svg-icon" />
                                                    </div>
                                                    <div className="c-alert__col c-alert__body">
                                                      <p><strong>Password change requested</strong></p>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div className="c-link-cta" onClick={(event) => { event.preventDefault(); sendResetPasswordEmail(); }}>
                                                    <span>Request password change</span>
                                                  </div>

                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="o-col-12@sm o-col-5@md o-col-4@lg">
                                    <div className="c-card c-card--bg-light">
                                      <div className="c-card__body">
                                        <div className="c-card__header">
                                          <h6>{isNew ? 'Create' : 'Update'}</h6>
                                          {!isNew && (
                                            <div className="c-card__desc">
                                              {editedUser && editedUser.updated_at ? (
                                                <p>This user was last updated {formatDate(editedUser.updated_at)}.</p>
                                              ) : (
                                                <p></p>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                        <div className="o-row o-row--fluid c-button-group">
                                          <div className="o-col">
                                            <SubmitButton disabled={isUploading}><span>{isNew ? 'Create' : 'Update'} user</span></SubmitButton>
                                          </div>
                                          {!isNew &&
                                            <div className="o-col c-button-group__inline">
                                              <div className="c-link-cta-basic" onClick={(event) => { event.preventDefault(); setIsDeleting(true); }}>
                                                <IconTrash className="o-svg-icon" /><span>Delete account</span>
                                              </div>
                                            </div>
                                          }
                                        </div>
                                      </div>
                                      <DeleteConfirmationAlert
                                        onDelete={deleteEditedUser}
                                        onCancel={() => {
                                          setIsDeleting(false);
                                        }}
                                        resource_label={editedUser.first_name + ' ' + editedUser.last_name}
                                        show={isDeleting}
                                        type={DeleteConfirmationAlertType.Card}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <UserEditModalSkeleton />
      )
      }
    </>
  );
}

export function UserEditModalSkeleton() {
  return (
    <div className="c-modal__root">
      <div className="c-modal__wrapper c-modal__wrapper--lg">
        <div className="c-modal c-modal--overflow">
          <div className="c-modal__header">
            <div className="c-block c-block--spacing-t-extra-small">
              <div className="o-container-fluid">
                <div className="o-row o-row--fluid c-block__row u-flex-row-reverse">
                  <div className="o-col">
                    <Skeleton width={250} />
                  </div>
                  <div className="o-col u-flex-grow">
                    <p className="c-modal__headline"><Skeleton width={250} /></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="c-modal__main">
            <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
              <div className="o-container-fluid">
                <div className="o-row">
                  <div className="o-col-6@sm o-col-7@md o-col-8@lg">
                    <div className="o-row">
                      <div className="o-col-6@lg o-col-5@xl">
                        <div className="u-mb-spacer-base-large">
                          <h6><Skeleton width={250} /></h6>

                          <Skeleton width={300} height={250} />
                        </div>
                      </div>

                      <div className="o-col-6@lg o-col-7@xl">
                        <div className="u-mb-spacer-base-large">
                          <h6><Skeleton width={250} /></h6>
                          <div className="o-row o-row--small-gutters">
                            <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                              <Skeleton width={200} height={60} />
                            </div>
                            <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                              <Skeleton width={200} height={60} />
                            </div>
                            <div className="o-col-12">
                              <Skeleton width={420} height={60} />
                            </div>
                            <div className="o-col-12">
                              <Skeleton width={420} height={60} />
                            </div>
                            <div className="o-col-12">
                              <Skeleton width={420} height={60} />
                            </div>
                          </div>
                        </div>
                        <div className="u-mb-spacer-base-large">
                          <h6><Skeleton width={200} /></h6>
                          <div className="o-row o-row--small-gutters">
                            <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                              <Skeleton width={200} height={50} />
                            </div>
                            <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                              <Skeleton width={200} height={50} />
                            </div>
                          </div>
                          <p className="c-note"><Skeleton count={2} /></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="o-col-6@sm o-col-5@md o-col-4@lg">
                    <div className="c-card c-card--bg-light">
                      <div className="c-card__body">
                        <div className="c-card__header">
                          <h6><Skeleton width={250} /></h6>
                          <div className="c-card__desc">
                            <p><Skeleton count={2} /></p>
                          </div>
                        </div>
                        <div className="o-row o-row--fluid c-button-group">
                          <div className="o-col">
                            <Skeleton width={250} height={50} />
                          </div>
                          <div className="o-col c-button-group__inline">
                            <Skeleton width={250} height={50} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
