import * as React from 'react';

import classNames from 'classnames';

// Hooks
import { useContext, useRef, useState } from 'react';

// Icons
import { ReactComponent as IconEye } from 'assets/svg/eye.svg';
import { ReactComponent as IconPen } from 'assets/svg/pen.svg';
import { ReactComponent as IconMore } from 'assets/svg/more.svg';
import { ReactComponent as IconTrash } from 'assets/svg/trash.svg';

import { ClientObject } from 'api/endpoints/clients';
import { Link } from 'react-router-dom';
import useClickAway from 'hooks/use-click-away';
import { listingContext } from './listing-context';
import * as quartzite from 'quartzite';
import DeleteConfirmationAlert, { DeleteConfirmationAlertType } from 'components/form/delete-confirmation';


interface IClientCardProps {
  client: ClientObject,
  deleteHandler: (ids: string[]) => void,
  selectHandler: (ids: string[]) => void,
}

const ClientCard: React.FunctionComponent<IClientCardProps> = ({ client, deleteHandler, selectHandler }) => {

  const [showActions, setShowActions] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const { selection } = useContext(listingContext);

  const trigger = useRef<HTMLDivElement>(null);
  useClickAway(trigger, () => setShowActions(false));

  function editClientLink(id: string): string {
    return `/client/${id}/general`;
  }

  function isSelected(id: string) {
    if (selection && selection.indexOf(id) > -1) {
      return true;
    }
    return false;
  }

  function formatDate(input: string) {
    const date = new Date(input);
    return quartzite.dateString(date);
  }

  return (
    <div className="c-listing__item">
      <div ref={trigger} className={classNames(['c-card', 'c-card--link', 'c-card--bg-white', { 'is-selected': isSelected(client.id) }, { 'has-popup-open': showActions }])}>
        <div className="c-card__body">
          <div className="c-card__content">
            <p className="c-card__title"><Link to={editClientLink(client.id)}>{client.name}</Link></p>
            <div className="c-card__info">
              <p><strong>{client.datasets_count}</strong> sites</p>
              <p><strong>{client.users_count}</strong> users</p>
            </div>
          </div>
          <div className="c-card__footer">
            <Link to={editClientLink(client.id)} className="c-link-cta-light"><IconPen className="o-svg-icon" /><span>Edit</span></Link>
            <div className="c-card__actions c-card__link-hidden">
              <div onClick={(event) => setShowActions(!showActions)} className="c-card__actions-toggle">
                <IconMore className="o-svg-icon" />
              </div>
              <div className="c-popup">
                <div className="c-popup__header c-popup__header--sm">
                  <p className="c-popup__title">Actions</p>
                </div>
                <div className="c-popup__body">
                  <ul className="c-popup__list c-popup__list--small">
                    {
                      (client.first_dashboard_id && client.first_dataset_id) &&
                      <li>
                        <Link to={`/dashboard/${client.first_dashboard_id}/${client.first_dataset_id}`}>
                          <IconEye className="o-svg-icon" /><span>View Client</span>
                        </Link>
                      </li>
                    }
                    <li>
                      <Link to={editClientLink(client.id)}>
                        <IconPen className="o-svg-icon" /><span>Edit Client</span>
                      </Link>
                    </li>
                    <li className="c-popup__divider c-popup__divider--top">
                      <div className="c-popup__link" onClick={(event) => { event.preventDefault(); setDeleting(true); setShowActions(false) }}>
                        <IconTrash className="o-svg-icon" /><span>Delete Client</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={classNames('c-form-element', 'c-form-element--style-fill', 'c-form-element--checkbox', 'c-card__checkbox', { 'c-card__link-hidden': !isSelected(client.id) })}>
              <div className="c-form-element__field">
                <input checked={isSelected(client.id)} type="checkbox" id={`check-listing-item-${client.id}`} onChange={(event) => { selectHandler([client.id]); }} />
                <label htmlFor={`check-listing-item-${client.id}`}></label>
              </div>
            </div>
          </div>
          <DeleteConfirmationAlert
            onDelete={() => { deleteHandler([client.id]); setDeleting(false); }}
            onCancel={() => { setDeleting(false); }}
            resource_label={client.name}
            show={isDeleting}
            type={DeleteConfirmationAlertType.Card}
          />
        </div>
      </div>
    </div >
  );
};

export default ClientCard;
