import api from 'api';
import useRequest from 'api/use-request';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { createTypeReferenceDirectiveResolutionCache } from 'typescript';
import { PageContext } from './page-context';

interface IDateTimeBannerProps {
}

const DateTimeBanner: React.FunctionComponent<IDateTimeBannerProps> = ( props ) => {
  const [dateTime, setDateTime] = useState(new Date());

  let { datasetId } = useParams() as {
    id: string | undefined,
    datasetId: string | undefined
  };

  // Conditional fetch, because we might not have the URL params present?
  let currentDataset = useRequest(api.datasets.getDataset({ id: datasetId? datasetId : '' }), datasetId? true : false);

  function formattedTimeString(date: Date) {

    var now = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      second: undefined,
      timeZone: currentDataset.data?.timezone,
      }).format(date);

    var ampm = now.slice(-2);
    var time = now.substring(0, now.length - 3);

    return (
      <p className="c-site-header-clock__time">
        <span>{time}</span> {ampm}
      </p>
    );
  }

  function formattedDateString(date: Date) {

    return (
      <p className="c-site-header-clock__date">
        {date.getDate() + ' ' + date.toLocaleString('default', { month: 'short', year: 'numeric' })}
      </p>
    );
  }

  useEffect(() => {
    const interval = setInterval(() => setDateTime(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // <p className="c-site-header-clock__time"><span>2:57</span> PM</p>
  // <p className="c-site-header-clock__date">10 Mar 2022</p>

  return (
    <div className="c-site-header-clock" title={currentDataset.data?.timezone}>
      {currentDataset.data
        ?
          <>
            {formattedTimeString(dateTime)}
            {formattedDateString(dateTime)}
          </>
        :
          <Skeleton width={320} height={70} />
      }
    </div>
  );
};

export default DateTimeBanner;
