import * as React from 'react';

import Pagination from 'components/cards/pagination';
import { listingContext } from 'components/cards/listing-context';
import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string'
import { SelectBoxControl } from 'components/controls/select-box-control';
import { isArray } from 'lodash';

interface IListingFooterProps {
  defaultLimit?: number,
  compact?: boolean,
}

const ListingFooter: React.FunctionComponent<IListingFooterProps> = (props) => {
  let listing = useContext(listingContext);
  let location = useLocation();
  let history = useHistory();
  let limitOptions = [12, 24, 36, 48].map((value, index) => {
    return { value: value.toString(), label: value.toString() };
  });

  function changeLimit(limit: string) {
    let params = queryString.parse(location.search);
    history.push(location.pathname + '?' + queryString.stringify({ ...params, limit }));
  }

  function currentLimit() {
    let params = queryString.parse(location.search);
    let limit = params.limit ? (isArray(params.limit) ? params.limit[0] : params.limit) : (props.defaultLimit ?? 12);
    return limit.toString();
  }

  return (
    <div className="o-row o-row--fluid c-listing__footer">
      {!props.compact && (
        <div className="o-col u-justify-start@md">
          <div className="c-actions">
            <div className="c-actions__col">
              {listing.data && listing.data.meta?.total > 0 && (
                <p className="c-caption">{`Showing ${listing.data.meta?.from} to ${listing.data.meta?.to} of ${listing.data.meta?.total} entries`}</p>
              )}
              {listing.data && listing.data.meta?.total === 0 && (
                <p className="c-caption">{`No entries found`}</p>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="o-col-12 o-col@md">
        {listing.data && listing.data.meta.last_page > 1 && (
          <Pagination
            currentPage={listing.data.meta.current_page}
            totalPages={listing.data.meta.last_page}
            pageNeighbours={1}
            alwaysShowFirstLast={true}
            alwaysShowPrevNext={true}
            onPageSelect={(page) => {
              const params = queryString.parse(location.search);
              history.push(location.pathname + '?' + queryString.stringify({ ...params, page }));
            }}
          />
        )}
      </div>
      {!props.compact && (
        <div className="o-col u-justify-end@md">
          <div className="c-actions">
            <SelectBoxControl
              title={'Entries per page:'}
              subtitle={'Entries per page:'}
              options={limitOptions}
              currentValue={currentLimit()}
              selectedOptions={[currentLimit().toString()]}
              onChange={(selectedValue) => {
                changeLimit(selectedValue);
              }}
              listClass={"c-dropdown__list--buttons"}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ListingFooter;
