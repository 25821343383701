import * as React from 'react';
import Notification from 'components/notification';
import { useStore } from 'store';
import { observer } from 'mobx-react';

export interface IAppNotificationsProps {
}

export const AppNotifications = observer((props: IAppNotificationsProps) => {
  const store = useStore();

  return (
    <>
    {store.notifications.app.map((notification, index) =>
    <Notification key={index} type="success" autoDismissible={5000} identifier={notification.identifier}>
        <p><strong>{notification.message}</strong></p>
    </Notification>
    )}
    </>
  );
});

