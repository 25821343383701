import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useStore } from 'store';

export interface IKioskRouteProps extends RouteProps {
}

export function KioskRoute({ children, ...rest }: IKioskRouteProps) {
  const store = useStore();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (store.isLoggedIn) {
          return (children);
        } else {
          console.log('redirect to /kiosk');
          return (
            <Redirect
              to={{
                pathname: '/kiosk',
                state: { from: location }
              }}
            />
          )
        }
      }}
    />
  );
}

