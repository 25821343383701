import HttpClient from 'api/http-client';
import * as z from 'zod';

export const FileUploadResponseSchema = z.object({
  file_path: z.string().nonempty(),
  file_url: z.string().nonempty()
});
export type FileUploadResponseObject = z.infer<typeof FileUploadResponseSchema>

export function uploadFile(data: FormData, directory: string) {
  return new HttpClient().request<FileUploadResponseObject>({
    method: 'post',
    url: '/asset-upload?directory=' + directory,
    data,
  });
}
