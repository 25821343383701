import * as React from 'react';

import { ReactComponent as IconAdd } from 'assets/svg/add.svg';
import { ReactComponent as IconAddItem } from 'assets/svg/add-item.svg';
import { ReactComponent as IconTrash } from 'assets/svg/trash.svg';
import { ReactComponent as IconMove } from 'assets/svg/move.svg';
import { ReactComponent as IconDropdown } from 'assets/svg/dropdown.svg';
import { ReactComponent as IconAlertError } from 'assets/svg/alert-error.svg';
import { ReactComponent as IconClose } from 'assets/svg/close.svg';

import { DashboardObject, DashboardObjectRequest, DashboardObjectRequestSchema, DashboardObjectSchema, DashboardType } from 'api/endpoints/dashboards';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import InputField, { InputFieldType } from 'components/form/input';
import { Form, FormNotification, FormStatus } from 'components/form/form';
import SelectField from 'components/form/select-field';
import api from 'api';
import useRequest from 'api/use-request';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import * as z from 'zod';
import RepeatableField from 'components/form/repeatable-field';
import { ButtonLink, ButtonScope } from 'components/button';
import { SubmitButton } from 'components/form/submit-button';
import { Field, FieldArrayRenderProps } from 'formik';
import * as _ from 'lodash';
import { FormFieldVariant } from 'components/form/common';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import Skeleton from 'react-loading-skeleton';
import DeleteConfirmationAlert, { DeleteConfirmationAlertType } from 'components/form/delete-confirmation';
import useScript from 'hooks/use-script';
import config from '../../../../../src/client/src/configuration';
import { HttpError } from 'api/http-client';
import { Multiselect } from 'components/controls/multiselect-control';

export interface IDashboardsTabProps {
  clientId: string;
}

export default function DashboardsTab({ clientId }: IDashboardsTabProps) {
  const location = useLocation();
  const searchArgs = queryString.parse(location.search, { parseNumbers: true });

  const [isLoaded, setIsLoaded] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const [initialValues, setInitialValues] = useState({ dashboards: [] as DashboardObjectRequest[] });
  const [deleteable, setDeleatable] = useState({ dashboards: [] as DashboardObjectRequest[] });

  const [shouldLoadSisense, setShouldLoadSisense] = useState(false);
  const [sisenseRequestFailed, setSisenseRequestFailed] = useState(false);
  const [isSisenseAuthenticated, setIsSisenseAuthenticated] = useState(false);
  const [isLoadingSisense, setIsLoadingSisense] = useState(false);

  const [isLoadingSisenseDashboards, setIsLoadingSisenseDashboards] = useState(false);
  const [hasStartedLoadingSisenseDashboards, setHasStartedLoadingSisenseDashboards] = useState(false);
  const [availableSisenseDashboards, setAvailableSisenseDashboards] = useState([] as {oid: string, title: string}[]);

  let sharedDashboards = useRequest(api.dashboards.getSharedDashboards({}));


  // let sisenseAuthCheck = () => {
  //   api.sisense.isAuthenticated().fetch()
  //       .then((response) => {
  //         if (!response.isAuthenticated) {
  //           setShouldLoadSisense(true);
  //         } else {
  //           setIsSisenseAuthenticated(true);
  //         }
  //       })
  //       .catch((error: HttpError) => {
  //         if (error.code !== '200') {
  //           console.log(error);
  //           setSisenseRequestFailed(true);
  //         }
  //       });
  // };

  // useEffect(()=>{
  //   sisenseAuthCheck();
  // }, []);





  // Loading sisense.js (but not frame.js) executes the SSO flow setting the cookie
  // which allows REST API calls for the authenticated user
  // TODO: Find a better solution for authenticating REST API calls via SSO
  // useScript({
  //   src: config.SISENSE_SERVER_URL + '/js/sisense.js',
  //   onLoad: () => {
  //     setIsSisenseAuthenticated(true);
  //     setShouldLoadSisense(false);
  //     //console.log('sisense loaded');
  //   },
  //   onError: (error) => {
  //     setSisenseRequestFailed(true);
  //   }
  // }, !sisenseRequestFailed && !isSisenseAuthenticated && shouldLoadSisense);


  // useEffect(() => {
  //   if (isSisenseAuthenticated && !hasStartedLoadingSisenseDashboards && !isLoadingSisenseDashboards && !sisenseRequestFailed) {
  //     setIsLoadingSisenseDashboards(true);
  //     api.sisense.getDashboards().fetch()
  //       .then((dashboardsList) => {
  //         const availableDashboards:{
  //           oid: string,
  //           title: string
  //         }[] = [];

  //         if (dashboardsList) {
  //           for (let index = 0; index < dashboardsList.length; index++) {
  //             const dashboard = dashboardsList[index];
  //                 availableDashboards.push({
  //                   oid: dashboard.oid,
  //                   title: dashboard.title,
  //                 })
  //           }
  //           setAvailableSisenseDashboards(availableDashboards);
  //           //console.log('sisense dashboards loaded');
  //           setIsLoadingSisenseDashboards(false);
  //         }
  //       })
  //       .catch((error: HttpError) => {
  //         if (error.code !== '200') {
  //           setSisenseRequestFailed(true);
  //         }
  //       });
  //       setHasStartedLoadingSisenseDashboards(true);
  //   }
  // }, [isSisenseAuthenticated, sisenseRequestFailed, isLoadingSisenseDashboards, hasStartedLoadingSisenseDashboards]);




  let client = useRequest(api.clients.getClient({ id: clientId }));

  let getDashboards = () => {
    setIsRefreshing(true);
    return api.dashboards.getDashboards({ ...searchArgs, client_id: clientId }).fetch()
      .then((response) => {
        const dashboards = response.data?.map((dashboard, index): DashboardObjectRequest => {
          return _.omit(dashboard, ['created_at', 'updated_at']);
        });
        setInitialValues({ dashboards: dashboards });
        setIsRefreshing(false);
        setIsLoaded(true);
        //console.log('dashboards loaded');
      })
      .catch((error: HttpError) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!isLoaded) {
      getDashboards();
    } else if (isLoaded && !isLoadingSisenseDashboards && availableSisenseDashboards.length > 0) {
      setIsRefreshing(false);
      //console.log('ready');
    }
  }, [availableSisenseDashboards, isLoadingSisenseDashboards, isLoaded]);


  return (
    <>
      {isLoaded ? (

        <Form
          validationSchema={
            z.object({
              dashboards: z.array(DashboardObjectRequestSchema)
            })
          }
          initialValues={initialValues}
          successMessage="Dashboards saved succesfully!"
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting, setStatus }) => {
            //console.log('submit');
            setIsRefreshing(true);

            let updates: Promise<unknown>[] = [];
            values.dashboards.forEach(({ id, source, type, name, order, parent_id }, index) => {
              const payload: DashboardObjectRequest = {
                client_id: clientId,
                id,
                source,
                type: DashboardType.sisense,
                name,
                order: index,
                shared: false,
                parent_id: parent_id,
              };
              if (payload.id) {
                updates.push(api.dashboards.updateDashboard(payload).fetch());
              } else {
                updates.push(api.dashboards.newDashboard(payload).fetch());
              }
            });

            deleteable.dashboards.forEach(({ id }, index) => {
              if (id) {
                updates.push(api.dashboards.deleteDashboard({ id }).fetch());
              }
            });

            return Promise.all(updates)
              .then((response) => {
                setDeleatable({dashboards: []});
                getDashboards().finally(()=>{
                  setStatus(FormStatus.Successful);
                });
              }, (reason) => {
                setStatus(FormStatus.Failed);
              })
          }}
        >
          {({ values, errors, }) => (
            <RepeatableField name="dashboards" render={(helpers) => (
              <div className="o-row">
                <div className="o-col-8@md">
                  <div className="u-mb-spacer-base-large">
                    <h6>Dashboards</h6>
                    <FormNotification />
                    {(values.dashboards.length < 1) ? (
                      <NoDashboardsPlaceholder helpers={helpers} />
                    ) : null}
                    <SortableList
                      lockAxis={"y"}
                      helperClass={"is-selected"}
                      useDragHandle
                      onSortEnd={({ oldIndex, newIndex }) => {
                        helpers.move(oldIndex, newIndex);

                      }}
                    >
                      {values.dashboards.map((dashboard, index) =>
                        <SortableDashboardDetails
                          key={`dashboard-${(index)}` + dashboard.id ?? Math.random().toString().substring(2, 8)}
                          index={index}
                          sortIndex={index}
                          deleteHandler={() => {
                            if (dashboard.id) {
                              setDeleatable({ dashboards: deleteable.dashboards.concat([dashboard]) })
                            }
                            helpers.remove(index)
                          }}
                          sisenseRequestFailed={sisenseRequestFailed}
                          availableSisenseDashboards={availableSisenseDashboards}
                          {...dashboard}
                          firstAvailableDataset={client.data?.first_dataset_id}
                          isLoadingSisenseDashboards={isLoadingSisenseDashboards}
                          sharedDashboards={sharedDashboards?.data?.data}
                        />
                      )}
                    </SortableList>
                  </div>
                </div>

                {(values.dashboards.length < 1 && deleteable.dashboards.length < 1) ? null : (
                <div className="o-col-4@md">
                  <div className="c-card c-card--bg-light">
                    <div className="c-card__body">
                      <div className="c-card__header">
                        <h6>Save</h6>
                        <div className="c-card__desc">
                          <p>These dashboards will be available in the dashboard menu for the client. There are currently {initialValues.dashboards.length} dashboards saved for this client.</p>
                        </div>
                      </div>

                        <div className="o-row o-row--fluid c-button-group">
                        <div className="o-col">
                          <SubmitButton><span>Update dashboards</span></SubmitButton>
                        </div>
                        <div className="o-col c-button-group__inline">
                        <div className="c-link-cta-basic" onClick={(e) => {
                          const dashboards = values.dashboards;
                          setDeleatable({ dashboards: deleteable.dashboards.concat(dashboards) });
                          for (let index = dashboards.length - 1; index >= 0; index--) {
                            helpers.remove(index);
                          }
                        }}><IconTrash className="o-svg-icon" /><span>Delete all dashboards</span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                )}
              </div>
            )} />
          )}
        </Form>
      ) : (
        <DashboardsTabSkeleton />
      )}
    </>
  )
}

export interface iDashboardDetailsProps extends Omit<DashboardObjectRequest, 'created_at' | 'updated_at'> {
  deleteHandler: () => void,
  isSelected?: boolean,
  sortIndex: number,
  availableSisenseDashboards: { oid: string, title: string }[],
  isLoadingSisenseDashboards?: boolean,
  firstAvailableDataset?: string,
  sisenseRequestFailed?: boolean,
  sharedDashboards?: DashboardObject[] | undefined,
  parent_id?: string,
  shared?: boolean,
}

function DashboardDetails({ id, name, type, order, source, isSelected, deleteHandler, sortIndex, availableSisenseDashboards, firstAvailableDataset, isLoadingSisenseDashboards, sisenseRequestFailed = false, sharedDashboards, parent_id, shared}: iDashboardDetailsProps) {
  if (!parent_id) parent_id = '';

  let [isOpen, setIsOpen] = useState(false || name === '');
  let [isDeleting, setIsDeleting] = useState(false);
  let [shouldShowWarning, setShouldShowWarning] = useState(false);

  let [parentDashboard, setParentDashboard] = useState(parent_id ?? '');

  let [dashboardName, setDashboardName] = useState(name ?? '');

  let isCustomDashboard = () => {
    return parentDashboard === '';
  };


  function dashboardLink() {
    switch (type) {
      case DashboardType.sisense:
        return <Link to={`/dashboard/${id}/${firstAvailableDataset}`} className="c-link-cta-light"><span>View</span><IconDropdown className="o-svg-icon o-svg-right o-svg-small" /></Link>

      case DashboardType.url:
        return <Link to={source ?? ''} className="c-link-cta-light"><span>View</span><IconDropdown className="o-svg-icon o-svg-right o-svg-small" /></Link>

        case DashboardType.powerbi:
          return <Link to={`/dashboard/p/${id}/${firstAvailableDataset}`} className="c-link-cta-light"><span>View</span><IconDropdown className="o-svg-icon o-svg-right o-svg-small" /></Link>

      case DashboardType.powerbipages:
        return <Link to={`/dashboard/p/${id}/${firstAvailableDataset}`} className="c-link-cta-light"><span>View</span><IconDropdown className="o-svg-icon o-svg-right o-svg-small" /></Link>

      default:
        return null;
    }
  }


  useEffect(()=>{
    if (type === DashboardType.sisense && !sisenseRequestFailed && !isLoadingSisenseDashboards && availableSisenseDashboards && availableSisenseDashboards.length > 0) {
      let matchingSisenseDashboard = availableSisenseDashboards.find((dashboard)=>dashboard.oid === source);
      if (matchingSisenseDashboard || !id) {
        setShouldShowWarning(false);
      } else {
        setShouldShowWarning(true);
      }
    }
  });

  useEffect(()=>{
    if (sharedDashboards) {
      let parent = sharedDashboards.find((sharedDashboard) => { return sharedDashboard.id === parentDashboard; });
      if (parent) {
        setDashboardName(parent.name);
      }
    }
  }, [parentDashboard, sharedDashboards]);

  return (
    <div className={classNames(['c-card', 'c-card--link', 'c-card--bg-white', 'c-accordion', { 'is-open': isOpen, 'is-selected': isSelected, 'c-card--bg-warning': shouldShowWarning }])}>
      <div className="c-card__body">
        <div onClick={() => { setIsOpen(!isOpen); }} className="c-card__header c-accordion__header">
          <div className="c-accordion__toggle c-accordion__toggle--high"></div>
          <p className="c-card__headline">{isCustomDashboard() ? (name ? name : "New Dashboard") : dashboardName}</p>
          <div className="c-actions c-actions--divider-last">
            {id && !shouldShowWarning && !isLoadingSisenseDashboards &&
              <div className="c-actions__col">
                {dashboardLink()}
              </div>
            }
            <div className="c-actions__col">
              <ReorderHandle />
            </div>
            <div className="c-actions__col">
              <div onClick={(e) => { setIsOpen(true); setIsDeleting(true); e.stopPropagation(); }} className="c-link-cta-light"><IconTrash className="o-svg-icon" /></div>
            </div>
          </div>
        </div>
        <div className="c-accordion__container">
          {shouldShowWarning &&
            <div className="c-alert c-alert--small@xs c-alert--row@sm c-alert--warning c-color--invert">
              <div className="c-alert__col c-alert__icon">
                <IconAlertError className="o-svg-icon" />
              </div>
              <div className="c-alert__col c-alert__body">
                <p><strong>Warning</strong></p>
                <p>The selected Sisense dashboard is not available. Please select another or <a href="https://locusrobotics.com/get-started/support/">contact your administrator</a>.</p>
              </div>
              {/* <a href="#" className="c-alert__close"><IconClose className="o-svg-icon" /></a> */}
            </div>
          }

          {isCustomDashboard() &&
          <>
            <InputField
              type={InputFieldType.text}
              name={`dashboards[${sortIndex}].name`}
              placeholder="Menu Label"
              label="Menu Label"
              variant={FormFieldVariant.fill}
              autoFocus={true}
            />

            <hr className="c-hr--dashed" />
            <div className="o-row o-row--small-gutters">
              <div className="o-col-12@sm">
                {type === DashboardType.sisense && !sisenseRequestFailed &&
                  <SelectField
                    name={`dashboards[${sortIndex}].source`}
                    placeholder="Select a dashboard"
                    label="Sisense Dashboard"
                    isLoading={isLoadingSisenseDashboards}
                    variant={FormFieldVariant.fill}
                    disabled={true}
                  >
                    {shouldShowWarning &&
                      <option value=''></option>
                    }
                    {availableSisenseDashboards.map((dasboard, index) => {
                      return (
                        <option key={dasboard.oid + '_' + index} value={dasboard.oid}>{dasboard.title}</option>
                      )
                    })}
                  </SelectField>
                }
                {type === DashboardType.sisense && sisenseRequestFailed &&
                  <InputField
                    type={InputFieldType.text}
                    name={`dashboards[${sortIndex}].source`}
                    placeholder="Sisense Dashboard ID"
                    label="Sisense Dashboard"
                    variant={FormFieldVariant.fill}
                    disabled={true}
                  />
                }
                {type === DashboardType.url &&
                  <InputField
                    type={InputFieldType.text}
                    name={`dashboards[${sortIndex}].source`}
                    placeholder="URL"
                    label="URL"
                    variant={FormFieldVariant.fill}
                  />
                }
              </div>
            </div>
          </>
          }
          <DeleteConfirmationAlert
            onDelete={() => { deleteHandler(); setIsDeleting(false); setIsOpen(false); }}
            onCancel={() => { setIsDeleting(false) }}
            resource_label={name ?? ''}
            show={isDeleting}
            type={DeleteConfirmationAlertType.Card}
          />
        </div>
      </div>
    </div>
  );
}

const SortableDashboardDetails = SortableElement((dashboard: iDashboardDetailsProps) => <DashboardDetails {...dashboard} />);
const SortableList = SortableContainer(({ children }: { children: React.ReactNode }) => {
  return <div>{children}</div>;
});
const ReorderHandle = SortableHandle(() => <div className="c-link-cta-light"><IconMove className="o-svg-icon" /><span className="u-hidden u-block@sm">Reorder</span></div>);


interface iNoDashboardsPlaceholderProps {
  helpers: FieldArrayRenderProps;
}

function NoDashboardsPlaceholder({ helpers }: iNoDashboardsPlaceholderProps) {
  return (
    <div className="c-add c-add--link u-flex-nowrap@md">
      <div className="c-add__icon">
        <IconAlertError className="o-svg-icon" />
      </div>
      <div className="c-add__body">
        <div className="c-add__title">
          <span>You currently don’t have any dashboards added.</span>
        </div>
      </div>
    </div>
  );
}

export function DashboardsTabSkeleton() {
  return (
    <div className="o-row">
      <div className="o-col-8@md">
        <div className="u-mb-spacer-base-large">
          <h6><Skeleton width={250} /></h6>
          <Skeleton height={80} count={3} />
        </div>
        <div className="u-mb-spacer-base-large">
          <Skeleton height={50} width={300} />
        </div>
      </div>
      <div className="o-col-4@md">
        <div className="c-card c-card--bg-light">
          <div className="c-card__body">
            <div className="c-card__header">
              <h6><Skeleton width={250} /></h6>
              <div className="c-card__desc">
                <p><Skeleton count={3} /></p>
              </div>
            </div>
            <div className="o-row o-row--fluid c-button-group">
              <div className="o-col">
                <Skeleton height={50} />
              </div>
              <div className="o-col c-button-group__inline">
                <Skeleton height={50} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
