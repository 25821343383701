import * as React from 'react';
// Icons
import { ReactComponent as IconAddItem } from 'assets/svg/add-item.svg';

// Components
import DatasetCard from 'components/cards/dataset';
import Listing from 'components/cards/listing';
import { listingContext, ListingSortOption, SortDirection } from 'components/cards/listing-context';

import api from 'api';
import useRequest from 'api/use-request';
import { useState, Suspense } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { usersRefresh } from 'utils/users-refresh-singleton';
import { UserRoleTypes } from 'api/endpoints/users';
import { deleteDataset } from 'api/endpoints/datasets';

export interface IDatasetsListingProps {
  clientId: string
}

const DatasetsListing: React.FunctionComponent<IDatasetsListingProps> = ({ clientId }) => {
  let location = useLocation();
  let searchArgs = queryString.parse(location.search, { parseNumbers: true });
  if (searchArgs.client_id === undefined) {
    searchArgs.client_id = clientId;
  }
  if (searchArgs.limit === undefined) {
    searchArgs.limit = 12;
  }

  let [selection, setSelection] = useState<string[]>([]);

  const currentUser = useRequest(api.users.getCurrentUser());
  let datasets = useRequest(api.datasets.getDatasets(searchArgs));

  let sort_options: Array<ListingSortOption> = [
    { key: 'name', title: 'Name, Ascending', direction: SortDirection.Ascending },
    { key: 'name', title: 'Name, Descending', direction: SortDirection.Descending },
    { key: 'created_at', title: 'Created At, Ascending', direction: SortDirection.Ascending },
    { key: 'created_at', title: 'Created At, Descending', direction: SortDirection.Descending },
  ];

  function deleteDatasets(ids: string[]) {
    let updates: Promise<unknown>[] = [];
    ids.forEach((id) => {
      updates.push(api.datasets.deleteDataset({ id }).fetch());
    });
    Promise.all(updates).finally(() => { 
      datasets.mutate();
      setSelection([]);
    });
  }

  function toggleSelectDatasets(ids: string[]) {
    ids.forEach((id) => {
      const index = selection.indexOf(id);
      if (index > -1) {
        selection.splice(index, 1);
      } else {
        selection.push(id);
      }
    });
    setSelection([...selection]);
  }

  function selectAll(shouldSelect: boolean) {
    if (datasets.data && datasets.data?.data.length > 0) {
      datasets.data?.data.forEach((dataset) => {
        const index = selection.indexOf(dataset.id);
        if (shouldSelect && index < 0) {
          selection.push(dataset.id);
        }
        if (!shouldSelect && index > -1) {
          selection.splice(index, 1);
        }
      });
      setSelection([...selection]);
    }
  }

  React.useEffect(() => {
    if (usersRefresh.needsRefresh) {
      datasets.mutate();
      usersRefresh.needsRefresh = false;
      setSelection([]);
    }
  });


  return (
    <>
      {(datasets.data && datasets.data?.data.length < 1) ? (
        <NoDatasetsPlaceholder />
      ) : (
        <>
          <listingContext.Provider value={{ ...datasets, selection, sort_options }}>
            <Suspense fallback={<div>Loading...</div>}>
              <Listing
                deleteHandler={deleteDatasets}
                selectionHandler={toggleSelectDatasets}
                selectAllHandler={selectAll}
                settingsKey={'Datasets'}
              >
                {datasets.data?.data.map((dataset) =>
                  <DatasetCard
                    deleteHandler={deleteDatasets}
                    selectHandler={toggleSelectDatasets}
                    key={dataset.id}
                    dataset={dataset}
                    shouldShowClient={currentUser.data?.role === UserRoleTypes.SuperAdmin}
                  />
                )}
              </Listing>
            </Suspense>
          </listingContext.Provider>
        </>
      )}
    </>
  );
};

interface iNoDatasetsPlaceholderProps {
}

function NoDatasetsPlaceholder() {
  let history = useHistory();
  let location = useLocation();
  let newDatasetLink = location.pathname + '/new';

  return (
    <div className="u-mb-spacer-base-large">
      <div className="c-add c-add--link u-flex-nowrap@md">
        <div className="c-add__icon">
          <IconAddItem className="o-svg-icon" />
        </div>
        <div className="c-add__body">
          <div className="c-add__title">
            <div onClick={() => { history.push(newDatasetLink) }} className="c-link-cta c-add__link"><span>Add new site</span></div>
          </div>
          <div className="c-add__desc">
            <p>You currently don’t have any sites added, click here to start.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DatasetsListing;
