import * as React from 'react';

import { isFunction } from "lodash";
import { useEffect, useState } from 'react';


export type DashboardInfo = {
  oid: string;
  title: string;
};

interface ISisenseApiDashboardsProviderProps {
  isSisenseAuthenticated: boolean;
}

const SisenseApiDashboardsProvider: React.FunctionComponent<ISisenseApiDashboardsProviderProps> = ({ children, isSisenseAuthenticated }) => {
  const [availableSisenseDashboards, setAvailableSisenseDashboards] = useState<DashboardInfo[] | undefined>();
  const [sisenseRequestFailed, setSisenseRequestFailed] = useState(false);

  useEffect(() => {
          const availableDashboards:DashboardInfo[] = [];
            //feature flag
            availableDashboards.unshift({
              oid: 'PowerBiPages',
              title: 'PowerBiPages',
            })
            availableDashboards.unshift({
              oid: 'PowerBi',
              title: 'PowerBi',
            })
            setAvailableSisenseDashboards(availableDashboards);
  }, []);

  return (
    <>
      {children ?
        isFunction(children)
          ? (children as (sisenseDashboards: DashboardInfo[] | undefined, sisenseRequestFailed: boolean) => React.ReactNode)(availableSisenseDashboards, sisenseRequestFailed)
          : children
        : null}
    </>
  );
};

export default SisenseApiDashboardsProvider;
