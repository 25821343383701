import * as React from 'react';
import { ReactComponent as IconClose } from 'assets/svg/close.svg';
import { ReactComponent as IconGear } from 'assets/svg/gear.svg';
import { ReactComponent as IconMoon } from 'assets/svg/moon.svg';
import { ReactComponent as IconMoonFill } from 'assets/svg/moon--fill.svg';
import useClickAway from 'hooks/use-click-away';
import { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useEffect, useContext } from 'react';
import config from '../../../../../src/client/src/configuration';
import { PageContext } from './page-context';
import { DeviceContext } from './device-context';

const cookieOptions:Cookies.CookieAttributes = { secure: true, sameSite: "None" };
const darkModeCookieName = config.DARKMODE_COOKIE_NAME;

interface ISettingsMenuProps {
}

const SettingsMenu: React.FunctionComponent<ISettingsMenuProps> = (props) => {
  let [isOpen, setIsOpen] = useState(false);
  let [useDarkMode, setUseDarkMode] = useState(false);
  let page = useContext(PageContext);
  let device = useContext(DeviceContext);

  const trigger = React.useRef<HTMLDivElement>(null);
  useClickAway(trigger, () => setIsOpen(false));

  function setDarkMode(darkMode: boolean) {
    window.darkMode(darkMode);
    page.setUsesDarkMode(darkMode);

    setUseDarkMode(darkMode);
    setIsOpen(false);
  }

  function defaultDarkMode() {
    if (page.isKioskMode && device) {
      return device.defaultTheme === 1;
    }
    return page.isKioskMode;
  }

  function saveDarkModePreference(darkMode: boolean) {
    Cookies.set(darkModeCookieName, darkMode ? '1' : '0', cookieOptions);
  }

  useEffect(() => {
    let cookiesDarkMode = Cookies.get(darkModeCookieName);
    if (!cookiesDarkMode) {
      setDarkMode(defaultDarkMode());
    } else {
      setDarkMode(cookiesDarkMode === '1');
    }
  }, [device.defaultTheme]);

    return (
    <div className={classNames(['c-site-notifications', { 'has-popup-open': isOpen }])} ref={trigger}>
        <div className="c-site-notifications__toggle c-popup__pointer" onClick={() => setIsOpen(!isOpen)}>
            <IconGear className="o-svg-icon" />
        </div>
        <div className="c-popup">
            <div className="c-popup__header">
                <p className="c-popup__title">Settings</p>
                <a href="#" className="c-popup__toggle"><IconClose className="o-svg-icon" /></a>
            </div>
            <div className="c-popup__body">
                <ul className="c-popup__list c-popup__list--small c-popup__notifications">
                    { useDarkMode ? (
                        <li className="c-popup__notifications-item--unread">
                            <Link to='' onClick={(e) => {e.preventDefault(); setDarkMode(false); saveDarkModePreference(false);} }><IconMoonFill className="o-svg-icon" /><span>Dark mode on<span>Click to turn off</span></span></Link>
                        </li>
                    ) : (
                        <li className="">
                            <Link to='' onClick={(e) => {e.preventDefault(); setDarkMode(true); saveDarkModePreference(true);} }><IconMoon className="o-svg-icon" /><span>Dark mode<span>Click to turn on</span></span></Link>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    </div>
    );
};

export default SettingsMenu;
