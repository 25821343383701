import * as React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { ReactComponent as IconDropdown } from 'assets/svg/dropdown.svg';
import { ClientObject } from 'api/endpoints/clients';
import { listingContext } from './listing-context';

// Hooks
import { useContext } from 'react';

interface IClientToggleCardAction {
  label: string;
  link: string;
}

interface IClientToggleCardProps {
  compact?: boolean,
  client: ClientObject, 
  action?: IClientToggleCardAction,
  handleToggle: (id: string, nextState: 'on' | 'off') => void,
}

const ClientToggleCard: React.FunctionComponent<IClientToggleCardProps> = ({ compact, client, action, handleToggle }) => {

  const { selection } = useContext(listingContext);

  function isSelected(id: string) {
    if (selection && selection.indexOf(id) > -1) {
      return true;
    }
    return false;
  }

  return (
    <div className="c-listing__item">
      <div className={classNames(['c-card', 'c-card--link', 'c-card--bg-white', { 'is-selected': isSelected(client.id) }, { 'c-card--compact': compact }])}>
        <div className="c-card__body">
          <div className="c-card__content">
            <p className="c-card__title">{client.name}</p>
          </div>
          <div className="c-card__footer">
            {action && isSelected(client.id) &&
              <Link to={action.link} className="c-link-cta-light c-card__link-hidden u-hidden u-inline-flex@sm"><span>{action.label}</span><IconDropdown className="o-svg-icon o-svg-right o-svg-small" /></Link>
            }
          </div>
          <div className="c-card__buttons">
            <div className="c-form-element c-form-element--style-line c-form-element--toggle c-card__toggle">
                <div className="c-form-element__field">
                  <input type="checkbox" id={`check-item-${client.id}`} checked={isSelected(client.id)} onChange={() => handleToggle(client.id, isSelected(client.id)? 'off' : 'on')} />
                  <label htmlFor={`check-item-${client.id}`}></label>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default ClientToggleCard;
