import * as React from "react";
import { Formik, Form as FormikForm, FormikConfig, FormikErrors, useFormikContext } from "formik";
// import * as yup from 'yup';
import * as z from "zod";
import { toFormikSchema } from "utils/formik-zod";
import { useEffect, createContext, useContext, useState } from "react";
import { isFunction, omit } from "lodash";

import Notification from "components/notification";

export enum FormStatus {
  Loading,
  Loaded,
  Submitting,
  Submitted,
  Failed,
  Successful,
}

const FormNotificationContext = createContext<{
  errors?: string[];
  showErrorMessage?: boolean;
  successMessage?: React.ReactNode;
  showFieldsNotice?: boolean;
}>({});

type RequiredValues<T> = {
  [P in keyof T]-?: NonNullable<T[P]>;
};

type InferValuesFromSchema<T extends z.ZodTypeAny> = RequiredValues<z.infer<T>>;

export interface FormProps<
  ValidationSchema extends z.ZodTypeAny,
  Values = InferValuesFromSchema<ValidationSchema>
  > extends FormikConfig<Values> {
  validationSchema: ValidationSchema;
  initialValues: Values;
  errors?: FormikErrors<Values> & { __other: string[] };
  showErrorNotificationMessage?: boolean;
  successMessage?: React.ReactNode;
  autocomplete?: 'off' | 'on';
}

interface HandleFormErrorsProps<
  ValidationSchema extends z.ZodTypeAny,
  Values = InferValuesFromSchema<ValidationSchema>
  > {
  errors?: FormikErrors<Values>;
}

const InjectFormErrors = <T extends z.ZodTypeAny, Values = InferValuesFromSchema<T>>({
  errors,
}: HandleFormErrorsProps<T>) => {
  const { setErrors } = useFormikContext<Values>();
  useEffect(() => {
    if (errors) {
      setErrors(errors as any);
    }
  }, [errors, setErrors]);

  return null;
};

export const FormNotification = () => {
  const { submitCount, isValid, status } = useFormikContext();
  const { errors, showErrorMessage, successMessage, showFieldsNotice } = useContext(FormNotificationContext);

  if (successMessage && status === FormStatus.Successful) {
    return (
      <Notification type="success" scrollTo>
        {successMessage}
      </Notification>
    );
  }

  // Show only the message if the form is invalid after submission
  if (!errors && showErrorMessage && submitCount && !isValid) {
    return (
      <Notification type="error" scrollTo>
        <p>Please make sure all required fields are filled out and then try again.</p>
      </Notification>
    );
  }

  if (!errors || !errors.length) {
    return null;
  }

  return (
    <Notification type="error" scrollTo>
      {showFieldsNotice && 
        <p>Please make sure all required fields are filled out and then try again.</p>
      }
      <ul className="u-my-1">
        {errors.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    </Notification>
  );
};

export const Form = <T extends z.ZodTypeAny>({
  children,
  showErrorNotificationMessage,
  successMessage,
  validationSchema,
  onSubmit,
  autocomplete = 'off',
  ...formikProps
}: FormProps<T>) => {
  // function extendChildren(elements: React.ReactNode, props: any) {
  //   if (elements && React.isValidElement(elements)){
  //     const extendedChildren = React.Children.map(elements, (child: React.ReactElement) => {
  //       if (React.isValidElement(child)) {
  //         return React.cloneElement(child, props);
  //       } else {
  //         return child;
  //       }
  //     });
  //     return elements;
  //   }
  //   return elements;
  // }

  const [errors, setErrors] = useState([] as string[]);
  const [showRequiredFieldsNotice, setShowRequiredFieldsNotice] = useState(true);

  const submitHandler: FormProps<T>["onSubmit"] = (values, helpers) => {
    const submitRes = onSubmit(values, helpers);

    if (submitRes) {
      let newErrors: string[] = [];
      setErrors(newErrors);
      submitRes
        .catch((error) => {
          console.log(error);
          if (error.response.status && (error.response.status === 401 || error.response.status === 409)) {
            setShowRequiredFieldsNotice(false);
          }

          if (error.response.data.errors && 
            typeof error.response.data.errors === 'object' &&
            !Array.isArray(error.response.data.errors) &&
            error.response.data.errors !== null) {
            
              Object.keys(error.response.data.errors).map((key: string) => {
                newErrors.push(error.response.data.errors[key]);
              });
          } else if (error.response.data.errors && error.response.data.errors[0]) {
            newErrors.push(error.response.data.errors[0]);
          } else if (error.response.data.message) {
            newErrors.push(error.response.data.message);
          } else if (error.response.data.msg) {
            newErrors.push(error.response.data.msg);
          }
          setErrors(newErrors);
        })
        .finally(() => {
          helpers.setSubmitting(false);
        });
    }
  };

  return (
    <Formik
      {...{
        ...formikProps,
        validationSchema: toFormikSchema(validationSchema),
        onSubmit: submitHandler,
      }}
    >
      {(formikBag) => (
        <FormikForm autoComplete={autocomplete}>
          <FormNotificationContext.Provider
            value={{
              errors,
              showErrorMessage: showErrorNotificationMessage,
              successMessage,
              showFieldsNotice: showRequiredFieldsNotice,
            }}
          >
            {children
              ? isFunction(children)
                ? (children as (props: typeof formikBag) => React.ReactNode)(formikBag)
                : children
              : null}
          </FormNotificationContext.Provider>
        </FormikForm>
      )}
    </Formik>
  );
};
