import HttpClient from 'api/http-client';
import { LinksSchema, MetaSchema, GetListingRequestSchema } from 'api/endpoints/common';
import * as z from 'zod';
import { DatasetObjectSchema } from '../datasets';
import { DashboardObjectSchema } from '../dashboards';
import { UserObjectSchema } from '../users';
import { UsergroupObjectSchema } from '../usergroups';

const dateRegex = /^(?<fullyear>\d{4})-(?<month>0[1-9]|1[0-2])-(?<mday>0[1-9]|[12][0-9]|3[01])T(?<hour>[01][0-9]|2[0-3]):(?<minute>[0-5][0-9]):(?<second>[0-5][0-9]|60)(?<secfrac>\.[0-9]+)?(Z|(\+|-)(?<offset_hour>[01][0-9]|2[0-3]):(?<offset_minute>[0-5][0-9]))$/i;

export const DeviceObjectSchema = z.object({
  id: z.string(),
  name: z.string(),
  activation_code: z.string(),
  client_id: z.string(),
  user_id: z.string(),
  created_at: z.string().regex(dateRegex),
  updated_at: z.string().regex(dateRegex),
  token: z.string().optional(),
  theme: z.number(),
  usergroup_id: z.string(),
  usergroup: UsergroupObjectSchema.optional(),
  user: UserObjectSchema.optional(),
  dataset_id: z.string().optional(),
  dataset: DatasetObjectSchema.optional(),
});

export const DeviceFormSchema = z.object({
  id: z.string().optional(),
  name: z.string(),
  activation_code: z.string(),
  user_id: z.string().optional(),
  client_id: z.string().optional(),
  theme: z.number(),
  usergroup_id: z.string().optional(),
  dataset_id: z.string().optional(),
});

export const DeviceObjectRequestSchema = DeviceObjectSchema.extend({
  id: z.string().optional(),
  client_id: z.string().optional(),
  user_id: z.string().optional(),
  usergroup_id: z.string().optional(),
  dataset_id: z.string().optional(),
}).omit({
  created_at: true,
  updated_at: true,
  usergroup: true,
  dataset: true,
  user: true,
});

export const InactiveDeviceObjectSchema = DeviceObjectSchema.extend({
  name: z.string().optional(),
  client_id: z.string().optional(),
  user_id: z.string().optional(),
});

export const GetDevicesResponseSchema = z.object({
  data: z.array(DeviceObjectSchema),
  links: LinksSchema,
  meta: MetaSchema,
});

export const GetDevicesRequestSchema = GetListingRequestSchema.extend({
  client_id: z.string().optional(),
  user_id: z.string().optional(),
});

export const DeviceObjectIdSchema = z.object({
  id: z.string(),
});

export const DeviceRegistrationRequestSchema = z.object({
  device_key: z.string(),
});
export const DeviceActivationRequestSchema = z.object({
  device_key: z.string(),
  activation_code: z.string(),
});

export type DeviceObject = z.infer<typeof DeviceObjectSchema>
export type InactiveDeviceObject = z.infer<typeof InactiveDeviceObjectSchema>
export type DeviceFormObject = z.infer<typeof DeviceFormSchema>
export type DeviceObjectRequest = z.infer<typeof DeviceObjectRequestSchema>
export type GetDevicesRequest = z.infer<typeof GetDevicesRequestSchema>
export type GetDevicesResponse = z.infer<typeof GetDevicesResponseSchema>
export type DeviceObjectId = z.infer<typeof DeviceObjectIdSchema>
export type DeviceRegistrationRequest = z.infer<typeof DeviceRegistrationRequestSchema>
export type DeviceActivationRequest = z.infer<typeof DeviceActivationRequestSchema>


export function getDevices(data: GetDevicesRequest) {
  return new HttpClient().request<GetDevicesResponse>({
    method: 'get',
    url: '/devices',
    validator: GetDevicesResponseSchema,
    params: data,
  });
}

export function newDevice(data: DeviceObjectRequest) {
  return new HttpClient().request<DeviceObject>({
    method: 'post',
    url: '/devices',
    validator: DeviceObjectSchema,
    data,
  });
}

export function getDevice({ id }: DeviceObjectId) {
  return new HttpClient().request<DeviceObject>({
    method: 'get',
    url: `/devices/${id}`,
    validator: DeviceObjectSchema,
  });
}

export function getDeviceByKey(deviceKey: string) {
  return new HttpClient().request<DeviceObject>({
    method: 'get',
    url: `/devices/key/${deviceKey}`,
    validator: DeviceObjectSchema,
  });
}

export function getDeviceByCode(activationCode: string) {
  return new HttpClient().request<InactiveDeviceObject>({
    method: 'get',
    url: `/devices/code/${activationCode}`,
    validator: InactiveDeviceObjectSchema,
  });
}

export function deleteDevice({ id }: DeviceObjectId) {
  return new HttpClient().request<void>({
    method: 'delete',
    url: `/devices/${id}`,
  });
}

export function updateDevice(data: DeviceObjectRequest) {
  return new HttpClient().request<DeviceObject>({
    method: 'put',
    url: `/devices/${data.id}`,
    data,
  });
}

export function registerDevice(data: DeviceRegistrationRequest) {
  return new HttpClient().request<DeviceObject>({
    method: 'post',
    url: `/devices/register`,
    data,
  });
}

export function checkActivation(data: DeviceActivationRequest) {
  return new HttpClient().request<DeviceObject>({
    method: 'post',
    url: `/devices/check-activation`,
    data,
  });
}
