import * as React from 'react';

// Icons
import { ReactComponent as IconMore } from 'assets/svg/more.svg';
import { ReactComponent as IconClose } from 'assets/svg/close.svg';
import { ReactComponent as IconUser } from 'assets/svg/user.svg';
import { ReactComponent as IconOut } from 'assets/svg/out.svg';
import { ReactComponent as IconGear } from 'assets/svg/gear.svg';
import { ReactComponent as IconTV } from 'assets/svg/tv.svg';

import useRequest from 'api/use-request';
import api from 'api';
import { useContext, useRef, useState } from 'react';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import useClickAway from 'hooks/use-click-away';
import { sizedUserProfileImageURL } from 'utils/imgix-helpers';
import { store } from 'store';
import { PageContext } from './page-context';
import configuration from '../../../../../src/client/src/configuration';

interface IUserMenuProps {
}

const UserMenu: React.FunctionComponent<IUserMenuProps> = (props) => {
  let user = useRequest(api.users.getCurrentUser());
  let [isOpen, setIsOpen] = useState(false);
  let history = useHistory();
  let page = useContext(PageContext);

  const trigger = useRef<HTMLDivElement>(null);
  useClickAway(trigger, () => setIsOpen(false));

  function userInitials(first: string, last: string) {
    return first[0] + last[0];
  }

  function logout() {
    store.logout();
  }

  function renderAvatar() {
    if (page.isKioskMode) {
      return <span className="c-site-user__toggle-initial"><IconTV className="o-svg-icon" /></span>;

    } else {
      if (user.data) {
        if (user.data.profile_image_url === '*') { // TODO: implement avatar image handling
          return <span className="c-site-user__toggle-initial">{userInitials(user.data.first_name, user.data.last_name)}</span>
        } else {
          return <img src={sizedUserProfileImageURL(user.data.profile_image_url)} alt="User Profile" />;
        }
      }
    }
  }

  if (!user.data) {
    return null;
  } else {
    return (
      <div className={classNames(['c-site-user', { 'has-popup-open': isOpen }])} ref={trigger}>
        <div className="c-site-user__toggle c-popup__pointer" onClick={() => setIsOpen(!isOpen)}>
          {renderAvatar()}
          <span className="c-site-user__toggle-more"></span>
          <IconMore className="o-svg-icon o-svg-right" />
        </div>
        <div className="c-popup">
          <div className="c-popup__header c-popup__header--lg">
            <p className="c-popup__username">{user.data.first_name} {user.data.last_name}<span>{user.data.role_name}</span></p>
            <div className="c-popup__toggle"><IconClose className="o-svg-icon" /></div>
          </div>
          <div className="c-popup__body">
            <ul className="c-popup__list c-popup__list--small">
              {user.data?.role === api.users.UserRoleTypes.GroupAdmin && user.data?.clients.length > 0 && (
                <li>
                  <Link to={'/client/' + user.data?.clients[0].id + '/general'}><IconGear className="o-svg-icon" /><span>Account</span></Link>
                </li>
              )}
              {user.data?.role === api.users.UserRoleTypes.SiteAdmin && user.data?.clients.length > 0 && (
                <li>
                  <Link to={'/client/' + user.data?.clients[0].id + '/users'}><IconGear className="o-svg-icon" /><span>Account</span></Link>
                </li>
              )}

              <li>
                <Link to={`/profile/general`}><IconUser className="o-svg-icon" /><span>Profile</span></Link>
              </li>
              {/* <li>
                <Link to={`/profile/devices`}><IconUser className="o-svg-icon" /><span>Devices</span></Link>
              </li> */}
              <li className="c-popup__divider c-popup__divider--top">
                <Link to="/login" onClick={logout}><IconOut className="o-svg-icon" /><span>Log out</span></Link>
              </li>
              <li className="c-popup__divider c-popup__divider--top">
                <span style={{fontSize: '10px'}}>Version: {configuration.APP_VERSION}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
};

export default UserMenu;
