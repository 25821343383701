import HttpClient from 'api/http-client';
import { LinksSchema, MetaSchema, GetListingRequestSchema } from 'api/endpoints/common';
import * as z from 'zod';
import { DashboardObjectSchema } from '../dashboards';

const dateRegex = /^(?<fullyear>\d{4})-(?<month>0[1-9]|1[0-2])-(?<mday>0[1-9]|[12][0-9]|3[01])T(?<hour>[01][0-9]|2[0-3]):(?<minute>[0-5][0-9]):(?<second>[0-5][0-9]|60)(?<secfrac>\.[0-9]+)?(Z|(\+|-)(?<offset_hour>[01][0-9]|2[0-3]):(?<offset_minute>[0-5][0-9]))$/i;
const fqdnRegex = /^(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/i;

export const DatasetObjectSchema = z.object({
  id: z.string(),
  client_id: z.string(),
  name: z.string(),
  connection: z.string(),
  hostname: z.string().optional(),
  timezone: z.string().optional(),
  created_at: z.string().regex(dateRegex),
  updated_at: z.string().regex(dateRegex),
  dashboards: z.array(DashboardObjectSchema).optional(),
  sisense_id: z.string().optional(),
});

export const DatasetFormSchema = z.object({
  id: z.string(),
  client_id: z.string(),
  name: z.string(),
  connection: z.string().regex(/^\S+$/, 'The string can\'t contain spaces.'),
  hostname: z.string().regex(fqdnRegex, 'The hostname must be a valid domain name, without the protocol, port or trailing slashes.'),
  timezone: z.string(),
});

export const DatasetObjectRequestSchema = DatasetObjectSchema.extend({
  id: z.string().optional(),
  client_id: z.string().optional(),
  connection: z.string().optional(),
  hostname: z.string().optional(),
  timezone: z.string().optional(),
}).omit({
  created_at: true,
  updated_at: true,
});

export const GetDatasetsResponseSchema = z.object({
  data: z.array(DatasetObjectSchema),
  links: LinksSchema,
  meta: MetaSchema,
});

export const MyDatasetsResponseSchema = z.object({
  data: z.array(DatasetObjectSchema)
});


export const GetDatasetsRequestSchema = GetListingRequestSchema.extend({
  client_id: z.string().optional(),
});

export const DatasetObjectIdSchema = z.object({
  id: z.string(),
});


export type DatasetObject = z.infer<typeof DatasetObjectSchema>
export type DatasetFormObject = z.infer<typeof DatasetFormSchema>
export type DatasetObjectRequest = z.infer<typeof DatasetObjectRequestSchema>
export type GetDatasetsRequest = z.infer<typeof GetDatasetsRequestSchema>
export type GetDatasetsResponse = z.infer<typeof GetDatasetsResponseSchema>
export type DatasetObjectId = z.infer<typeof DatasetObjectIdSchema>


export function getDatasets(data: GetDatasetsRequest) {
  return new HttpClient().request<GetDatasetsResponse>({
    method: 'get',
    url: '/datasets',
    validator: GetDatasetsResponseSchema,
    params: data,
  });
}

export function newDataset(data: DatasetObjectRequest) {
  return new HttpClient().request<DatasetObject>({
    method: 'post',
    url: '/datasets',
    validator: DatasetObjectSchema,
    data,
  });
}

export function getDataset({ id }: DatasetObjectId) {
  return new HttpClient().request<DatasetObject>({
    method: 'get',
    url: `/datasets/${id}`,
    validator: DatasetObjectSchema,
  });
}

export function deleteDataset({ id }: DatasetObjectId) {
  return new HttpClient().request<void>({
    method: 'delete',
    url: `/datasets/${id}`,
  });
}

export function updateDataset(data: DatasetObjectRequest) {
  return new HttpClient().request<DatasetObject>({
    method: 'put',
    url: `/datasets/${data.id}`,
    data,
  });
}

export function myDatasets(data: GetDatasetsRequest) {
  return new HttpClient().request<GetDatasetsResponse>({
    method: 'get',
    url: `/datasets/for-me`,
    validator: MyDatasetsResponseSchema,
    params: data,
  });
}

export function switchDataset({ id }: DatasetObjectId) {
  return new HttpClient().request<void>({
    method: 'get',
    url: `/datasets/${id}/switch`,
  });
}
