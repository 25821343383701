import HttpClient from 'api/http-client';
import { LinksSchema, MetaSchema, GetListingRequestSchema } from 'api/endpoints/common';
import * as z from 'zod';
import { DatasetObjectSchema } from '../datasets';
import { DashboardObjectSchema } from '../dashboards';

const dateRegex = /^(?<fullyear>\d{4})-(?<month>0[1-9]|1[0-2])-(?<mday>0[1-9]|[12][0-9]|3[01])T(?<hour>[01][0-9]|2[0-3]):(?<minute>[0-5][0-9]):(?<second>[0-5][0-9]|60)(?<secfrac>\.[0-9]+)?(Z|(\+|-)(?<offset_hour>[01][0-9]|2[0-3]):(?<offset_minute>[0-5][0-9]))$/i;

export const UsergroupObjectSchema = z.object({
  id: z.string(),
  client_id: z.string(),
  name: z.string(),
  created_at: z.string().regex(dateRegex),
  updated_at: z.string().regex(dateRegex),
  datasets: z.array(DatasetObjectSchema),
  dashboards: z.array(DashboardObjectSchema),
  sisense_id: z.string().optional(),
  app_controlled: z.boolean(),
});

export const UsergroupFormSchema = z.object({
  id: z.string(),
  client_id: z.string(),
  name: z.string(),
  datasets: z.array(z.string()),
  dashboards: z.array(z.string())
});

export const UsergroupObjectRequestSchema = UsergroupObjectSchema.extend({
  id: z.string().optional(),
  client_id: z.string().optional(),
}).omit({
  created_at: true,
  updated_at: true,
});

export const GetUsergroupsResponseSchema = z.object({
  data: z.array(UsergroupObjectSchema),
  links: LinksSchema,
  meta: MetaSchema,
});

export const GetUsergroupsRequestSchema = GetListingRequestSchema.extend({
  client_id: z.string().optional(),
  dataset_id: z.string().optional(),
});

export const UsergroupObjectIdSchema = z.object({
  id: z.string(),
});


export type UsergroupObject = z.infer<typeof UsergroupObjectSchema>
export type UsergroupFormObject = z.infer<typeof UsergroupFormSchema>
export type UsergroupObjectRequest = z.infer<typeof UsergroupObjectRequestSchema>
export type GetUsergroupsRequest = z.infer<typeof GetUsergroupsRequestSchema>
export type GetUsergroupsResponse = z.infer<typeof GetUsergroupsResponseSchema>
export type UsergroupObjectId = z.infer<typeof UsergroupObjectIdSchema>


export function getUsergroups(data: GetUsergroupsRequest) {
  return new HttpClient().request<GetUsergroupsResponse>({
    method: 'get',
    url: '/usergroups',
    validator: GetUsergroupsResponseSchema,
    params: data,
  });
}

export function newUsergroup(data: UsergroupFormObject) {
  return new HttpClient().request<UsergroupObject>({
    method: 'post',
    url: '/usergroups',
    validator: UsergroupObjectSchema,
    data,
  });
}

export function getUsergroup({ id }: UsergroupObjectId) {
  return new HttpClient().request<UsergroupObject>({
    method: 'get',
    url: `/usergroups/${id}`,
    validator: UsergroupObjectSchema,
  });
}

export function deleteUsergroup({ id }: UsergroupObjectId) {
  return new HttpClient().request<void>({
    method: 'delete',
    url: `/usergroups/${id}`,
  });
}

export function updateUsergroup(data: UsergroupFormObject) {
  return new HttpClient().request<UsergroupObject>({
    method: 'put',
    url: `/usergroups/${data.id}`,
    data,
  });
}
