import HttpClient from 'api/http-client';
import * as z from 'zod';
import { GetListingRequestSchema, LinksSchema, MetaSchema } from 'api/endpoints/common';
import { ClientObjectSchema } from '../clients';
import { UsergroupObjectSchema } from '../usergroups';
import { DatasetObjectSchema } from '../datasets';

const dateRegex = /^(?<fullyear>\d{4})-(?<month>0[1-9]|1[0-2])-(?<mday>0[1-9]|[12][0-9]|3[01])T(?<hour>[01][0-9]|2[0-3]):(?<minute>[0-5][0-9]):(?<second>[0-5][0-9]|60)(?<secfrac>\.[0-9]+)?(Z|(\+|-)(?<offset_hour>[01][0-9]|2[0-3]):(?<offset_minute>[0-5][0-9]))$/i;

export enum UserRoleTypes {
  SuperAdmin = "super-admin",
  GroupAdmin = "group-admin",
  SiteAdmin = "site-admin",
  User = "user"
}
export type UserRole = { role: UserRoleTypes, title: string, priority: number };

export const UserRoles: UserRole[] = [
  { role: UserRoleTypes.SuperAdmin, title: 'Locus Admin', priority: 100 },
  { role: UserRoleTypes.GroupAdmin, title: 'Client Admin', priority: 50 },
  { role: UserRoleTypes.SiteAdmin, title: 'Site Admin', priority: 30 },
  { role: UserRoleTypes.User, title: 'Viewer', priority: 1 },
];

export const UserObjectSchema = z.object({
  id: z.string(),
  first_name: z.string().nonempty(),
  last_name: z.string().nonempty(),
  created_at: z.string().regex(dateRegex).optional(),
  updated_at: z.string().regex(dateRegex).optional(),
  last_seen: z.string().regex(dateRegex).optional(),
  email: z.string().email(),
  clients: z.array(ClientObjectSchema.omit({
    users_count: true,
    dashboards_count: true,
    datasets_count: true,
  })),
  usergroups: z.array(UsergroupObjectSchema),
  client_name: z.string().nonempty().optional(),
  role: z.string().nonempty(),
  role_name: z.string().nonempty().optional(),
  email_verified_at: z.string().regex(dateRegex).optional(),
  profile_image_url: z.string(),
  client_logo_url: z.string().optional(),
  client_logo_dark_url: z.string().optional(),
  sisense_id: z.string().optional(),
  current_dataset: z.string().optional(),
  available_clients: z.array(ClientObjectSchema.omit({
    users_count: true,
    dashboards_count: true,
    datasets_count: true,
  })),
  datasets: z.array(DatasetObjectSchema),
  device_id: z.string().optional(),
});

export const UserFormSchema = z.object({
  id: z.string(),
  first_name: z.string().nonempty(),
  last_name: z.string().nonempty(),
  email: z.string().email(),
  role: z.string().nonempty(),
  profile_image: z.string(),
  profile_image_url: z.string().optional(),
  clients: z.array(z.string()),
  usergroups: z.array(z.string()),
  datasets: z.array(z.string()),
  client_id: z.string().optional(),
});

export const CreateUserResponseSchema = UserObjectSchema;

export const GetUsersResponseSchema = z.object({
  data: z.array(UserObjectSchema),
  links: LinksSchema,
  meta: MetaSchema,
});

export const GetUsersRequestSchema = GetListingRequestSchema;

export const UserObjectIdSchema = z.object({
  id: z.string(),
});

export const SendWelcomeEmailRequestObjectSchema = z.object({
  user_id: z.string(),
  return_url: z.string(),
});

export type UserObject = z.infer<typeof UserObjectSchema>
export type UserFormObject = z.infer<typeof UserFormSchema>
export type GetUsersRequest = z.infer<typeof GetUsersRequestSchema>
export type GetUsersResponse = z.infer<typeof GetUsersResponseSchema>
export type UserObjectId = z.infer<typeof UserObjectIdSchema>
export type SendWelcomeEmailRequestObject = z.infer<typeof SendWelcomeEmailRequestObjectSchema>

export function getUsers(data: GetUsersRequest) {
  return new HttpClient().request<GetUsersResponse>({
    method: 'get',
    url: '/users',
    validator: GetUsersResponseSchema,
    params: data,
  });
}

export function newUser(data: Omit<UserFormObject, 'id'>) {
  return new HttpClient().request<UserObject>({
    method: 'post',
    url: '/users',
    validator: UserObjectSchema,
    data,
  });
}

export function getUser(data: UserObjectId) {
  return new HttpClient().request<UserObject>({
    method: 'get',
    url: `/users/${data.id}`,
    validator: UserObjectSchema,
  });
}

export function deleteUser(data: UserObjectId) {
  return new HttpClient().request({
    method: 'delete',
    url: `/users/${data.id}`,
    data,
  });
}

export function updateUser(data: UserFormObject, user_id: string) {
  return new HttpClient().request<UserObject>({
    method: 'put',
    url: `/users/${user_id}`,
    data,
  });
}

export function getCurrentUser() {
  return new HttpClient().request<UserObject>({
    method: 'get',
    url: `/me`,
  });
}

export function sendWelcomeEmail(data: SendWelcomeEmailRequestObject) {
  return new HttpClient().request({
    method: 'post',
    url: '/users/send-welcome-email',
    data,
  });
}

export function roleForUserRoleType(userRole: UserRoleTypes): UserRole {
  let matchedRole = UserRoles[UserRoles.length - 1];
  UserRoles.forEach(role => {
    if (role.role === userRole) matchedRole = role;
  });
  return matchedRole;
}

export function roleForUser(user: UserObject): UserRole {
  return roleForUserRoleType(user.role as UserRoleTypes);
}

export function allowedRolesForUser(user: UserObject, userBeingEdited: UserObject): UserRole[] {
  let allowedRoles: UserRole[] = [];
  let editorRole = roleForUser(user);
  let editedRole = roleForUser(userBeingEdited);

  UserRoles.forEach(role => {
    if (editorRole.role === UserRoleTypes.SuperAdmin ||
      (editedRole.priority <= editorRole.priority && editorRole.priority > role.priority) ||
      editorRole.role == role.role) {

      allowedRoles.push(role);
    }
  });
  return allowedRoles;
}

export function userFulfillsRole(user: UserObject | undefined, role: UserRoleTypes) {
  if (user) {
    let currentUserRole = roleForUser(user);
    return currentUserRole.priority >= roleForUserRoleType(role).priority;
  }
  return false;
}
