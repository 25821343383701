import HttpClient from 'api/http-client';
import * as z from 'zod';

const UserSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string().email(),
});

export type User = z.infer<typeof UserSchema>

export function getMe() {
  return new HttpClient().request<User>({
    method: 'get',
    url: '/auth/me',
    validator: UserSchema,
  });
}
