import HttpClient from 'api/http-client';
import * as z from 'zod';

export const GetStatusResponseSchema = z.object({
  db: z.boolean(),
  pending: z.number(),
  failed: z.number(),
  emoji: z.string(),
  version: z.any(),
  build: z.any(),
});

export type GetStatusResponse = z.infer<typeof GetStatusResponseSchema>

export function getStatus() {
  return new HttpClient().request<GetStatusResponse>({
    method: 'get',
    url: '/status',
    validator: GetStatusResponseSchema,
  });
}