export const powerbiLightTheme = {
  "name": "Light Theme",
  "visualStyles": {
    "lineClusteredColumnComboChart": {
      "*": {
        "general": [{
          "responsive": true,
          "keepLayerOrder": true
        }],
        "legend": [{
          "show": true,
          "position": "Top",
          // "showTitle": true,
          // "titleText": "",
          // "labelColor": { "solid": { "color": "#01B8AA"}},
          // "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "fontSize": 10
        }],
        "categoryAxis": [{
          "show": true,
          // "position": "Right",
          "labelColor": { "solid": { "color": "#000"}},
          // "fontSize": 14,
          // "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "preferredCategoryWidth": 30,
          // "maxMarginFactor": 28,
          // "innerPadding": 22,
          // "concatenateLabels": true,
          // "showAxisTitle": true,
          // "axisStyle": "showTitleOnly",
          // "titleColor": { "solid": { "color": "#fff"}},
          // "titleText": "asdf",
          // "titleFontSize": 12,
          // "titleFontFamily": "Segoe UI"
        }],
        "valueAxis": [{
          "show": true,
          // "position": "Right",
          // "axisScale": "Linear",
          // "start": 0,
          // "end": 10,
          "labelColor": { "solid": { "color": "#000"}},
          // "fontSize": 14,
          // "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "labelDisplayUnits": "0",
          // "labelPrecision": "Auto",
          // "showAxisTitle": true,
          // "axisStyle": "showTitleOnly",
          "color": { "solid": { "color": "#000"}},
          // "titleText": "asdf",
          // "titleFontSize": 12,
          // "titleFontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          "gridlineShow": true,
          "gridlineColor": { "solid": { "color": "#e4e4e4"}},
          "gridlineThickness": 2,
          "gridlineStyle": "solid",
          // "secShow": true,
          // "alignZeros": true,
          // "secAxisLabel": "asdfsadf",
          // "secPosition": "Right",
          // "secAxisScale": "Log",
          // "secStart": 0,
          // "secEnd": 10,
          "secLabelColor": { "solid": { "color": "#000"}},
          // "secFontSize": 12,
          // "secFontFamily": "Verdana",
          // "secLabelDisplayUnits": "0",
          // "secLabelPrecision":  2,
          // "secShowAxisTitle": true,
          // "secAxisStyle": "showTitleOnly",
          "secTitleColor": { "solid": { "color": "#000"}},
          // "secTitleText": "aaaa",
          // "secTitleFontSize": 10,
          // "secTitleFontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif"
        }],
        // "dataPoint": [{
        //   "defaultColor": { "solid": { "color": "#448822"}}
        // }],
        "labels": [{
          // "show": true,
          "color": { "solid": { "color": "#000"}},
          // "labelDisplayUnits": "0",
          // "labelPrecision": 1,
          // "labelOrientation": "vertical",
          // "labelPosition": "OutsideEnd",
          // "fontSize": 11,
          // "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "enableBackground": true,
          // "backgroundColor": { "solid": { "color": "#0928DD"}},
          // "backgroundTransparency": 30
        }],	
        // "lineStyles": [{
        //   "shadeArea": true,
        //   "strokeWidth": 4,
        //   "strokeLineJoin": "bevel",
        //   "showMarker": true,
        //   "markerShape": "triangle",
        //   "markerSize": 3,
        //   "markerColor": { "solid": { "color": "#448822"}}
        // }],
        // "plotArea": [{
        //   "transparency": 20
        // }]
      },
    },
    "actionButton": {
      "*": {
        "general": [{
          "responsive": true,
          "keepLayerOrder": true
        }],
        "background": [{
          // "show": true,
          "color": { "solid": { "color": "#fff"}},
          // "transparency": 100
        }],
        // "border": [{
        //   "color": { "solid": { "color": "#808080"}},
        //   "show": true,
        //   "radius": 0
        // }],
        "title": [{
          // "show": true,
          "fontColor": { "solid": { "color": "#000" } },
          "background": { "solid": { "color": "#fff" } },
          // "alignment": "center"
        }],
        "visualHeader": [{
          "show": true
        }],
        "outline": [{
          "show": true
        },
        // {
        //   "$id": "default",
        //   "weight": 5
        // },
        // {
        //   "$id": "hover",
        //   "weight": 5
        // },
        // {
        //   "$id": "selected",
        //   "weight": 5
        //         },
        {
          "$id": "disabled",
          "weight": 5
                }
              ],
        "fill": [
              {
          "show": true
        },
              {
                "$id": "default",
                "transparency": 100,
                "fillColor": {"solid":{"color":"#fff"}}
              },
        ],

      }
    },
    "columnChart": {
      "*": {
        "general": [{
          "responsive": true
        }],
        "legend": [{
          "show": true,
          // "position": "Top",
          // "showTitle": true,
          // "titleText": "",
          "labelColor": { "solid": { "color": "#000"}},
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "fontSize": 10
        }],
        "categoryAxis": [{
          // "show": true,
          // "position": "Right",
          "labelColor": { "solid": { "color": "#000"}},
          // "fontSize": 14,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "preferredCategoryWidth": 30,
          // "maxMarginFactor": 28,
          // "innerPadding": 22,
          // "concatenateLabels": true,
          // "showAxisTitle": true,
          // "axisStyle": "showTitleOnly",
          "titleColor": { "solid": { "color": "#000"}},
          // "titleText": "asdf",
          // "titleFontSize": 12,
          // "titleFontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif"
        }],
        "valueAxis": [{
          // "show": true,
          // "position": "Right",
          // "axisScale": "Linear",
          // "start": 0,
          // "end": 10,
          "labelColor": { "solid": { "color": "#000"}},
          // "fontSize": 14,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "labelDisplayUnits": "0",
          // "labelPrecision": "Auto",
          // "showAxisTitle": true,
          // "axisStyle": "showTitleOnly",
          "color": { "solid": { "color": "000"}},
          // "titleText": "asdf",
          // "titleFontSize": 12,
          // "titleFontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "gridlineShow": true,
          "gridlineColor": { "solid": { "color": "#e4e4e4"}},
          // "gridlineThickness": 2,
          "gridlineStyle": "solid"
        }],
        // "dataPoint": [{
        //   "defaultColor": { "solid": { "color": "#448822"}}
        // }],
        "labels": [{
          // "show": true,
          "color": { "solid": { "color": "#000"}},
          // "labelDisplayUnits": "0",
          // "labelOrientation": "vertical",
          // "labelPosition": "OutsideEnd",
          // "fontSize": 11,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "enableBackground": true,
          // "backgroundColor": { "solid": { "color": "#0928DD"}},
          // "backgroundTransparency": 30
        }],
        // "plotArea": [{
        //   "transparency": 20
        // }]
      }
    },
    "card": {
      "*": {
        "general": [{
          "responsive": true,
          "keepLayerOrder": true
        }],
        "labels": [{
          "color": { "solid": { "color": "#000"}},
          // "labelDisplayUnits": "0",
          // "labelPrecision": 0,
          "fontSize": 10,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif"
        }],
        "categoryLabels": [{
          "show": true,
          "color": { "solid": { "color": "#000"}},
          "fontSize": 10,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif"
        }],
        "wordWrap": [{
          "show": true
        }],
        // "background": [{
        //     "show": true,
        //     "color": {
        //       "solid": {
        //         "color": "#000000"
        //       }
        //     },
        //     "transparency": 0
        //   }
        // ],
          "title": [{
            "show": true,
            "fontColor": { "solid": { "color": "#000" } },
            // "background": { "solid": { "color": "#062b60" } },
            "alignment": "left",
            "fontSize": 11,
            "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif"
          }],
          // "border": [{
          //   "show": true,
          //   "color": { "solid": { "color": "#062b60"}}
          // }]
      }
    },
    "clusteredColumnChart": {
      "*": {
        "general": [{
          "responsive": true,
          "keepLayerOrder": true
        }],
        "legend": [{
          "show": true,
          // "position": "Top",
          // "showTitle": true,
          // "titleText": "",
          "labelColor": { "solid": { "color": "#000"}},
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          "fontSize": 10
        }],
        "categoryAxis": [{
          // "show": true,
          "labelColor": { "solid": { "color": "#000"}},
          "fontSize": 10,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          "preferredCategoryWidth": 30,
          "maxMarginFactor": 28,
          "innerPadding": 22,
          // "concatenateLabels": true,
          // "showAxisTitle": true,
          "axisStyle": "showTitleOnly",
          "titleColor": { "solid": { "color": "#000"}},
          // "titleText": "asdf",
          "titleFontSize": 12,
          "titleFontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif"
        }],
        "valueAxis": [{
          "show": true,
          "position": "Right",
          "axisScale": "Linear",
          // "start": 0,
          // "end": 10,
          "labelColor": { "solid": { "color": "#000"}},
          "fontSize": 14,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "labelDisplayUnits": "0",
          // "labelPrecision": "Auto",
          // "showAxisTitle": true,
          // "axisStyle": "showTitleOnly",
          "color": { "solid": { "color": "000"}},
          // "titleText": "asdf",
          "titleFontSize": 12,
          "titleFontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "gridlineShow": true,
          "gridlineColor": { "solid": { "color": "#e4e4e4"}},
          "gridlineThickness": 2,
          "gridlineStyle": "solid"
        }],
        // "dataPoint": [{
        //   "defaultColor": { "solid": { "color": "#448822"}}
        // }],
        "labels": [{
          // "show": true,
          "color": { "solid": { "color": "#fff"}},
          // "labelDisplayUnits": "0",
          // "labelPrecision": 1,
          // "labelOrientation": "vertical",
          // "labelPosition": "OutsideEnd",
          "fontSize": 11,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          "enableBackground": true,
          // "backgroundColor": { "solid": { "color": "#0928DD"}},
          "backgroundTransparency": 30
        }],
        "plotArea": [{
          "transparency": 20
        }]
      }
    },
    "clusteredBarChart": {
      "*": {
        "general": [{
          "responsive": true,
          "keepLayerOrder": true
        }],
        "legend": [{
          // "show": true,
          // "position": "Top",
          // "showTitle": true,
          // "titleText": "",
          "labelColor": { "solid": { "color": "#000"}},
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          "fontSize": 10
        }],
        "categoryAxis": [{
          // "show": true,
          // "position": "Right",
          "labelColor": { "solid": { "color": "#000"}},
          "fontSize": 10,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          "preferredCategoryWidth": 30,
          "maxMarginFactor": 28,
          "innerPadding": 22,
          // "concatenateLabels": true,
          // "showAxisTitle": true,
          // "axisStyle": "showTitleOnly",
          "titleColor": { "solid": { "color": "#000"}},
          // "titleText": "asdf",
          "titleFontSize": 12,
          "titleFontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif"
        }],
        "valueAxis": [{
          // "show": true,
          // "position": "Right",
          // "axisScale": "Linear",
          // "start": 0,
          // "end": 10,
          "labelColor": { "solid": { "color": "#000"}},
          "fontSize": 14,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "labelDisplayUnits": "0",
          // "labelPrecision": "Auto",
          // "showAxisTitle": true,
          "axisStyle": "showTitleOnly",
          "color": { "solid": { "color": "#000"}},
          // "titleText": "asdf",
          "titleFontSize": 12,
          "titleFontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "gridlineShow": true,
          "gridlineColor": { "solid": { "color": "#e4e4e4"}},
          "gridlineThickness": 2,
          "gridlineStyle": "solid"
        }],
        // "dataPoint": [{
        //   "defaultColor": { "solid": { "color": "#448822"}}
        // }],
        "labels": [{
          // "show": true,
          "color": { "solid": { "color": "#000"}},
          // "labelDisplayUnits": "0",
          "labelPrecision": 1,
          "labelPosition": "OutsideEnd",
          "fontSize": 11,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "enableBackground": true,
          "backgroundColor": { "solid": { "color": "#fff"}},
          // "backgroundTransparency": 30
        }],
        "plotArea": [{
          "transparency": 20
        }]
      }
    },
    "slicer": {
      "*": {
        "general": [{
          "outlineColor": { "solid": { "color": "#444444"}},
          "outlineWeight": 1,
          "orientation": "vertical",
          "keepLayerOrder": true
        }],
        "data": [{
          "mode": "Basic",
          "relativeRange": "",
          "relativePeriod": ""
        }],
        "selection": [{
          "selectAllCheckboxEnabled": false,
          "singleSelect": true
        }],
        "header": [{
          "show": true,
          "fontColor": { "solid": { "color": "#000000"}},
          "background": { "solid": { "color": "#fff"}},
          "outline": "None",
          "textSize": 10,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif"
        }],
        "items": [{
          "fontColor": { "solid": { "color": "#000000"}},
          "background": { "solid": { "color": "#fff"}},
          "outline": "None",
          "textSize": 11,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif"
        }],
        "slider": [{
          "color": {"solid": { "color": "#FF373A"} }
        }],
        "background" : [{
          "transparency": 0
        }],
        "visualHeader": [{
          "show": false,
          "background": { "solid": { "color": "#444444"}},
          "border": { "solid": { "color": "#444444"}},
          "transparency": "20D",
          "foreground": { "solid": { "color": "#444444"}},
          "showVisualInformationButton": false,
          "showVisualWarningButton": false,
          "showVisualErrorButton": false,
          "showDrillRoleSelector": false,
          "showDrillUpButton": false,
          "showDrillToggleButton": false,
          "showDrillDownLevelButton": false,
          "showDrillDownExpandButton": false,
          "showPinButton": false,
          "showFocusModeButton": false,
          "showFilterRestatementButton": false,
          "showSeeDataLayoutToggleButton": false,
          "showOptionsMenu": false,
          "showTooltipButton": false
        }]
      }
    },
    "tableEx": {
      "*": {
        "general": [{
          "responsive": true,
          "keepLayerOrder": true
        }],
        "background": [{
          "show": true,
          "color": { "solid": { "color": "#FFFFFF"}},
          "transparency": 0
        }],
        "border": [{
          // "color": { "solid": { "color": "#808080"}},
          "show": false,
          "radius": 0
        }],
        "title": [{
          "show": true,
          // "fontColor": { "solid": { "color": "#000000" } },
          // "background": { "solid": { "color": "#FFFFFF" } },
          "alignment": "center"
        }],
        "grid": [{
          "gridVertical": true,
          // "gridVerticalColor": { "solid": { "color": "#FFB8DD"}},
          "gridVerticalWeight": 2,
          "gridHorizontal": true,
          // "gridHorizontalColor": { "solid": { "color": "#FFB8DD"}},
          "gridHorizontalWeight": 2,
          "rowPadding": 2,
          "outlineColor": { "solid": { "color": "#eee"}},
          "outlineWeight": 2,
          "textSize": 10,
          "imageHeight": 100
        }],
        "columnHeaders": [{
          "fontColor": { "solid": { "color": "#000"}},
          "backColor": { "solid": { "color": "#fff"}},
          "outline": "Frame",
          "autoSizeColumnWidth": true,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          "fontSize": 10,
          "alignment": "Left",
          "wordWrap": true
        }],
        "values": [{
          // "fontColorPrimary": { "solid": { "color": "#00B8DD"}},
          // "backColorPrimary": { "solid": { "color": "#00AA22"}},
          // "fontColorSecondary": { "solid": { "color": "#FFB8DD"}},
          // "backColorSecondary": { "solid": { "color": "#FFAA22"}},
          "outline": "Frame",
          "urlIcon": true,
          "wordWrap": true,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          "fontSize": 10
        }],
        "total": [{
          "totals": true,
          // "fontColor": { "solid": { "color": "#00B8DD"}},
          // "backColor": { "solid": { "color": "#00AA22"}},
          "outline": "Frame",
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          "fontSize": 12
        }],
        "stylePreset": [{
          "name": "BoldHeader"
        }]
      }
    },
    "multiRowCard": {
      "*": {
        "general": [{
          "responsive": true,
          "keepLayerOrder": true
        }],
        "background": [{
          "show": true,
          "color": { "solid": { "color": "#fff"}},
          "transparency": 0
        }],
        "border": [{
          "color": { "solid": { "color": "#808080"}},
          "show": false,
          "radius": 0
        }],
        "title": [{
          "show": true,
          "fontColor": { "solid": { "color": "#000" } },
          "background": { "solid": { "color": "#fff" } },
          "alignment": "center"
        }],
        "dataLabels": [{
          "color": { "solid": { "color": "#000"}},
          "fontSize": 12,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif"
        }],
        "categoryLabels": [{
          "show": true,
          "color": { "solid": { "color": "#000"}},
          "fontSize": 8,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif"
        }],
        "cardTitle": [{
          "color": { "solid": { "color": "#000"}},
          "fontSize": 8,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif"
        }],
        "card": [{
          "outline": "TopOnly",
          "outlineColor": { "solid": { "color": "#fff"}},
          "outlineWeight": 2,
          "barShow": false,
          "barColor": { "solid": { "color": "#01B8AA"}},
          "barWeight": 2,
          "cardPadding": 1,
          "cardBackground": { "solid": { "color": "#fff"}}
        }]
      }
    },
    "scatterChart": {
      "*": {
        "general": [{
          // "dataVolume": 10000,
          "responsive": true,
          "keepLayerOrder": true
        }],
        "legend": [{
          // "show": true,
          // "position": "Top",
          // "showTitle": true,
          // "titleText": "",
          "labelColor": { "solid": { "color": "#000"}},
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          "fontSize": 10
        }],
        "categoryAxis": [{
          // "show": true,
          // "axisScale": "Linear",
          // "start": 0,
          // "end": 10,
          "labelColor": { "solid": { "color": "#000"}},
          "fontSize": 14,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "labelDisplayUnits": "0",
          // "labelPrecision": "Auto",
          // "showAxisTitle": true,
          // "axisStyle": "showTitleOnly",
          "color": { "solid": { "color": "000"}},
          // "titleText": "asdf",
          "titleFontSize": 12,
          "titleFontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "gridlineShow": true,
          "gridlineColor": { "solid": { "color": "#e4e4e4"}},
          "gridlineThickness": 2,
          // "gridlineStyle": "dashed"
        }],
        "valueAxis": [{
          // "show": true,
          // "position": "Right",
          // "axisScale": "Linear",
          // "start": 0,
          // "end": 10,
          "labelColor": { "solid": { "color": "#000"}},
          "fontSize": 14,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "labelDisplayUnits": "0",
          // "labelPrecision": "Auto",
          // "showAxisTitle": true,
          // "axisStyle": "showTitleOnly",
          "color": { "solid": { "color": "01B8AA"}},
          // "titleText": "asdf",
          "titleFontSize": 12,
          "titleFontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "gridlineShow": true,
          "gridlineColor": { "solid": { "color": "#e4e4e4"}},
          "gridlineThickness": 2,
          "gridlineStyle": "solid"
        }],
        // "dataPoint": [{
        //   "defaultColor": { "solid": { "color": "#448822"}}
        // }],
        // "shapes": [{
        //   "markerSize": 3,
        //   "markerStyle": "triangle",
        //   "customizeSeries": true
        // }],
        // "plotArea": [{
        //   "transparency": 20
        // }],
        "categoryLabels": [{
          // "show": true,
          "color": { "solid": { "color": "#000"}},
          "fontSize": 11,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "enableBackground": true,
          "backgroundColor": { "solid": { "color": "#fff"}},
          // "backgroundTransparency": 30
        }],
        // "fillPoint": [{
        //   "show": false
        // }],
        // "colorByCategory": [{
        //   "show": false
        // }]
      }
    },
    "pieChart": {
      "*": {
        "general": [{
          "responsive": true,
          "keepLayerOrder": true
        }],
        "background": [{
          "show": true,
          "color": { "solid": { "color": "#FFFFFF"}},
          "transparency": 0
        }],
        "border": [{
          "color": { "solid": { "color": "#808080"}},
          "show": false,
          "radius": 0
        }],
        "title": [{
          // "show": true,
          "fontColor": { "solid": { "color": "#000000" } },
          "background": { "solid": { "color": "#FFFFFF" } },
          "alignment": "left"
        }],
        "legend": [{
          // "show": true,
          // "position": "Top",
          // "showTitle": true,
          // "titleText": "",
          "legendColor": { "solid": { "color": "#000"}},
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          "fontSize": 8
        }],
        "labels": [{
          // "show": true,
          // "labelStyle": "Data",
          "color": { "solid": { "color": "#000"}},
          // "labelDisplayUnits": "0",
          // "labelPrecision": 0,
          "fontSize": 10,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "position": "inside"
        }]
      }
    },
    "lineStackedColumnComboChart": {
      "*": {
        "general": [{
          "responsive": true,
          "keepLayerOrder": true
        }],
        "legend": [{
          // "show": true,
          // "position": "Top",
          // "showTitle": true,
          // "titleText": "",
          "labelColor": { "solid": { "color": "#000"}},
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          "fontSize": 10
        }],
        "categoryAxis": [{
          // "show": true,
          // "position": "Right",
          "labelColor": { "solid": { "color": "#000"}},
          "fontSize": 14,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          "preferredCategoryWidth": 30,
          "maxMarginFactor": 28,
          "innerPadding": 22,
          "concatenateLabels": true,
          // "showAxisTitle": true,
          // "axisStyle": "showTitleOnly",
          "titleColor": { "solid": { "color": "#000"}},
          // "titleText": "asdf",
          "titleFontSize": 12,
          "titleFontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif"
        }],
        "valueAxis": [{
          // "show": true,
          // "position": "Right",
          // "axisScale": "Linear",
          // "start": 0,
          // "end": 10,
          "labelColor": { "solid": { "color": "#000"}},
          "fontSize": 14,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "labelDisplayUnits": "0",
          "labelPrecision": "Auto",
          // "showAxisTitle": true,
          // "axisStyle": "showTitleOnly",
          "color": { "solid": { "color": "#000"}},
          // "titleText": "asdf",
          "titleFontSize": 12,
          "titleFontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "gridlineShow": true,
          "gridlineColor": { "solid": { "color": "#e4e4e4"}},
          "gridlineThickness": 1,
          "gridlineStyle": "solid",
          // "secShow": true,
          // "alignZeros": true,
          // "secAxisLabel": "asdfsadf",
          // "secPosition": "Left",
          // "secAxisScale": "Log",
          // "secStart": 0,
          // "secEnd": 10,
          "secLabelColor": { "solid": { "color": "#000000"}},
          "secFontSize": 12,
          "secFontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "secLabelDisplayUnits": "0",
          "secLabelPrecision":  2,
          // "secShowAxisTitle": true,
          // "secAxisStyle": "showTitleOnly",
          "secTitleColor": { "solid": { "color": "#000"}},
          // "secTitleText": "aaaa",
          "secTitleFontSize": 10,
          "secTitleFontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif"
        }],
        // "dataPoint": [{
        //   "defaultColor": { "solid": { "color": "#448822"}}
        // }],
        "labels": [{
          // "show": true,
          "color": { "solid": { "color": "#000"}},
          // "labelDisplayUnits": "0",
          // "labelPrecision": 1,
          // "labelOrientation": "vertical",
          // "labelPosition": "OutsideEnd",
          "fontSize": 11,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          "enableBackground": true,
          "backgroundColor": { "solid": { "color": "#fff"}},
          // "backgroundTransparency": 30
        }],
        "lineStyles": [{
          // "shadeArea": true,
          // "strokeWidth": 2,
          // "strokeLineJoin": "bevel",
          // "showMarker": true,
          // "markerShape": "triangle",
          // "markerSize": 2,
          "markerColor": { "solid": { "color": "#448822"}},
          // "lineStyle" : "dashed",
          // "stepped" : true
        }],
        // "plotArea": [{
        //   "transparency": 20
        // }]
      }
    },
    "barChart": {
      "*": {
        "general": [{
          "responsive": true,
          "keepLayerOrder": true
        }],
        "legend": [{
          // "show": true,
          // "position": "Top",
          // "showTitle": true,
          // "titleText": "",
          "labelColor": { "solid": { "color": "#000"}},
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          "fontSize": 10
        }],
        "categoryAxis": [{
          // "show": true,
          // "position": "Right",
          "labelColor": { "solid": { "color": "#000"}},
          "fontSize": 14,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          "preferredCategoryWidth": 30,
          "maxMarginFactor": 28,
          "innerPadding": 22,
          // "showAxisTitle": true,
          // "axisStyle": "showTitleOnly",
          "titleColor": { "solid": { "color": "#000"}},
          // "titleText": "asdf",
          "titleFontSize": 12,
          "titleFontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif"
        }],
        "valueAxis": [{
          // "show": true,
          // "axisScale": "Linear",
          // "start": 0,
          // "end": 10,
          "labelColor": { "solid": { "color": "#000"}},
          "fontSize": 14,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "labelDisplayUnits": "0",
          "labelPrecision": "Auto",
          // "showAxisTitle": true,
          // "axisStyle": "showTitleOnly",
          "titleColor": { "solid": { "color": "#000"}},
          // "titleText": "asdf",
          "titleFontSize": 12,
          "titleFontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "gridlineShow": true,
          "gridlineColor": { "solid": { "color": "#e4e4e4"}},
          "gridlineThickness": 1,
          "gridlineStyle": "solid"
        }],
        // "dataPoint": [{
        //   "defaultColor": { "solid": { "color": "#448822"}}
        // }],
        "labels": [{
          // "show": true,
          "color": { "solid": { "color": "#000"}},
          // "labelDisplayUnits": "0",
          "fontSize": 11,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "enableBackground": true,
          "backgroundColor": { "solid": { "color": "#fff"}},
          // "backgroundTransparency": 30
        }],
        // "plotArea": [{
        //   "transparency": 20
        // }]
      }
    },
    "areaChart": {
      "*": {
        // "general": [{
        //   "responsive": true,
        //   "keepLayerOrder": true
        // }],
        "legend": [{
          // "show": true,
          // "position": "Top",
          // "showTitle": true,
          // "titleText": "",
          "labelColor": { "solid": { "color": "#000"}},
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          "fontSize": 10,
          // "legendMarkerRendering": "markerOnly",
          // "matchLineColor": false
        }],
        "categoryAxis": [{
          // "show": true,
          // "axisType": "categorical",
          "labelColor": { "solid": { "color": "#000"}},
          // "fontSize": 14,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "preferredCategoryWidth": 30,
          // "maxMarginFactor": 28,
          // "concatenateLabels": true,
          // "showAxisTitle": true,
          // "axisStyle": "showTitleOnly",
          "titleColor": { "solid": { "color": "#000"}},
          // "titleText": "asdf",
          // "titleFontSize": 12,
          "titleFontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif"
        }],
        "valueAxis": [{
          // "show": true,
          // "position": "Right",
          // "axisScale": "Linear",
          // "start": 0,
          // "end": 10,
          "labelColor": { "solid": { "color": "#000"}},
          // "fontSize": 14,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "labelDisplayUnits": "0",
          // "labelPrecision": "Auto",
          // "showAxisTitle": true,
          // "axisStyle": "showTitleOnly",
          "color": { "solid": { "color": "000"}},
          // "titleText": "asdf",
          // "titleFontSize": 12,
          "titleFontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "gridlineShow": true,
          "gridlineColor": { "solid": { "color": "#e4e4e4"}},
          // "gridlineThickness": 2,
          "gridlineStyle": "solid"
        }],
        // "dataPoint": [{
        //   "defaultColor": { "solid": { "color": "#448822"}}
        // }],
        "labels": [{
          // "show": true,
          "color": { "solid": { "color": "#000"}},
          // "labelDisplayUnits": "0",
          // "labelPrecision": 1,
          // "fontSize": 11,
          "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "enableBackground": true,
          // "backgroundColor": { "solid": { "color": "#2c2c2c"}},
          // "backgroundTransparency": 30
        }],
        "lineStyles": [{
          // "strokeWidth": 4,
          // "strokeLineJoin": "bevel",
          // "lineStyle": "dashed",
          // "showMarker": true,
          // "markerShape": "triangle",
          // "markerSize": 10,
          "markerColor": { "solid": { "color": "#000"}}
        }],
        // "plotArea": [{
        //   "transparency": 20
        // }]
      }
    },
    "page": {
      "*": {
        "background": [
          {
            "color": {
              "solid": {
                "color": "#eee"
              }
            },
            "transparency": 100
          }
        ],
        "outspace": [
          {
            "color": {
              "solid": {
                "color": "#eee"
              }
            }
          }
        ],
        "wallpaper": [
          {
            "color": {
              "solid": {
                "color": "#eee"
              }
            }
          }
        ],
        "displayArea": [
          {
            "verticalAlignment": "Top",
            "color": {
              "solid": {
                "color": "#eee"
              }
            }
          }
        ]
      }
    },
    "*": {
      "*": {
        "outspacePane": [
          {
            "backgroundColor": {
              "solid": {
                "color": "#dadada"
              }
            },
            "foregroundColor": {
              "solid": {
                "color": "#dadada"
              }
            },
            "checkboxAndApplyColor": {
              "solid": {
                "color": "#dadada"
              }
            }
          }
        ],
        "legend": [{
          // "show": true,
          // "position": "Top",
          // "showTitle": true,
          // "titleText": "",
          "labelColor": { "solid": { "color": "#000"}},
          // "fontFamily": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif",
          // "fontSize": 10,
          // "legendMarkerRendering": "markerOnly",
          // "matchLineColor": false,
          // "defaultToCircle": false
        }],
        "filterCard": [
          {
            "$id": "Applied",
            "backgroundColor": {
              "solid": {
                "color": "#FCFDFE"
              }
            },
            "foregroundColor": {
              "solid": {
                "color": "#323447"
              }
            },
            "transparency": 0
          },
          {
            "$id": "Available",
            "backgroundColor": {
              "solid": {
                "color": "#F1F2F7"
              }
            },
            "foregroundColor": {
              "solid": {
                "color": "#323447"
              }
            },
            "transparency": 0
          }
        ],
        "background": [
          {
            "color": {
              "solid": {
                "color": "#fff"
              }
            }
          }
        ],
        // "border": [
        //   {
        //     "color": {
        //       "solid": {
        //         "color": "#2C2C2C"
        //       }
        //     },
        //     "show": true
        //   }
        // ],
        "visualHeader":[
          {
            // "show":false,
            "foreground":{
              "solid":{
                "color":"#000"
              }
            },
            "border":{
              "solid":{
                "color":"#000"
              }
            },
            "background":{
              "solid":{
                "color":"#fff"
              }
            },
            "transparency":0,
            "showVisualInformationButton":false,
            "showVisualWarningButton":false,
            "showVisualErrorButton":false,
            "showDrillRoleSelector":false,
            "showDrillUpButton":false,
            "showDrillToggleButton":false,
            "showDrillDownLevelButton":false,
            "showDrillDownExpandButton":false,
            "showPinButton":false,
            "showFocusModeButton":false,
            "showFilterRestatementButton":false,
            "showSeeDataLayoutToggleButton":false,
            "showOptionsMenu":true,
            "showTooltipButton":false
          }
        ],
        "visualHeaderTooltip":[
          {
            // "text":"Header tooltip text",
            "titleFontColor":{
              "solid":{
                "color":"#000"
              }
            },
            "transparency":0,
            "background":{
              "solid":{
                "color":"#fff"
              }
            }
          }
        ],
        "visualTooltip": [
          {
            "titleFontColor": {
              "solid": {
                "color": "#262735"
              }
            },
            "valueFontColor": {
              "solid": {
                "color": "#262735"
              }
            },

            "background": {
              "solid": {
                "color": "#F8FAF7"
              }
            }
          }
        ],
        "dropShadow": [
          {
            "position": "Outer",
            "show": false,
            "color": {
              "solid": {
                "color": "#000000"
              }
            }
          }
        ]
      }
    }
  },
  "dataColors": [
    "#60CDFF",
    "#EEBD43",
    "#EF8E45",
    "#E35556",
    "#AD6FBC",
    "#6AB649",
  ],
  "textClasses": {
    "label": {
      "fontFace": "wf_standard-font, helvetica, arial, sans-serif",
      "color": "#000",
      "fontSize": 10
    },
    "callout": {
      "color": "#000",
      "fontSize": 9,
      "fontFace": "'Segoe UI Bold', wf_segoe-ui_bold, helvetica, arial, sans-serif"
    },
    "title": {
      "color": "#000"
    },
    "header": {
      "color": "#000",
      "fontSize": 9,
      "fontFace": "'Segoe UI Semibold', wf_segoe-ui_semibold, helvetica, arial, sans-serif"
    }
  },
  "bad": "#60CDFF",
  "neutral": "#CCCCCC",
  "good": "#005FB8",
  "minimum": "#60CDFF",
  "center": "#CCCCCC",
  "maximum": "#005FB8",
  // "foreground": "#dadada",
  // "foregroundNeutralSecondary": "#dadada",
  // "backgroundLight": "#dadada",
  // "background": "#dadada",
  // "foregroundNeutralTertiary": "#dadada",
  // "backgroundNeutral": "#dadada"
}
