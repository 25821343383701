import * as React from 'react';
import { RouteProps } from 'react-router';
import GuardedRoute from 'components/routes/guarded';
import Page403Error from 'pages/access-denied';
import { defineAbilityFor, TAppAbilityCanParams } from 'authorizations';
import useRequest from 'api/use-request';
import api from 'api';
import { useStore } from 'store';
import MainLayout from 'layouts/main';
import { Redirect, useLocation } from 'react-router-dom';
import { DatasetObject } from 'api/endpoints/datasets';
import { LocationState } from 'routes';
import { observer } from 'mobx-react';

function firstDashboardForUser(datasets?: DatasetObject[]): string {
  let url: string;

  if (datasets && datasets.length > 0 && datasets[0].dashboards && datasets[0].dashboards.length > 0) {
    url = '/dashboard/' + datasets[0].dashboards[0].id + '/' + datasets[0].id
  } else if (datasets && datasets.length == 0) {
    url = '/nodashboards'
  } else {
    url = '*'
  }

  return url;
}

export interface AuthRouteProps extends RouteProps {
  location?: RouteLocation
}

interface RouteLocation extends Location {
  state: LocationState,
}

/**
 * Auth route redirects authenticated users to their main app screens
 * @param param0
 * @returns
 */
export const AuthRoute = observer((props: AuthRouteProps) => {
  const store = useStore();
  const user = useRequest(api.users.getCurrentUser(), store.isLoggedIn);
  const datasets = useRequest(api.datasets.myDatasets({ client_id: user.data?.clients && user.data?.clients.length > 0 ? user.data?.clients[0].id : '*' }), store.isLoggedIn);
  //console.log(datasets);
  
  return (
    <GuardedRoute
      {...props}
      guard={(params, children) => {
        if (store.isLoggedIn && store.userRole) {
          let destination = '/404';
          
          window.loader(false);
          if (props.location?.state && props.location.state.from) {
            destination = props.location.state.from.pathname;
          } else if (store.isSuperAdmin) {
            destination = '/clients';
          } else if (store.isGroupAdmin || store.isSiteAdmin || store.isUser) {
            destination = firstDashboardForUser(datasets.data?.data);
            if (destination === '*') {
              window.loader(true);
              return (
                <div />
              );
            }
          }
          return (
            <Redirect to={destination} />
          );
        } else {
          return children();
        }
      }}
    />
  );
});

export default AuthRoute;
