import * as React from 'react';

import { ReactComponent as IconDropdown } from 'assets/svg/dropdown.svg';
import { TabsNav, TabsPanel } from 'components/layout/tabs';
import { Link, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import ClientGeneralTab from 'components/tabs/client-general';
import DashboardsTab from 'components/tabs/dashboards';
import UsersTab from 'components/tabs/users';
import DatasetsTab from 'components/tabs/datasets';
import useRequest from 'api/use-request';
import api from 'api';
import { useContext, useEffect } from 'react';
import { PageContext } from 'components/layout/page-context';
import UsergroupsTab from 'components/tabs/usergroups';
import { userFulfillsRole, UserRoleTypes } from 'api/endpoints/users';
import DevicesTab from 'components/tabs/devices';

export interface IClientPageProps {
  createNew?: boolean;
}

export interface IClientUrlParams {
  id: string;
}


export default function ClientPage({ createNew = false }: IClientPageProps) {
  const page = useContext(PageContext);
  let user = useRequest(api.users.getCurrentUser());

  useEffect(() => {
    if (user.data?.role === UserRoleTypes.SuperAdmin) {
      page.setTitle('Edit Client');
      page.setBackLink('/clients');
    } else {
      page.setTitle('Account');
      page.setBackLink(undefined);
    }
    page.setShowsClientSwitcher(false);
  });

  let match = useRouteMatch();

  let params = useParams() as {
    clientId: string | undefined,
  };
  let clientId = params.clientId ?? '';

  let client = useRequest(api.clients.getClient({ id: clientId }));

  // const tabs = [
  //   { label: 'General', path: `${match.url}/general`, superadmin_only: false },
  //   { label: 'Dashboards', path: `${match.url}/dashboards`, superadmin_only: true },
  //   { label: 'Sites', path: `${match.url}/sites`, superadmin_only: false },
  //   { label: 'User Groups', path: `${match.url}/usergroups`, superadmin_only: false },
  //   { label: 'Users', path: `${match.url}/users`, superadmin_only: false },
  // ];

  const tabs = [
    { label: 'General', path: `${match.url}/general`, requires: UserRoleTypes.GroupAdmin },
    { label: 'Dashboards', path: `${match.url}/dashboards`, requires: UserRoleTypes.SuperAdmin },
    { label: 'Sites', path: `${match.url}/sites`, requires: UserRoleTypes.GroupAdmin },
    { label: 'User Groups', path: `${match.url}/usergroups`, requires: UserRoleTypes.GroupAdmin },
    { label: 'Users', path: `${match.url}/users`, requires: UserRoleTypes.SiteAdmin },
    { label: 'Devices', path: `${match.url}/devices`, requires: UserRoleTypes.SuperAdmin },
  ];

  let allowedTabs = tabs.filter((tab, index) => (user.data && userFulfillsRole(user.data, tab.requires)))
                        .map((tab, index) => { return {label: tab.label, path: tab.path}});

  return (
    <section className="c-block c-block--spacing-b-small">
      <div className="o-container-fluid o-container--full">
        <div className="c-page-title">
          <h2 className="c-title--large">{client.data?.name}</h2>
          {(client.data?.first_dashboard_id && client.data?.first_dataset_id) &&
            <Link to={`/dashboard/${client.data.first_dashboard_id}/${client.data.first_dataset_id}`} className="c-link-cta"><span>View dashboards</span><IconDropdown className="o-svg-icon o-svg-right o-svg-small" /></Link>
          }
        </div>

        <TabsNav tabs={allowedTabs}>
          <Switch>
            <Route path={tabs[0].path}>
              <TabsPanel>
                <ClientGeneralTab clientId={clientId} isNew={createNew} />
              </TabsPanel>
            </Route>
            {user.data && user.data.role === 'super-admin' &&
              <Route path={tabs[1].path}>
                <TabsPanel>
                  <DashboardsTab clientId={clientId} />
                </TabsPanel>
              </Route>
            }
            <Route path={tabs[2].path}>
              <TabsPanel>
                <DatasetsTab clientId={clientId} />
              </TabsPanel>
            </Route>
            <Route path={tabs[3].path}>
              <TabsPanel>
                <UsergroupsTab clientId={clientId} />
              </TabsPanel>
            </Route>
            <Route path={tabs[4].path}>
              <TabsPanel>
                <UsersTab clientId={clientId} />
              </TabsPanel>
            </Route>
            <Route path={tabs[5].path}>
              <TabsPanel>
                <DevicesTab clientId={clientId} />
              </TabsPanel>
            </Route>
          </Switch>
        </TabsNav>
      </div>
    </section>
  );
}
