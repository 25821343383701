import * as React from 'react';

import classNames from 'classnames';
import SelectField from './select-field';
import { FormFieldSizing, FormFieldVariant } from './common';
import timezones from 'utils/timezones';
import { Field as FormikField, FieldConfig, useFormikContext } from 'formik';
import ErrorMessage from './error-message';
import { useEffect, useState } from 'react';

export type TimezonePickerProps<T> = JSX.IntrinsicElements['select'] &
  Omit<FieldConfig<T>, 'component' | 'as' | 'render' | 'children' | 'type'> & {
    name: string;
  } & {
    validateOnInit?: boolean;
    label?: React.ReactNode;
    help?: string;
    variant?: FormFieldVariant;
    sizing?: FormFieldSizing;
    isLoading?: boolean;
    valueTransform?: (value: any) => any;
  };

export const TimezonePicker = <T extends any>({
  name,
  validateOnInit,
  label,
  disabled,
  required = false,
  placeholder,
  multiple,
  help,
  variant = FormFieldVariant.fill,
  sizing,
  valueTransform,
  isLoading = false,
  ...formikFieldProps
}: TimezonePickerProps<T>): React.ReactElement => {

  const { setFieldTouched, isSubmitting, getFieldMeta, setFieldValue } = useFormikContext<any>();

  const fieldMeta = getFieldMeta(name);
  const value = fieldMeta.value;
  const errorMsg = fieldMeta.error;

  const hasError = typeof errorMsg !== 'undefined' && fieldMeta.touched;
  const isPlaceholderSelected = !multiple && Boolean(placeholder) && typeof value === 'undefined';

  const [selectedContinent, setSelectedContinent] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');

  function timezonesDictionary(): Map<string, string[]> {
    let continents = new Map<string, string[]>();

    timezones.forEach((timezone) => {
      var option = timezone.split('/');
      var continent = option[0];
      option.shift();
      var region = option.join('/');

      if (!continents.get(continent)) {
        continents.set(continent, []);
      }

      let current = continents.get(continent);
      if (current) {
        current.push(region);
        continents.set(continent, current);
      }
    });

    return continents;
  }

  const timezonesDict = timezonesDictionary();


  useEffect(()=> {
    if (selectedContinent && selectedContinent !== '' && selectedRegion && selectedRegion !== '') {
      setFieldValue(name, selectedContinent + '/' + selectedRegion);
    }
  }, [selectedContinent, selectedRegion]);

  useEffect(()=>{
    if (value && (value as string) !== '') {
      var option = (value as string).split('/');
      setSelectedContinent(option[0]);
      option.shift();
      setSelectedRegion(option.join('/'));
    }
  }, []);

  return (
    <>
      <div className={classNames(['c-form-element', 'c-form-element--select', { ['c-form-element' + variant]: variant, 'c-form-element--error': hasError, ['c-form-element' + sizing]: sizing }])}>
        {label && (
          <label htmlFor={name} className="c-form-label">
            {label}
            {required && ' *'}
          </label>
        )}
        <div className="o-row o-row--small-gutters">
          <div className="o-col-6@sm">
            <div className="c-form-element__field">
              { isLoading && (
                <div className="c-form-element__loader"></div>
              )}
              <FormikField
                {...formikFieldProps}
                multiple={false}
                name={name + '_continent'}
                as="select"
                disabled={isSubmitting ? true : disabled}
                defaultValue={isPlaceholderSelected ? '' : undefined}
                onChange={(event: { target: { value: string; }; }) => {
                  setSelectedContinent(event.target.value);
                }}
                value={selectedContinent}
              >
                {!multiple && Boolean(placeholder) && (
                  <option value={''} disabled key="__placeholder">
                    Select a Continent
                  </option>
                )}
                
                {Array.from(timezonesDict.keys()).map((continent) => {
                  return (<option key={continent} value={continent}>{continent}</option>);
                })}
              </FormikField>
            </div>
          </div>
          <div className="o-col-6@sm">
            <div className="c-form-element__field">
              <FormikField
                {...formikFieldProps}
                multiple={false}
                name={name + '_region'}
                as="select"
                disabled={isSubmitting || !selectedContinent ? true : disabled}
                defaultValue={isPlaceholderSelected ? '' : undefined}
                onChange={(event: { target: { value: string; }; }) => {
                  setSelectedRegion(event.target.value);
                }}
                value={selectedRegion}
              >
                {!multiple && Boolean(placeholder) && (
                  <option value={''} disabled key="__placeholder">
                    Select a Region
                  </option>
                )}
                
                {selectedContinent && (
                  timezonesDict.get(selectedContinent)?.map((region: string) => {
                    return (<option key={region} value={region}>{region}</option>);
                  })
                )}
              </FormikField>
            </div>
          </div>        
        </div>
        <ErrorMessage name={name} />
        {help && (
          <p className="c-note">{help}</p>
        )}
      </div>
    </>
  );
}
