import * as React from 'react';
import { useContext, useEffect, useState, createRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useRequest from 'api/use-request';
import api from 'api';
import configuration from '../../../../src/client/src/configuration';
import { PageContext } from 'components/layout/page-context';
import { ReactComponent as IconClose } from 'assets/svg/close.svg';
import classNames from 'classnames';
import { PowerBIEmbed } from "powerbi-client-react";
import { models, Report, Embed } from 'powerbi-client';
import axios from "axios";
import {powerbiLightTheme} from './powerbi-themes/light-theme'
import {powerbiDarkTheme} from './powerbi-themes/dark-theme'

const PowerBiDashboardPage = function (props: any) {
  const page = useContext(PageContext);
  let currentUser = useRequest(api.users.getCurrentUser());
  let [currentDarkMode, setCurrentDarkMode] = useState(false);
  let tokenExpiration:any = null;
  let containerRef = createRef<HTMLDivElement>();

  let { id, datasetId} = useParams() as {
    id: string,
    datasetId: string
  };

  let dashboardEntry = useRequest(api.dashboards.viewDashboard({
    id: id,
    datasetId: datasetId,
  }));
  
    // @ts-ignore
  let slicers = [];
  let filters = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
        table: "databaseInfo",
        column: "databaseSelected"
    },
    filterType: models.FilterType.Advanced,
    logicalOperator: "Or",
    conditions: [
      {
        operator: "Is",
        value: ""
      },
    ],
  };
  
  const getParamFilters = async () => {
  const params = new URLSearchParams(window.location.search);

  const paramsObj = Array.from(params.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: params.get(val) }),
    {}
  );
  return paramsObj;
  }

  const buildfilters = (filters:any) => {
  //Example of filter saved in localstorage

  // let filters = [{
  //   table: 'floor',
  //   column: 'description',
  //   operator: 'In',
  //   values: ['Floor 2'],
  //   filterType: 'basic'
  // }]

    // @ts-ignore
    filters.forEach(item => {
      let filter;
      if(item.filterType == 'basic'){
        filter = {
          selector: {
            $schema: "http://powerbi.com/product/schema#slicerTargetSelector",
            target: {
              table: item.table,
              column: item.column
            },
          },
          state: {
            filters: [{
              $schema: "http://powerbi.com/product/schema#basic",
              target: {
                table: item.table,
                column: item.column
              },
              operator: item.operator,
              values: item.values,
              filterType: models.FilterType.Basic
            }]
          }
        }
      }
      else if(item.filterType == 'advanced'){
        filter = {
          $schema: "http://powerbi.com/product/schema#advanced",
          target: {
            table: item.table,
            column: item.column
          },
          logicalOperator: item.operator,
          conditions: item.values,
          filterType: models.FilterType.Advanced
        };
      }
    // @ts-ignore
      slicers.push(filter)
    });
  }

  let initialConfigState = {
    type: "report",
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    id: "",
    permissions: 0,
    datasetBinding: {
      datasetId: "notOriginalDatasetId",
    },
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false
        },
        pageNavigation: {
          visible: false
        }
      },
      layoutType: window.screen.width <= 480 ? models.LayoutType.MobilePortrait : models.LayoutType.Custom,
      customLayout:{
        displayOption: page.isKioskMode ? models.DisplayOption.FitToPage : models.DisplayOption.FitToWidth
      },

    },
    theme: { themeJson: page.usesDarkMode ? powerbiDarkTheme : powerbiLightTheme},
    // @ts-ignore
    slicers: slicers || null,
    // @ts-ignore
    filters: filters ? [filters] : null,
  }

  useEffect(() => {
      setCurrentDarkMode(page.usesDarkMode);
  }, [page.usesDarkMode, currentDarkMode]);

  useEffect(() => {
    setHideElement(false);
      let update = api.dashboards.viewDashboard({ id: id, datasetId: datasetId,}).fetch();
      update.then(async (response) => {
        dashboardEntry.data = response;
        clearTimeout(window.powerBiRefresh);
        slicers = [];
        let paramsObj = await getParamFilters()
        for (const property in paramsObj) {
          if(property == 'floor'){
            buildfilters([{
              table: 'floor',
              column: 'description',
              operator: 'In',
              // @ts-ignore
              values: [paramsObj[property]],
              filterType: 'basic'
            }]);
          }
          else if(property == 'custowner'){
            buildfilters([{
              table: 'custowner',
              column: 'custownername',
              operator: 'In',
              // @ts-ignore
              values: [paramsObj[property]],
              filterType: 'basic'
            }]);
          }
          else if(property == 'rotation'){
            buildfilters([{
              table: 'Rotate',
              column: 'Rotate',
              operator: 'In',
              // @ts-ignore
              values: [`${paramsObj[property]}°`],
              filterType: 'basic'
            }]);
          }
          else if(property == 'tasktype'){
            buildfilters([{
              table: 'TaskType',
              column: 'TaskType',
              operator: 'In',
              // @ts-ignore
              values: [`${paramsObj[property]}`],
              filterType: 'basic'
            }]);
          }
        }
              // @ts-ignore
        initialConfigState = {...initialConfigState, slicers: slicers || null}
              // @ts-ignore
        setReportConfig({...initialConfigState, slicers: slicers || null})

        // var filters = localStorage.getItem('filters');
        // //TODO parse to have array of objects after parse build slicers
        // let fitersParsed = filters ? JSON.parse(filters) : [];
        // if(fitersParsed.length){
        //   buildfilters(fitersParsed);
        // }
        // @ts-ignore
        getReport(false, slicers)
      }).catch((error) => {
        console.log(error);
      });
  }, [id, datasetId, page.usesDarkMode]);

    // Report config useState hook
    let [hideElement, setHideElement] = useState(true);
    // Values for properties like embedUrl, accessToken and settings will be set on click of buttons below
    const [reportConfig, setReportConfig] = useState(initialConfigState);

    // Map of event handlers to be applied to the embedding report
    const eventHandlersMap = new Map([
      [
        "loaded",
        function () {
          console.log("Report has loaded");
          setHideElement(true);
        }
      ],
      [
        "rendered",
        function () {
          console.log("Report has rendered");
          setRefresh();
          setMessage("The report is rendered");
        }
      ],
      [
        "error",
        function (event: any) {
          if (event) {
            console.error(event.detail);
            setHideElement(true);
          }
        }
      ]
    ]);

    const setRefresh = async() =>{
      clearTimeout(window.powerBiRefresh);
        window.powerBiRefresh = setTimeout(() => refreshReport(), 30000);
    }

    const checkTokenAndUpdate = () => {
      // Get the current time
      const currentTime = Date.now();
      // @ts-ignore
      const expiration = Date.parse(tokenExpiration);
  
      // Time until token expiration in milliseconds
      const timeUntilExpiration = expiration - currentTime;
      const timeToUpdate = 10 * 60 * 1000;
  
      // Update the token if it is about to expired
      if (timeUntilExpiration <= timeToUpdate)
      {
          getReport(true);
      }
  }

    const refreshReport = async() =>{
      try {
        // @ts-ignore
        checkTokenAndUpdate();
          await window.reportObject.refresh();
          console.log("Refreshed");
        }
        catch (errors) {
            console.log('Refresh report error', errors);
            setRefresh()
        }
    }

    // Fetch sample report's config (eg. embedUrl and AccessToken) for embedding
    const getReport = async (updateToken = false, slicers = []) => {
      let dataset = await api.datasets.getDataset({ id: datasetId }).fetch();
      // @ts-ignore
      let datasetIdOverride;
      if(dataset?.hostname && dataset.hostname.toLowerCase().includes('kusto')){
        // @ts-ignore
        datasetIdOverride = configuration.REACT_APP_POWERBI_DATASET;
        filters.conditions[0].value = dataset.connection;
      }

      if(!updateToken){
        setReportConfig(initialConfigState)
        window.reportObject = null;
      }
      if(id && datasetId && dashboardEntry.data?.client_id){
        return await axios
          .get(
            `${configuration.REACT_APP_POWERBI_API}/analytics/report/${dashboardEntry.data?.source}`,
            {
              headers: {
                "locus-domain": configuration.REACT_APP_POWERBI_DOMAIN,
                "locus-warehouse": configuration.REACT_APP_POWERBI_WAREHOUSE,
                // "locus-client": "testcustomer",
                "locus-user": "{\"username\":\"bob\"}",
                "locus-client-id": dashboardEntry.data?.client_id,
                "locus-dataset-id": datasetIdOverride || datasetId,
                "locus-powerbi": !!datasetIdOverride,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            tokenExpiration = response.data.tokenExpiration;
            if(!updateToken){
              setReportConfig({
                ...reportConfig,
                embedUrl: response.data.embedUrl,
                accessToken: response.data.token,
                id: response.data.reportId,
                datasetBinding: {
                  datasetId: response.data.datasetId,
                },
                settings: {
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false
                    },
                    pageNavigation: {
                      visible: dashboardEntry.data?.type == 3
                    }
                  },
                  layoutType: window.screen.width <= 480 ? models.LayoutType.MobilePortrait : models.LayoutType.Custom,
                  customLayout:{
                    displayOption: page.isKioskMode ? models.DisplayOption.FitToPage : models.DisplayOption.FitToWidth
                  },
  
                },
              // @ts-ignore
                theme: { themeJson: page.usesDarkMode ? powerbiDarkTheme : powerbiLightTheme},
              // @ts-ignore
                slicers: slicers.length ? slicers : null,
              // @ts-ignore
                filters: datasetIdOverride ? [filters] : null
              });
            }
            else{
              console.log('reset token')
              window.reportObject.setAccessToken(response.data.token);
            }
          })
          .catch((err) => {
            console.log('getReport err', err);
          });
      }
      };

    const [displayMessage, setMessage] = useState(
      `The report is bootstrapped. Click the Embed Report button to set the access token`
    );

  useEffect(() => {
    // console.log('page runner', dashboardEntry.data)
    if (dashboardEntry.data) {
      page.setTitle(currentUser.data?.client_name);
      page.setShowsDashboardSelector(true)
      //removed
      window.screen.width <= 480 ? page.setFooterHidden(true) : page.setFooterHidden(false)
      page.setSelectedClientId(dashboardEntry.data?.client_id);
      page.setShowsClientSwitcher(true);
      if (dashboardEntry.data?.client_logo_url) {
        page.setLogoURL(dashboardEntry.data?.client_logo_url);
      }
      page.setBackLink('')
    }
    return () => {
      page.setLogoURL(undefined);
    };
  }, [dashboardEntry, page])
    return (
      <section className={classNames(['c-block', 'c-body__main', 'c-block--spacing-b-small', (page.usesDarkMode ? 'u-bg-dash-dark' : 'u-bg-dash-light')])}>
                  {!hideElement &&
          <div className="c-loading-dashboard">
            <div className="c-site-logo c-site-logo--basic c-site-logo--sm"></div>
              <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            <p className="c-note">Loading dashboard...</p>
          </div>
          }
        <div className="o-container-fluid o-container--full u-px-0 u-px-spacer-base@sm power-bi-page-container">
          <div id="sisenseApp" ref={containerRef}>
            <PowerBIEmbed
            // @ts-ignore
            embedConfig = {reportConfig}
            eventHandlers = { eventHandlersMap }
            cssClassName = { (window.screen.width <= 480 && hideElement) ? "report-style-class report-style-class-mobile" : "report-style-class"}
            getEmbeddedComponent = {(embedObject: Embed) => {
              window.reportObject = embedObject;
            }}
            />
          </div>
        </div>
      </section>
    );
}

export default PowerBiDashboardPage;

