import * as React from 'react';
import { useEffect, useState } from 'react';
import config from '../../../../src/client/src/configuration';
import Cookies from 'js-cookie';
import { store } from 'store';
import api from 'api';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string'
import { useTimeout, useTimeoutFn } from 'react-use';


const deviceKeyCookieOptions:Cookies.CookieAttributes = { secure: true, sameSite: "None", expires: 365 };
const deviceKeyCookieName = config.DEVICE_KEY_COOKIE_NAME;
const activationCodeCookieOptions:Cookies.CookieAttributes = { secure: true, sameSite: "None", expires: 365 };
const activationCodeCookieName = config.ACTIVATION_CODE_COOKIE_NAME;

const KioskPage: React.FunctionComponent = () => {
  let [activationCode, setActivationCode] = useState(Cookies.get(activationCodeCookieName));
  
  let [isRegisteringDevice, setIsRegisteringDevice] = useState(false);
  let [isCheckingActivation, setIsCheckingActivation] = useState(false);
  
  const [registrationTimerIsReady, cancelRegistrationTimer, resetRegistrationTimer] = useTimeoutFn(registerDevice, 15000);
  cancelRegistrationTimer();

  const [activationTimerIsReady, cancelActivationTimer, resetActivationTimer] = useTimeoutFn(checkActivation, 15000);
  cancelActivationTimer();
  
  let location = useLocation<{from: string}>();
  let history = useHistory();
  let params = queryString.parse(location.search);

  function generateDeviceKey() {
    let key = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let codeLength = 12;
    for (var i = 0; i < codeLength; i++) {
      key += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return key;
  }

  function deviceKey() {
    let current_key = Cookies.get(deviceKeyCookieName);
    if (!current_key) {
      current_key = generateDeviceKey();
      Cookies.set(deviceKeyCookieName, current_key, deviceKeyCookieOptions);
    }
    return current_key;
  }

  function registerDevice() {
    if (isRegisteringDevice) {
      return;
    }

    setIsRegisteringDevice(true);
    api.devices.registerDevice({ 
      device_key: deviceKey() 
    }).fetch()
    .then((device) => {
      setIsRegisteringDevice(false);
      if (device && device.activation_code) {
        Cookies.set(activationCodeCookieName, device.activation_code, activationCodeCookieOptions);
        setActivationCode(device.activation_code);
      } else {
        resetRegistrationTimer();
      }
    })
    .catch((error) => {
      setIsRegisteringDevice(false);
      resetRegistrationTimer();
    });
  }

  function checkActivation() {
    if (!activationCode || isCheckingActivation) {
      return;
    }
    
    setIsCheckingActivation(true);
    api.devices.checkActivation({
      device_key: deviceKey(), 
      activation_code: activationCode,
    }).fetch()
    .catch((error) => {
      setIsCheckingActivation(false);
      resetActivationTimer();
    })
    .then((response) => {
      setIsCheckingActivation(false);
      if (response && response.token) {
        cancelActivationTimer();
        store.loginWithTokens({access_token: response.token, refresh_token: response.token})
        redirectToContent();
      } else {
        resetActivationTimer();
      }
    })
  }

  function redirectToContent() {
    if (location.state.from) {
      history.push(location.state.from as string);
    }
  }

  useEffect(() => {
    if (activationCode) {
      checkActivation();
    }
  }, [activationCode]);

  useEffect(() => {
    if (store.isLoggedIn) {
      redirectToContent();
    }
  }, [store.isLoggedIn]);

  useEffect(() => {
    if (!store.isLoggedIn) {
      if (!activationCode) {
        // add a random delay to prevent multiple dashboard devices to register multiple times
        setTimeout(registerDevice, Math.random() * 100);
      }
    } else {
      redirectToContent();
    }

    return () => {
        cancelActivationTimer();
    };
  }, []);

  return (
    <div>

    <div className="o-row o-row--no-gutters">
      <div className="o-col-12">
        <div className="c-block c-block--full-height c-block--spacing-t c-block--spacing-b">
          <div className="o-container-fluid o-container--xs u-justify-between">
            {/* <div className="">
              <div className="c-site-logo c-site-logo--basic c-site-logo--sm u-mb-spacer-base"></div>
            </div> */}
            <div className="u-my-auto u-pb-spacer-base-large">
              <h2>Device Activation</h2>
              { !activationCode && 
                <>
                  <p>Registering a device</p>
                  {/* <p className="c-note">Registration code: {deviceKey()}.</p> */}
                </>
              }

              { activationCode &&
                <>
                  <p>Activate a device using the following code:</p>
                  <div className="c-pre c-pre--large c-pre--wide u-text-center">{activationCode}</div>
                  <p className="c-note">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  );
};

export default KioskPage;
