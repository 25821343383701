import * as React from 'react';
import Notification from 'components/notification';
import { useStore } from 'store';
import { observer } from 'mobx-react';

export interface IAppErrorsProps {
}

export const AppErrors = observer((props: IAppErrorsProps) => {
  const store = useStore();

  function getNiceErrorName(type: string) {
    let title: string = '';
    switch (type) {
      case 'network':
        title = 'Connectivity problem.'
        break;
    }

    return title;
  }

  return (
    <>
    {store.errors.app.map((error, index) =>
      <Notification key={index} floating={true} onTop={error.type === 'network'} type={error.style || "error"}>
        <p><strong>{getNiceErrorName(error.type)}</strong></p>
        <p>{error.message}</p>
        {error.cta === 'reload' &&
          <span className='c-button c-button--stroke c-button--white c-button--small u-block u-ml-auto' onClick={() => window.location.reload()}>Click to reload</span>
        }
      </Notification>
    )}
    </>
  );
});
