import { PageContext } from 'components/layout/page-context';
import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from 'store';

export interface IAccessDeniedPageProps {
}

export default function AccessDeniedPage(props: IAccessDeniedPageProps) {
  const page = useContext(PageContext);
  const store = useStore();

  page.setTitle('401');

  useEffect(() => {
    store.logout();
  });

  return (
    <section className="c-block c-block--spacing-b">
      <div className="o-container-fluid o-container--md u-text-center">
        <div className="c-lighthouse u-mb-spacer-base-large">
          <div className="c-lighthouse__lights"></div>
          <img src="/assets/images/content/lighthouse.svg" alt="" />
        </div>
        <div className="c-block__content">
          <h2>You have no power here…</h2>
          <p>You dont have access to this content. If you got here by mistake, try going back to the <Link to={'/'} className="c-link-underline"><span>homepage</span></Link>.</p>
        </div>
      </div>
      <div className="c-lighthouse__overlay"></div>
    </section>
  );
}
