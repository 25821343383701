import HttpClient from 'api/http-client';
import { LinksSchema, MetaSchema, GetListingRequestSchema, GetListingRequest } from 'api/endpoints/common';
import * as z from 'zod';

const dateRegex = /^(?<fullyear>\d{4})-(?<month>0[1-9]|1[0-2])-(?<mday>0[1-9]|[12][0-9]|3[01])T(?<hour>[01][0-9]|2[0-3]):(?<minute>[0-5][0-9]):(?<second>[0-5][0-9]|60)(?<secfrac>\.[0-9]+)?(Z|(\+|-)(?<offset_hour>[01][0-9]|2[0-3]):(?<offset_minute>[0-5][0-9]))$/i;

export enum DashboardType {
  'sisense',
  'url',
  'powerbi',
  'powerbipages'
};

export const DashboardTypeSchema = z.nativeEnum(DashboardType);

export const DashboardObjectSchema = z.object({
  id: z.string(),
  client_id: z.string().optional(),
  name: z.string(),
  type: DashboardTypeSchema,
  source: z.string(),
  order: z.number(),
  created_at: z.string().regex(dateRegex),
  updated_at: z.string().regex(dateRegex),
  client_logo_url: z.string().optional(),
  client_logo_dark_url: z.string().optional(),
  client_name: z.string().optional(),
  shared: z.boolean().optional(),
  parent_id: z.string().optional(),
  number_of_clients: z.number().optional(),
});

export const DashboardObjectRequestSchema = DashboardObjectSchema.extend({
  id: z.string().optional(),
  client_id: z.string().optional(),
  name: z.string().optional(),
  type: DashboardTypeSchema.optional(),
  source: z.string().optional(),
}).omit({
  created_at: true,
  updated_at: true,
});

export const GetDashboardsResponseSchema = z.object({
  data: z.array(DashboardObjectSchema),
  links: LinksSchema,
  meta: MetaSchema,
});

export const GetDashboardsRequestSchema = GetListingRequestSchema.extend({
  client_id: z.string().optional(),
});

export const DashboardObjectIdSchema = z.object({
  id: z.string(),
});

export const ViewDashboardRequestSchema = DashboardObjectIdSchema.extend({
  datasetId: z.string(),
});

export type DashboardObject = z.infer<typeof DashboardObjectSchema>
export type DashboardObjectRequest = z.infer<typeof DashboardObjectRequestSchema>
export type GetDashboardsRequest = z.infer<typeof GetDashboardsRequestSchema>
export type GetDashboardsResponse = z.infer<typeof GetDashboardsResponseSchema>
export type DashboardObjectId = z.infer<typeof DashboardObjectIdSchema>
export type GetDashboardRequest = z.infer<typeof DashboardObjectIdSchema>
export type ViewDashboardRequest = z.infer<typeof ViewDashboardRequestSchema>
export type ToggleSharedDashboardForAllClientsRequest = z.infer<typeof DashboardObjectIdSchema>

export function getDashboards(data: GetDashboardsRequest) {
  return new HttpClient().request<GetDashboardsResponse>({
    method: 'get',
    url: '/dashboards',
    validator: GetDashboardsResponseSchema,
    params: data,
  });
}

export function getSharedDashboards(data: GetListingRequest) {
  return new HttpClient().request<GetDashboardsResponse>({
    method: 'get',
    url: '/dashboards/shared',
    validator: GetDashboardsResponseSchema,
    params: data,
  });
}

export function enableSharedDashboardForAllClients(data: ToggleSharedDashboardForAllClientsRequest) {
  return new HttpClient().request<GetDashboardsResponse>({
    method: 'post',
    url: `/dashboards/shared/${data.id}`
  });
}

export function disableSharedDashboardForAllClients(data: ToggleSharedDashboardForAllClientsRequest) {
  return new HttpClient().request<GetDashboardsResponse>({
    method: 'delete',
    url: `/dashboards/shared/${data.id}`
  });
}

export function newDashboard(data: DashboardObjectRequest) {
  return new HttpClient().request<DashboardObject>({
    method: 'post',
    url: '/dashboards',
    validator: DashboardObjectSchema,
    data,
  });
}

export function getDashboard({ id }: GetDashboardRequest) {
  return new HttpClient().request<DashboardObject>({
    method: 'get',
    url: `/dashboards/${id}`,
    validator: DashboardObjectSchema,
  });
}

export function viewDashboard({ id, datasetId }: ViewDashboardRequest) {
  return new HttpClient().request<DashboardObject>({
    method: 'get',
    url: `/dashboards/${id}/view`,
    validator: DashboardObjectSchema,
    params: {
      datasetId,
    }
  });
}

export function deleteDashboard({ id }: DashboardObjectId) {
  return new HttpClient().request<void>({
    method: 'delete',
    url: `/dashboards/${id}`,
  });
}

export function updateDashboard(data: DashboardObjectRequest) {
  return new HttpClient().request<DashboardObject>({
    method: 'put',
    url: `/dashboards/${data.id}`,
    data,
  });
}
