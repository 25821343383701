export default {
  APP_NAME: "LocusView",
  APP_VERSION: process.env.REACT_APP_VERSION || "unversioned",
  APP_ENVIRONMENT: process.env.NODE_ENV,
  APP_VERSION_CHECK_INTERVAL: process.env.REACT_APP_VERSION_CHECK_INTERVAL || "60000",
  API_BASE: window.location.hostname === 'eulocusview2.locusrobotics.com' ? `https://eulocusview2.locusrobotics.com/api/v1/` : (process.env.REACT_APP_PUBLIC_API_BASE || 'https://staging.locusview2.locusrobotics.com/api/v1/'),
  API_CLIENT_ID: process.env.REACT_APP_API_CLIENT_ID || "1",
  API_CLIENT_SECRET:
    process.env.REACT_APP_API_CLIENT_SECRET || "vxrqsrbMgypF785EveThhi9XyxbmlYLGvEPwq4wt",
  KIOSK_API_CLIENT_ID: process.env.REACT_APP_KIOSK_API_CLIENT_ID || "1",
  KIOSK_API_CLIENT_SECRET:
    process.env.REACT_APP_KIOSK_API_CLIENT_SECRET || "Rs64dU4LYkYu5HTyBUvBVlaJ0zlzf1K1nGOFwcKw",
  KIOSK_RELOAD_COUNT_START: process.env.REACT_APP_KIOSK_RELOAD_COUNT_START || "30",
  KIOSK_RELOAD_INTERVAL_IN_HRS: process.env.REACT_APP_KIOSK_RELOAD_INTERVAL_IN_HRS || "4",
  COOKIE_ACCESS_TOKEN_KEY: process.env.REACT_APP_COOKIE_ACCESS_TOKEN_KEY || "locusview_access",
  COOKIE_REFRESH_TOKEN_KEY: process.env.REACT_APP_COOKIE_REFRESH_TOKEN_KEY || "locusview_refresh",
  POWER_BI:
  window.location.hostname === 'eulocusview2.locusrobotics.com'? `https://eusisense.locusrobotics.com` : process.env.REACT_APP_SISENSE_SERVER_URL || "https://sisense-staging.locusview2.locusrobotics.com",
  DARKMODE_COOKIE_NAME: process.env.REACT_APP_DARKMODE_COOKIE_NAME || "darkMode",
  DEVICE_KEY_COOKIE_NAME: process.env.REACT_APP_DEVICE_KEY_COOKIE_NAME || "device_key",
  ACTIVATION_CODE_COOKIE_NAME: process.env.REACT_APP_ACTIVATION_KEY_COOKIE_NAME || "activation_key",
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN || "https://eafab10d949f400c8c201c37d3dd4f43@o184053.ingest.sentry.io/6399891",
  DEBUG: process.env.REACT_APP_DEBUG || false,
  NETWORK_ERROR_TIMEOUT: process.env.REACT_APP_NETWORK_ERROR_TIMEOUT || 5000,
  REACT_APP_POWERBI_API: window.location.hostname === 'eulocusview2.locusrobotics.com'? `https://weu-prd-analytics-fa-blue.azurewebsites.net/api` : process.env.REACT_APP_POWERBI_API || "https://eus-qa-analytics-fa-blue.azurewebsites.net/api",
  REACT_APP_POWERBI_DATASET: window.location.hostname === 'eulocusview2.locusrobotics.com'? `71809575-4fbc-40cd-8046-8ab9a7d61e81` : process.env.REACT_APP_POWERBI_DATASET || "fa899b27-ef52-43cd-a221-d53db38a981b",
  REACT_APP_POWERBI_DOMAIN: window.location.hostname === 'eulocusview2.locusrobotics.com'? `elcorteingles` : process.env.REACT_APP_POWERBI_DOMAIN || "stgdemo",
  REACT_APP_POWERBI_WAREHOUSE: window.location.hostname === 'eulocusview2.locusrobotics.com'? `corola` : process.env.REACT_APP_POWERBI_WAREHOUSE || "dhl14lockbourneoh",
};
