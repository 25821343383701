import * as React from 'react';

import classNames from 'classnames';
import { ListingType, listingContext } from 'components/cards/listing-context';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string'

// Icons
import { ReactComponent as IconGrid } from 'assets/svg/grid.svg';
import { ReactComponent as IconList } from 'assets/svg/list.svg';
import { ReactComponent as IconSearch } from 'assets/svg/search.svg';
import { ReactComponent as IconClose } from 'assets/svg/close.svg';
import { SelectBoxControl, SelectBoxOption } from 'components/controls/select-box-control';
import value from '*.ico';
import DeleteConfirmationAlert, { DeleteConfirmationAlertType } from 'components/form/delete-confirmation';
import user from './user';
//import { ReactComponent as IconSearch } from 'assets/svg/search.svg';

export interface IListingHeaderCustomAction {
  label: string,
  action: () => void
}

interface IListingHeaderProps {
  typeChangeHandler: (type: ListingType) => void,
  selectionHandler?: (selection: number[]) => void,
  selectAllHandler?: (shouldSelectAll: boolean) => void,
  deleteHandler?: (items: number[]) => void,
  customActions?: IListingHeaderCustomAction[],
  showSearch: boolean,
  hideListingTypes?: boolean
}

const ListingHeader: React.FunctionComponent<IListingHeaderProps> = ({
  typeChangeHandler,
  deleteHandler,
  selectAllHandler,
  customActions,
  showSearch,
  hideListingTypes
}) => {
  const listing = useContext(listingContext);
  let location = useLocation();
  let history = useHistory();
  let currentSortOption = getCurrentSortOption();
  let selectBoxSortOptions = listing.sort_options?.map((option) => { return { value: option.key + ',' + option.direction, label: option.title } });
  const [isDeleting, setIsDeleting] = useState(false);

  let params = queryString.parse(location.search);
  let [searchOptionsText, setSearchOptionsText] = useState(params.q ?? '');
  let [isSearchFocused, setIsSearchFocused] = useState(false);


  function arrayCount(array: any[]) {
    return Object.keys(array).length
  }

  function changeSort(option: string) {
    let params = queryString.parse(location.search);
    let sort = option.split(',')[0];
    let direction = option.split(',')[1];
    history.push(location.pathname + '?' + queryString.stringify({ ...params, sort, direction }));
  }

  function getCurrentSortOption() {
    let params = queryString.parse(location.search);
    if (!params.sort && listing.sort_options && listing.sort_options?.length > 0) {
      return listing.sort_options[0].key + ',' + listing.sort_options[0].direction;
    }
    return params.sort + ',' + params.direction;
  }

  function isSelectAllChecked() {
    let isChecked = false;
    if (listing.selection && arrayCount(listing.selection) > 0 && listing.data) {
      isChecked = arrayCount(listing.selection) === arrayCount(listing.data.data as any[]);
    }
    return isChecked;
  }

  function selectAllClicked() {
    if (selectAllHandler != null) {
      selectAllHandler(!isSelectAllChecked());
    }
  }

  function performSearch() {
    let params = queryString.parse(location.search);
    let sortOption = getCurrentSortOption();
    let sort = sortOption.split(',')[0];
    let direction = sortOption.split(',')[1];
    let q = searchOptionsText;
    history.push(location.pathname + '?' + queryString.stringify({ ...params, sort, direction, q }));
  }

  useEffect(()=>{
    performSearch();
  }, [searchOptionsText]);

  return (
    <>
      <div className="o-row o-row--fluid c-listing__header u-justify-between u-items-center">
        <div className="o-col">
          <div className="c-actions">
            {selectAllHandler != null &&
              <>
                <div className="c-actions__col">
                  <form>
                    <div className="c-form-element c-form-element--style-line c-form-element--checkbox  c-form-element--checkbox--basic">
                      <div className="c-form-element__field">
                        <input onClick={selectAllClicked} type="checkbox" id="check-item-31" checked={isSelectAllChecked()} readOnly />
                        <label htmlFor="check-item-31"></label>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="c-actions__col c-actions__col--no-divider">
                  {listing.selection && arrayCount(listing.selection) > 0 ? (
                    <p className="c-caption">{arrayCount(listing.selection)} selected {arrayCount(listing.selection) === 1 ? 'entry' : 'entries'}</p>
                  ) : (
                    <p className="c-caption">No selected entries</p>
                  )}
                </div>
              </>
            }
            {deleteHandler != null && (
              <div className="c-actions__col">
                {deleteHandler != null && listing.selection && arrayCount(listing.selection) > 0 && (
                  <div onClick={(event) => { event.preventDefault(); setIsDeleting(true); }} className="c-link-cta-light"><span>Delete</span></div>
                )}
              </div>
            )}
            {(customActions != null && customActions.length > 0) && (
              <>
                {customActions.map((customAction) => (
                  <div className="c-actions__col">
                    <a onClick={(event) => { event.preventDefault(); customAction.action(); }} className="c-link-cta"><span>{customAction.label}</span></a>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        <div className="o-col">
          <div className="c-actions">

            {showSearch &&
              <div className="c-actions__col c-actions__col--mobile-full">
                <form onSubmit={(event)=> event.preventDefault()} className={classNames(["c-actions__item-search", {"c-actions__item-search--active": isSearchFocused}])}>
                  <div className="c-form-element c-form-element--icon-last c-form-element--short c-form-element--style-line">
                    <div className="c-form-element__field">
                      <div className="c-form-element__icon">
                        <IconSearch className="o-svg-icon" />
                      </div>
                      {searchOptionsText !== '' &&
                        <div className="c-form-element__icon c-form-element__icon--clear" onClick={(event)=> { setSearchOptionsText(''); setIsSearchFocused(false); }}>
                          <IconClose className="o-svg-icon o-svg-smaller"/>
                        </div>
                      }
                      <input 
                        id="form-31-name" 
                        type="text" 
                        placeholder="Search" 
                        onChange={(event)=>{
                          let q = event.target.value.trim();
                          setSearchOptionsText(q);                      
                        }}
                        value={searchOptionsText}
                        onFocus={()=>setIsSearchFocused(true)}
                        onBlur={()=> {
                          if (searchOptionsText === '') {
                            setIsSearchFocused(false)
                          }
                        }}
                        />
                    </div>
                  </div>
                </form>
              </div>
            }

            <SelectBoxControl
              title={'sort by:'}
              subtitle={'Select sort order:'}
              options={selectBoxSortOptions}
              currentValue={currentSortOption}
              onChange={(selectedValue) => {
                changeSort(selectedValue);
              }}
            />

            {!hideListingTypes &&
              <div className="c-actions__col u-hidden u-flex@md">
                <div
                  onClick={(event) => typeChangeHandler(ListingType.grid)}
                  className={classNames([
                    'c-actions__icon c-actions__nav',
                    { 'is-selected': listing.type === ListingType.grid }
                  ])}>
                  <IconGrid className="o-svg-icon" />
                </div>
                <div
                  onClick={(event) => typeChangeHandler(ListingType.list)}
                  className={classNames([
                    'c-actions__icon c-actions__nav',
                    { 'is-selected': listing.type === ListingType.list }
                  ])}>
                  <IconList className="o-svg-icon" />
                </div>
              </div>
            }
            
          </div>
        </div>
      </div>
      {deleteHandler != null &&
        <DeleteConfirmationAlert
          onDelete={() => { deleteHandler(listing.selection!); setIsDeleting(false); }}
          onCancel={() => { setIsDeleting(false) }}
          resource_label={arrayCount(listing.selection!) + ' selected ' + (arrayCount(listing.selection!) === 1 ? 'entry' : 'entries')}
          show={isDeleting}
          type={DeleteConfirmationAlertType.Global}
        />
      }
    </>
  );
};

export default ListingHeader;
