import * as React from 'react';

import { ReactComponent as IconTrash } from 'assets/svg/trash.svg';
import { ReactComponent as IconClose } from 'assets/svg/close.svg';

import api from 'api';
import { ClientObject } from 'api/endpoints/clients/index'
import { useEffect, useState } from 'react';
import useRequest from 'api/use-request';

import { Form, FormNotification } from 'components/form/form';
import { FormFieldVariant } from 'components/form/common';
import InputField, { InputFieldType } from 'components/form/input';
import { SubmitButton } from 'components/form/submit-button';
import SelectField from 'components/form/select-field';

import { useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { debug } from 'utils/debug';
import DeleteConfirmationAlert, { DeleteConfirmationAlertType } from 'components/form/delete-confirmation';
import * as quartzite from 'quartzite';
import value from '*.ico';

import { Multiselect } from 'components/controls/multiselect-control';
import { Field } from 'formik';
import classNames from 'classnames';
import { DatasetFormObject, DatasetFormSchema, DatasetObject } from 'api/endpoints/datasets';

import timezones from 'utils/timezones';
import { TimezonePicker } from 'components/form/timezone-picker';

function formatDate(input: string) {
  const date = new Date(input);
  return quartzite.dateString(date);
}

export interface ISiteDetailsProps {
  isNew: boolean,
  nextLocation: string,
  onUpdate: () => void,
  isProfile: boolean,
}

function newDatasetObj(clientId: string): DatasetFormObject {
  return _.clone({
    id: '',
    name: '',
    client_id: clientId,
    connection: '',
    timezone: '',
    hostname: '',
  });
}

export function DatasetDetails({ isNew, nextLocation, onUpdate, isProfile }: ISiteDetailsProps) {
  let [initialValue, setInitialValue] = useState<DatasetFormObject>();
  let [isUploading, setIsUploading] = useState(false);
  let [isDeleting, setIsDeleting] = useState(false);
  let [editedDataset, setEditedDataset] = useState({} as DatasetObject);

  // require passwords when creating a new user, don't require for edits
  let validationSchema = DatasetFormSchema
    .omit({ id: true, client_id: true })
    .nonstrict();

  let history = useHistory();
  let { clientId, datasetId } = useParams() as {
    clientId: string | undefined,
    datasetId: string | undefined,
  };

  const submitHandler = (values: any, formikHelpers: any) => {
    const payload: DatasetFormObject = {
      id: datasetId ? datasetId : '',
      name: values.name,
      client_id: values.client_id,
      connection: values.connection,
      timezone: values.timezone,
      hostname: values.hostname,
    };

    let update: Promise<DatasetObject>;
    if (isNew) {
      update = api.datasets.newDataset(payload).fetch();
    } else {
      update = api.datasets.updateDataset(payload).fetch();
    }

    return update
      .then((response) => {
        history.push(nextLocation);
        onUpdate();
      })
  };

  const loadDataset = async (datasetId: string) => {
    let dataset = await api.datasets.getDataset({ id: datasetId }).fetch();
    if (dataset) {
      setEditedDataset(dataset);
      setInitialValue({ ...dataset, timezone: dataset.timezone? dataset.timezone : '', hostname: dataset.hostname? dataset.hostname : '' });
    }
  }

  const initForm = () => {
    if (!datasetId) {
      setInitialValue(newDatasetObj(clientId ?? ''));
    } else {
      loadDataset(datasetId);
    }
  }

  useEffect(initForm, []);

  useEffect(() => {
    document.body.classList.add('has-modal-open');

    return () => {
      document.body.classList.remove('has-modal-open');
    };
  });

  function deleteEditedDataset() {
    if (datasetId) {
      let update = api.datasets.deleteDataset({ id: datasetId }).fetch();
      onUpdate();
      update.then((response) => {
        setIsDeleting(false);
        history.push(nextLocation);
      }).catch((error) => {
        setIsDeleting(false);
        debug(error);
      });
    }
  }

  return (
    <>
      {initialValue ? (

        <div className="c-modal__root">
          <div className="c-modal__wrapper c-modal__wrapper--md">
            <div className="c-modal c-modal--overflow">
              <div className="c-modal__header">
                <div className="c-block c-block--spacing-t-extra-small">
                  <div className="o-container-fluid">
                    <div className="o-row o-row--fluid c-block__row u-flex-row-reverse">
                      <div className="o-col">
                        <div onClick={() => { history.goBack() }} className="c-link-cta-basic"><span>Close</span><IconClose className="o-svg-icon" /></div>
                      </div>
                      <div className="o-col u-flex-grow">
                        <p className="c-modal__headline">{isNew ? 'Add' : 'Edit'} site</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="c-modal__main">
                <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
                  <div className="o-container-fluid">
                    <Form
                      validationSchema={validationSchema}
                      initialValues={initialValue}
                      onSubmit={submitHandler}
                      enableReinitialize={true}
                    >
                      {({ values, errors, setFieldValue }) => (
                        < div className="o-row">
                          <div className="o-col-6@sm o-col-7@md">
                            <FormNotification />
                            <div className="u-mb-spacer-base-large">
                              <h6>About</h6>
                              <div className="o-row o-row--small-gutters">
                                <div className="o-col-12@md o-col-12@lg o-col-12@xl">
                                    <InputField
                                      type={InputFieldType.text}
                                      name={`name`}
                                      placeholder="Name"
                                      label="Name"
                                      variant={FormFieldVariant.fill}
                                      autocomplete={false}
                                    />
                                </div>
                              </div>
                              <div className="o-row o-row--small-gutters">
                                <div className="o-col-12@md o-col-12@lg o-col-12@xl">
                                    <InputField
                                      type={InputFieldType.text}
                                      name={`connection`}
                                      placeholder="Database name"
                                      label="MS SQL Database"
                                      variant={FormFieldVariant.fill}
                                      autocomplete={false}
                                    />
                                </div>
                              </div>
                              <div className="o-row o-row--small-gutters">
                                <div className="o-col-12@md o-col-12@lg o-col-12@xl">
                                    <InputField
                                      type={InputFieldType.text}
                                      name={`hostname`}
                                      placeholder="Hostname"
                                      label="Hostname"
                                      variant={FormFieldVariant.fill}
                                      autocomplete={false}
                                    />
                                </div>
                              </div>
                              <div className="o-row o-row--small-gutters">
                                <div className="o-col-12@md o-col-12@lg o-col-12@xl">
                                <TimezonePicker
                                      name={`timezone`}
                                      placeholder="Select a Time Zone"
                                      label="Local Time Zone"
                                      variant={FormFieldVariant.fill}
                                    />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="o-col-6@sm o-col-5@md">
                            <div className="c-card c-card--bg-light">
                              <div className="c-card__body">
                                <div className="c-card__header">
                                  <h6>{isNew ? 'Create' : 'Update'}</h6>
                                  {!isNew && (
                                    <div className="c-card__desc">
                                      {editedDataset && editedDataset.updated_at ? (
                                        <p>This site was last updated {formatDate(editedDataset.updated_at)}.</p>
                                      ) : (
                                        <p></p>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="o-row o-row--fluid c-button-group">
                                  <div className="o-col">
                                    <SubmitButton disabled={isUploading}><span>{isNew ? 'Create' : 'Update'} site</span></SubmitButton>
                                  </div>
                                  {!isNew &&
                                    <div className="o-col c-button-group__inline">
                                      <div className="c-link-cta-basic" onClick={(event) => { event.preventDefault(); setIsDeleting(true); }}>
                                        <IconTrash className="o-svg-icon" /><span>Delete site</span>
                                      </div>
                                    </div>
                                  }
                                </div>
                              </div>
                              <DeleteConfirmationAlert
                                onDelete={deleteEditedDataset}
                                onCancel={() => {
                                  setIsDeleting(false);
                                }}
                                resource_label={editedDataset.name}
                                show={isDeleting}
                                type={DeleteConfirmationAlertType.Card}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <UserEditModalSkeleton />
      )
      }
    </>
  );
}

export function UserEditModalSkeleton() {
  return (
    <div className="c-modal__root">
      <div className="c-modal__wrapper c-modal__wrapper--md">
        <div className="c-modal c-modal--overflow">
          <div className="c-modal__header">
            <div className="c-block c-block--spacing-t-extra-small">
              <div className="o-container-fluid">
                <div className="o-row o-row--fluid c-block__row u-flex-row-reverse">
                  <div className="o-col">
                    <Skeleton width={250} />
                  </div>
                  <div className="o-col u-flex-grow">
                    <p className="c-modal__headline"><Skeleton width={250} /></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="c-modal__main">
            <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
              <div className="o-container-fluid">
                <div className="o-row">
                  <div className="o-col-6@sm o-col-7@md">
                    <div className="u-mb-spacer-base-large">
                      <h6><Skeleton width={250} /></h6>
                      <div className="o-row o-row--small-gutters">
                        <div className="o-col-12 u-mb-spacer-base">
                          <Skeleton width={480} height={60} />
                        </div>
                        <div className="o-col-12 u-mb-spacer-base">
                          <Skeleton width={480} height={60} />
                        </div>
                        <div className="o-col-12 u-mb-spacer-base">
                          <Skeleton width={480} height={60} />
                        </div>
                        <div className="o-col-12 u-mb-spacer-base">
                          <Skeleton width={480} height={60} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="o-col-6@sm o-col-5@md">
                    <div className="c-card c-card--bg-light">
                      <div className="c-card__body">
                        <div className="c-card__header">
                          <h6><Skeleton width={250} /></h6>
                          <div className="c-card__desc">
                            <p><Skeleton count={2} /></p>
                          </div>
                        </div>
                        <div className="o-row o-row--fluid c-button-group">
                          <div className="o-col">
                            <Skeleton width={250} height={50} />
                          </div>
                          <div className="o-col c-button-group__inline">
                            <Skeleton width={250} height={50} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
