import * as React from 'react';

import { ReactComponent as IconAdd } from 'assets/svg/add.svg';
import DatasetsListing from 'components/cards/datasets-listing';
import { useHistory, useLocation } from 'react-router-dom';


export interface IDatasetsTabProps {
  clientId: string
}

export default function DatasetsTab(props: IDatasetsTabProps) {
  let history = useHistory();
  let location = useLocation();
  let newDatasetLink = location.pathname + '/new';

  return (
    <div className="c-tabs-item is-active" id="tabsDatasets">
      <div className="u-mb-spacer-base-large">
        <div className="c-page-title">
          <h6>Sites</h6>
          <div onClick={() => { history.push(newDatasetLink) }} className="c-button c-button--stroke c-button--secondary c-button--icon u-hidden@md"><IconAdd className="o-svg-icon" /></div>
          <div onClick={() => { history.push(newDatasetLink) }} className="c-button c-button--stroke c-button--secondary u-hidden u-block@md"><IconAdd className="o-svg-icon" /><span>Add new site</span></div>
        </div>
        <DatasetsListing clientId={props.clientId} />
      </div>
    </div >
  );
}
