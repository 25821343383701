import * as React from 'react';

import { Switch, Route, useParams } from 'react-router-dom';
import { UserDetails } from 'components/cards/user-edit';
import { usersRefresh } from 'utils/users-refresh-singleton';
import { UsergroupDetails } from 'components/modals/usergroup-details';

export interface IUsergroupEditModalProps {
}

const UsergroupEditModal: React.FunctionComponent<IUsergroupEditModalProps> = () => {
  let { clientId } = useParams() as {
    clientId: string | undefined,
    usergroupId: string | undefined,
  };

  function onUpdate() {
    usersRefresh.needsRefresh = true;
  }

  return (
    <Switch>
      <Route path={`/client/:clientId/usergroups/:usergroupId/edit`}>
        <UsergroupDetails
          isNew={false}
          nextLocation={`/client/${clientId}/usergroups`}
          onUpdate={onUpdate}
          isProfile={false}
        />
      </Route>
      <Route path={`/client/:clientId/usergroups/new`}>
        <UsergroupDetails
          isNew={true}
          nextLocation={`/client/${clientId}/usergroups`}
          onUpdate={onUpdate}
          isProfile={false}
        />
      </Route>
      <Route path={`/usergroups/:usergroupId/edit`}>
        <UsergroupDetails
          isNew={false}
          nextLocation={`/usergroups`}
          onUpdate={onUpdate}
          isProfile={false}
        />
      </Route>
      <Route path={`/usergroups/new`}>
        <UsergroupDetails
          isNew={true}
          nextLocation={`/usergroups`}
          onUpdate={onUpdate}
          isProfile={false}
        />
      </Route>
    </Switch>
  );
};

export default UsergroupEditModal;
