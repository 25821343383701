import * as React from 'react';
import { RouteProps } from 'react-router';
import GuardedRoute from 'components/routes/guarded';
import Page403Error from 'pages/access-denied';
import { defineAbilityFor, TAppAbilityCanParams } from 'authorizations';
import useRequest from 'api/use-request';
import api from 'api';
import { useStore } from 'store';
import MainLayout from 'layouts/main';

export interface AuthorizedRouteProps extends RouteProps {
  subject: TAppAbilityCanParams[1];
  action: TAppAbilityCanParams[0];
}

export function AuthorizedRoute({ subject, action, ...rest }: AuthorizedRouteProps) {
  const store = useStore();
  const user = useRequest(api.users.getCurrentUser());
  const authorizedUser = defineAbilityFor(user.data);

  return (
    <GuardedRoute
      {...rest}
      guard={(params, children) => {
        
        if (authorizedUser.can(action, subject)) {
          window.loader(false);
          return children();
        } else {
          window.loader(true);
          return (
            <div></div>
          );
        }

        return (
          <MainLayout>
            <Page403Error />
          </MainLayout>
        );
      }}
    />
  );
}

export default AuthorizedRoute;
