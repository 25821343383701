import * as React from 'react';

import { Switch, Route, useParams } from 'react-router-dom';
import { UserDetails } from 'components/cards/user-edit';
import { usersRefresh } from 'utils/users-refresh-singleton';

export interface IUserEditModalProps {
}

const UserEditModal: React.FunctionComponent<IUserEditModalProps> = () => {
  let { clientId } = useParams() as {
    clientId: string | undefined,
    userId: string | undefined,
  };

  function onUpdate() {
    usersRefresh.needsRefresh = true;
  }

  return (
    <Switch>
      <Route path={`/client/:clientId/users/:userId/edit`}>
        <UserDetails
          isNew={false}
          nextLocation={`/client/${clientId}/users`}
          onUpdate={onUpdate}
          isProfile={false}
          showAllClients={false}
        />
      </Route>
      <Route path={`/client/:clientId/users/new`}>
        <UserDetails
          isNew={true}
          nextLocation={`/client/${clientId}/users`}
          onUpdate={onUpdate}
          isProfile={false}
          showAllClients={false}
        />
      </Route>
      <Route path={`/users/:userId/edit`}>
        <UserDetails
          isNew={false}
          nextLocation={`/users`}
          onUpdate={onUpdate}
          isProfile={false}
          showAllClients={true}
        />
      </Route>
      <Route path={`/users/new`}>
        <UserDetails
          isNew={true}
          nextLocation={`/users`}
          onUpdate={onUpdate}
          isProfile={false}
          showAllClients={true}
        />
      </Route>
    </Switch>
  );
};

export default UserEditModal;
