import React, { createRef, ReactChild, Suspense, useEffect, useState } from "react";
import { isObject } from "utils/checks";
import { store } from 'store';

import MainNav from 'components/layout/main-nav';
import Header from 'components/layout/header';
import { SisenseContextProvider } from 'components/dashboard/context';
import PageLoader from 'components/layout/page-loader';
import ErrorBoundary from 'components/error-boundary';

import UserEditModal from 'components/modals/user-edit-modal'
import Footer from 'components/layout/footer';
import Skeleton from 'react-loading-skeleton';
import { UserEditModalSkeleton } from "components/cards/user-edit";
import useRequest from "api/use-request";
import api from "api";
import classNames from "classnames";
import DatasetEditModal from "components/modals/dataset-edit-modal";
import UsergroupEditModal from "components/modals/usergroup-edit-modal";
import { PageContextProvider } from "components/layout/page-context";
import DashboardEditModal from "components/modals/dashboard-edit-modal";
import DeviceEditModal from "components/modals/device-edit-modal";
import config from "../../../../src/client/src/configuration";
import { useInterval, useTimeout } from "react-use";
import useCountdown from "hooks/use-countdown";

interface IMainLayoutProps {
  children: ReactChild | INamedChildrenSlots,
  kioskMode?: boolean,
}

interface INamedChildrenSlots {
  content: ReactChild,
  header: ReactChild,
  footer: ReactChild,
}

export default function MainLayout(props: IMainLayoutProps) {
  const { children } = props;
  const msAppVersonCheckInterval = parseInt(config.APP_VERSION_CHECK_INTERVAL);

  const [runAppVersionCheck, toggleAppVersionCheck] = useState(config.APP_VERSION !== "unversioned");
  const [secondsToAppReload, isCountdownRunning, { startCountdown }] = useCountdown({
    countStart: parseInt(config.KIOSK_RELOAD_COUNT_START),
  });

  const currentUser = useRequest(api.users.getCurrentUser());
  const headerRef = createRef<HTMLElement>();
  const footerRef = createRef<HTMLElement>();

  function hasSiteNav() {
    return (currentUser.data?.role && currentUser.data.role !== api.users.UserRoleTypes.User);
  }

  function prepareAppReload() {
    startCountdown();
  }

  if (!children) {
    throw new Error("Layouts need view components as children.");
  }

  useEffect(() => {
    if (!isCountdownRunning) {
      return;
    }
    if (secondsToAppReload === 0) {
      window.location.reload();
    } else {
      store.errors.presentUniqueError(
        'version.reload.countdown',
        `New application version was detected. Application will reload automatically (${secondsToAppReload}).`,
        'version',
        'warning');
    }
  }, [secondsToAppReload])

  useInterval(() => {
    api.service.getStatus().fetch().then((res) => {
      const clientVersion = config.APP_VERSION;
      const apiVersion = res.version as string;

      if (clientVersion === apiVersion) {
        return;
      }

      toggleAppVersionCheck(false);

      if (props.kioskMode) {
        prepareAppReload();
      } else {
        store.errors.presentError({
          identifier: undefined,
          style: 'warning',
          type: 'version',
          message: `New application version was detected. Please reload the application.`,
          cta: 'reload'
        });
      }
    });
  }, runAppVersionCheck ? msAppVersonCheckInterval : null);

  // Multiple named slots used
  if (hasNamedSlots(children)) {
    const { content } = children;

    return (
      <PageContextProvider kioskMode={props.kioskMode ?? false} headerRef={headerRef} footerRef={footerRef}>
        <SisenseContextProvider>
          <div className={classNames(['c-body', { 'has-site-nav': hasSiteNav()}])}>
            <MainNav />
            <Header ref={headerRef} />
            <ErrorBoundary>
              {content ? content : null}
            </ErrorBoundary>
            <UserEditModal />
            <DatasetEditModal />
            <UsergroupEditModal />
            <DashboardEditModal />
            <DeviceEditModal />
          </div>
        </SisenseContextProvider>
      </PageContextProvider>
    );
  }

  // Single component
  return (
    <PageContextProvider kioskMode={props.kioskMode ?? false} headerRef={headerRef} footerRef={footerRef}>
      <SisenseContextProvider>
        <div className={classNames(['c-body', { 'has-site-nav': hasSiteNav(), 'c-body--kiosk': props.kioskMode }])}>
          <ErrorBoundary>
            <Suspense fallback={<HeaderSkeleton />}>
              <MainNav />
              <Header ref={headerRef} />
            </Suspense>
            <Suspense fallback={<PageLoader />}>
                {children}
            </Suspense>
            <Suspense fallback={<UserEditModalSkeleton />}>
              <UserEditModal />
            </Suspense>
            <Suspense fallback={<UserEditModalSkeleton />}>
              <DatasetEditModal />
            </Suspense>
            <Suspense fallback={<UserEditModalSkeleton />}>
              <UsergroupEditModal />
            </Suspense>
            <Suspense fallback={<UserEditModalSkeleton />}>
              <DashboardEditModal />
            </Suspense>
            <Suspense fallback={<UserEditModalSkeleton />}>
              <DeviceEditModal />
            </Suspense>
          </ErrorBoundary>
          <Footer ref={footerRef} />
        </div>
      </SisenseContextProvider>
    </PageContextProvider>
  );

};

const hasNamedSlots = (children: any): children is INamedChildrenSlots => isObject(children) && 'content' in children;


export interface IHeaderSkeletonProps {
}

export function HeaderSkeleton(props: IHeaderSkeletonProps) {
  return (
    <header id="site-header" className="c-site-header">
      <div className="o-container-fluid o-container--full">
        <div className="c-site-header__wrapper">
          <div className="c-site-header__main">
            <div className="c-site-header__headline">
              <Skeleton />
            </div>
          </div>
          <div className="c-site-header__utility">
            <div className="c-site-user">
              <Skeleton />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
