import * as React from 'react';

import { ReactComponent as IconTrash } from 'assets/svg/trash.svg';
import { ReactComponent as IconClose } from 'assets/svg/close.svg';

import api from 'api';
import { ClientObject } from 'api/endpoints/clients/index'
import { useEffect, useState } from 'react';
import useRequest from 'api/use-request';

import { Form, FormNotification } from 'components/form/form';
import { FormFieldVariant } from 'components/form/common';
import InputField, { InputFieldType } from 'components/form/input';
import { SubmitButton } from 'components/form/submit-button';
import SelectField from 'components/form/select-field';

import { useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { debug } from 'utils/debug';
import DeleteConfirmationAlert, { DeleteConfirmationAlertType } from 'components/form/delete-confirmation';
import * as quartzite from 'quartzite';
import value from '*.ico';

import { Multiselect } from 'components/controls/multiselect-control';
import { Field } from 'formik';
import classNames from 'classnames';

import timezones from 'utils/timezones';
import { TimezonePicker } from 'components/form/timezone-picker';
import { DeviceFormObject, DeviceFormSchema, DeviceObject } from 'api/endpoints/devices';
import { getCurrentUser } from 'api/endpoints/users';
import { UsergroupObject } from 'api/endpoints/usergroups';
import { DatasetObject } from 'api/endpoints/datasets';

function formatDate(input: string) {
  const date = new Date(input);
  return quartzite.dateString(date);
}

export interface IDeviceDetailsProps {
  isNew: boolean,
  nextLocation: string,
  onUpdate: () => void,
}

function newDeviceObj(clientId: string): DeviceFormObject {
  return _.clone({
    id: '',
    name: '',
    client_id: clientId,
    activation_code: '',
    theme: 0,
    usergroup_id: '',
  });
}

export function DeviceDetails({ isNew, nextLocation, onUpdate }: IDeviceDetailsProps) {
  let [initialValue, setInitialValue] = useState<DeviceFormObject>();
  let [isUploading, setIsUploading] = useState(false);
  let [isDeleting, setIsDeleting] = useState(false);
  let [editedDevice, setEditedDevice] = useState({} as DeviceObject);
  let currentUser = useRequest(api.users.getCurrentUser());
  let [selectedDatasetId, setSelectedDatasetId] = useState<string>();
  let [currentActivationCode, setCurrentActivationCode] = useState<string>();


  // require passwords when creating a new device, don't require for edits
  let validationSchema = DeviceFormSchema
    .omit({ id: true, client_id: true, user_id: true, usergroup_id: true, dataset_id: true, })
    .nonstrict();

  let history = useHistory();
  let { clientId, deviceId } = useParams() as {
    clientId: string | undefined,
    deviceId: string | undefined,
  };

  let allDatasets = useRequest(api.datasets.getDatasets({client_id: clientId}));
  let allUsergroups = useRequest(api.usergroups.getUsergroups({client_id: clientId, dataset_id: selectedDatasetId}));

  let [currentStep, setCurrentStep] = useState(1);
  let [isCheckingActivationCode, setIsCheckingActivationCode] = useState(false);

  const submitHandler = (values: any, formikHelpers: any) => {
    const payload: DeviceFormObject = {
      id: deviceId ? deviceId : '',
      name: values.name,
      user_id: currentUser.data?.id,
      client_id: values.client_id!,
      activation_code: values.activation_code,
      theme: values.theme,
      usergroup_id: values.usergroup_id,
      dataset_id: values.dataset_id,
    };

    //console.log(payload);

    let update: Promise<DeviceObject>;
    if (isNew) {
      update = api.devices.newDevice(payload).fetch();
    } else {
      update = api.devices.updateDevice(payload).fetch();
    }

    return update
      .then((response) => {
        history.push(nextLocation);
        onUpdate();
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  const nextClickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    if (currentActivationCode) {
      setIsCheckingActivationCode(true);
      api.devices.getDeviceByCode(currentActivationCode).fetch()
      .then((response)=>{
        setCurrentStep(2);
        setIsCheckingActivationCode(false);
      })
      .catch((error)=>{
        //console.log(error);
        setIsCheckingActivationCode(false);
      });
    }
  };

  // const submitHandler = (values: any, formikHelpers: any) => {
  //   if (currentUser.data && currentUser.data.role === UserRoleTypes.SuperAdmin && selectedClientId) {
  //     clientId = selectedClientId;
  //   }
  //   const payload: DeviceFormObject = {
  //     name: values.name,
  //     activation_code: values.activation_code,
  //     user_id: userId,
  //     client_id: clientId,
  //   };

  //   return api.devices.newDevice(payload).fetch()
  //     .then((response) => {
  //       //store.notifications.presentNotification('Your device was activated.');
  //       devices.mutate();
  //       formikHelpers.resetForm();
  //     })
  // };

  // const deactivateDevice = (deviceId: string) => {
  //   api.devices.deleteDevice({id: deviceId}).fetch()
  //   .then((response) => {
  //     devices.mutate();
  //   })
  // }


  const loadDevice = async (deviceId: string) => {
    let device = await api.devices.getDevice({ id: deviceId }).fetch();
    if (device) {
      setEditedDevice(device);
      setInitialValue({ ...device});
    }
  }

  const initForm = () => {
    if (!deviceId) {
      setInitialValue(undefined);
    } else {
      loadDevice(deviceId);
    }
  }

  useEffect(initForm, []);

  useEffect(() => {
    document.body.classList.add('has-modal-open');

    return () => {
      document.body.classList.remove('has-modal-open');
    };
  });

  function deleteEditedDevice() {
    if (deviceId) {
      let update = api.devices.deleteDevice({ id: deviceId }).fetch();
      onUpdate();
      update.then((response) => {
        setIsDeleting(false);
        history.push(nextLocation);
      }).catch((error) => {
        setIsDeleting(false);
        debug(error);
      });
    }
  }

  return (
    <>
      {!isNew ? (
        <div className="c-modal__root">
          <div className="c-modal__wrapper c-modal__wrapper--md">
            <div className="c-modal c-modal--overflow">
              <div className="c-modal__header">
                <div className="c-block c-block--spacing-t-extra-small">
                  <div className="o-container-fluid">
                    <div className="o-row o-row--fluid c-block__row u-flex-row-reverse">
                      <div className="o-col">
                        <div onClick={() => { history.goBack() }} className="c-link-cta-basic"><span>Close</span><IconClose className="o-svg-icon" /></div>
                      </div>
                      <div className="o-col u-flex-grow">
                        <p className="c-modal__headline">Edit device</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="c-modal__main">
                <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
                  <div className="o-container-fluid">
                    {initialValue && 
                      <Form
                        validationSchema={validationSchema}
                        initialValues={initialValue}
                        onSubmit={submitHandler}
                        enableReinitialize={true}
                      >
                        {({ values, errors, setFieldValue }) => (
                          < div className="o-row">
                            <div className="o-col-6@sm o-col-7@md">
                              <FormNotification />
                              <div className="u-mb-spacer-base-large">
                                <h6>About</h6>
                                <div className="o-row o-row--small-gutters">
                                  <div className="o-col-12@md o-col-12@lg o-col-12@xl">
                                      <InputField
                                        type={InputFieldType.text}
                                        name="name"
                                        placeholder="Device Name"
                                        label="Device Name"
                                        variant={FormFieldVariant.fill}
                                        autocomplete={false}
                                      />
                                  </div>
                                </div>

                                {allDatasets.data ? (
                                  <div className="o-row o-row--small-gutters">
                                    <div className="o-col-12">
                                      <label htmlFor={"dataset_id"} className={classNames('c-form-label')}>Site</label>
                                      <Field
                                        name="dataset_id"
                                        id="dataset_id"
                                        component={Multiselect}
                                        placeholder="Select Site"
                                        options={allDatasets.data.data.map((dataset: DatasetObject) => { return { value: dataset.id, label: dataset.name } })}
                                        isMulti={false}
                                        onChange={(selectedValue: string) => {
                                          setSelectedDatasetId(selectedValue);
                                        }}
                                      />
                                      <br />
                                    </div>
                                  </div>
                                ) : (<div />)}

                                {allUsergroups.data ? (
                                  <div className="o-row o-row--small-gutters">
                                    <div className="o-col-12">
                                      <label htmlFor={"usergroup_id"} className={classNames('c-form-label')}>User Group</label>
                                      <Field
                                        name="usergroup_id"
                                        id="usergroup_id"
                                        component={Multiselect}
                                        placeholder="Select User Group"
                                        options={allUsergroups.data.data.map((usergroup: UsergroupObject) => { return { value: usergroup.id, label: usergroup.name } })}
                                        isMulti={false}
                                      />
                                      <br />
                                    </div>
                                  </div>
                                ) : (<div />)}

                                <div className="o-row o-row--small-gutters">
                                  <div className="o-col-12">
                                    <label htmlFor={"theme"} className={classNames('c-form-label')}>Theme</label>
                                    <Field
                                      name="theme"
                                      id="theme"
                                      component={Multiselect}
                                      placeholder="Theme"
                                      options={ [{ value: 0, label: 'Light' }, { value: 1, label: 'Dark' }] }
                                      isMulti={false}
                                    />
                                    <br />
                                  </div>
                                </div>

                                {/* <div className="o-row o-row--small-gutters">
                                  <div className="o-col-12@md o-col-12@lg o-col-12@xl">
                                      <InputField
                                        type={InputFieldType.text}
                                        name={`activation_code`}
                                        placeholder="Activation Code"
                                        label="Activation Code"
                                        variant={FormFieldVariant.fill}
                                        autocomplete={false}
                                        disabled={!isNew}
                                      />
                                  </div>
                                </div> */}

                              </div>
                            </div>
                            <div className="o-col-6@sm o-col-5@md">
                              <div className="c-card c-card--bg-light">
                                <div className="c-card__body">
                                  <div className="c-card__header">
                                    <h6>Update</h6>                                    
                                    <div className="c-card__desc">
                                      {editedDevice && editedDevice.updated_at ? (
                                        <p>This device was last updated {formatDate(editedDevice.updated_at)}.</p>
                                      ) : (
                                        <p></p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="o-row o-row--fluid c-button-group">
                                    <div className="o-col">
                                      <SubmitButton disabled={isUploading}><span>Update device</span></SubmitButton>
                                    </div>
                                      <div className="o-col c-button-group__inline">
                                        <div className="c-link-cta-basic" onClick={(event) => { event.preventDefault(); setIsDeleting(true); }}>
                                          <IconTrash className="o-svg-icon" /><span>Delete device</span>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                                <DeleteConfirmationAlert
                                  onDelete={deleteEditedDevice}
                                  onCancel={() => {
                                    setIsDeleting(false);
                                  }}
                                  resource_label={editedDevice.name}
                                  show={isDeleting}
                                  type={DeleteConfirmationAlertType.Card}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </Form>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Form
          validationSchema={validationSchema}
          initialValues={newDeviceObj(clientId!)}
          onSubmit={submitHandler}
          enableReinitialize={true}
          >
        {({ values, errors, setFieldValue }) => (
          <div className="c-modal__root">
            <div className="c-modal__wrapper c-modal__wrapper--xs">

                {/* Step 1 */}
                <div className={classNames(['c-modal', 'c-modal--bg-primary', 'c-color--invert', {'u-hidden': currentStep !== 1}])}>
                  <div className="c-modal__header">
                    <div className="c-block c-block--spacing-t-extra-small">
                      <div className="o-container-fluid">
                        <div className="o-row o-row--fluid u-flex-row-reverse">
                          <div className="o-col">
                            <div onClick={() => { history.goBack() }} className="c-link-cta-basic"><span>Cancel</span><IconClose className="o-svg-icon" /></div>
                          </div>
                          <div className="o-col u-flex-grow">
                            <p className="c-modal__headline u-mb-spacer-base-small">
                              Activate New Device
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="c-modal__main">
                    <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
                      <div className="o-container-fluid">
                        <h5 className="u-mb-spacer">Enter activation code</h5>
                        <div className="c-caption">
                          <p>Step 1 out of 2</p>
                        </div>
                        <InputField
                          type={InputFieldType.text}
                          name={`activation_code`}
                          placeholder="Activation Code"
                          variant={FormFieldVariant.fill}
                          autocomplete={false}
                          disabled={!isNew}
                          onKeyUp={(e) => {
                            //console.log('evo');
                            setCurrentActivationCode(e.currentTarget.value);
                          }}
                        />

                        <a 
                          href="#" 
                          onClick={nextClickHandler} 
                          className={classNames(["c-button", "c-button--stroke", "c-button--primary", "c-button--full", "u-mb-spacer-base", {"c-button--disabled": isCheckingActivationCode || !currentActivationCode || currentActivationCode === ''}])}
                        >
                          <span>Next</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Step 2 */}
                <div className={classNames(['c-modal', {'u-hidden': currentStep !== 2}])}>
                  <div className="c-modal__header">
                    <div className="c-block c-block--spacing-t-extra-small">
                      <div className="o-container-fluid">
                        <div className="o-row o-row--fluid u-flex-row-reverse">
                          <div className="o-col">
                            <div onClick={() => { history.goBack() }} className="c-link-cta-basic"><span>Cancel</span><IconClose className="o-svg-icon" /></div>
                          </div>
                          <div className="o-col u-flex-grow">
                            {/* <div onClick={() => setCurrentStep(1)} className="c-link-cta-basic"><span>Back</span></div> */}
                            <p className="c-modal__headline u-mb-spacer-base-small">
                              Activate New Device
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="c-modal__main">
                    <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
                      <div className="o-container-fluid">
                        <h5 className="u-mb-spacer">Enter general info</h5>
                        <div className="c-caption">
                          <p>Step 2 out of 2</p>
                        </div>
                        
                        <InputField
                          type={InputFieldType.text}
                          name={`name`}
                          placeholder="Device Name"
                          label="Device Name"
                          variant={FormFieldVariant.fill}
                          autocomplete={false}
                        />

                        {allDatasets.data ? (
                          <>
                            <label htmlFor={"dataset_id"} className={classNames('c-form-label')}>Site</label>
                            <Field
                              name="dataset_id"
                              id="dataset_id"
                              component={Multiselect}
                              placeholder="Select Site"
                              options={allDatasets.data.data.map((dataset: DatasetObject) => { return { value: dataset.id, label: dataset.name } })}
                              isMulti={false}
                              onChange={(selectedValue: string) => {
                                setSelectedDatasetId(selectedValue);
                              }}
                            />
                            <br />
                          </>
                        ) : (<div />)}

                        {allUsergroups.data ? (
                          <>
                            <label htmlFor={"usergroup_id"} className={classNames('c-form-label')}>User Group</label>
                            <Field
                              name="usergroup_id"
                              id="usergroup_id"
                              component={Multiselect}
                              placeholder="Select User Group"
                              options={allUsergroups.data.data.map((usergroup: UsergroupObject) => { return { value: usergroup.id, label: usergroup.name } })}
                              isMulti={false}
                            />
                            <br />
                          </>
                        ) : (<div />)}

                        <label htmlFor={"theme"} className={classNames('c-form-label')}>Theme</label>
                        <Field
                          name="theme"
                          id="theme"
                          component={Multiselect}
                          placeholder="Theme"
                          options={ [{ value: 0, label: 'Light' }, { value: 1, label: 'Dark' }] }
                          isMulti={false}
                        />
                        <br />

                        <SubmitButton className='c-button c-button--primary c-button--full u-mb-spacer-base' disabled={isUploading}><span>Activate device</span></SubmitButton>
                      </div>
                    </div>
                  </div>
                </div>

            </div>
          </div>
        )}
        </Form>
  
      )
      }
    </>
  );
}

export function UserEditModalSkeleton() {
  return (
    <div className="c-modal__root">
      <div className="c-modal__wrapper c-modal__wrapper--md">
        <div className="c-modal c-modal--overflow">
          <div className="c-modal__header">
            <div className="c-block c-block--spacing-t-extra-small">
              <div className="o-container-fluid">
                <div className="o-row o-row--fluid c-block__row u-flex-row-reverse">
                  <div className="o-col">
                    <Skeleton width={250} />
                  </div>
                  <div className="o-col u-flex-grow">
                    <p className="c-modal__headline"><Skeleton width={250} /></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="c-modal__main">
            <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
              <div className="o-container-fluid">
                <div className="o-row">
                  <div className="o-col-6@sm o-col-7@md">
                    <div className="u-mb-spacer-base-large">
                      <h6><Skeleton width={250} /></h6>
                      <div className="o-row o-row--small-gutters">
                        <div className="o-col-12 u-mb-spacer-base">
                          <Skeleton width={480} height={60} />
                        </div>
                        <div className="o-col-12 u-mb-spacer-base">
                          <Skeleton width={480} height={60} />
                        </div>
                        <div className="o-col-12 u-mb-spacer-base">
                          <Skeleton width={480} height={60} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="o-col-6@sm o-col-5@md">
                    <div className="c-card c-card--bg-light">
                      <div className="c-card__body">
                        <div className="c-card__header">
                          <h6><Skeleton width={250} /></h6>
                          <div className="c-card__desc">
                            <p><Skeleton count={2} /></p>
                          </div>
                        </div>
                        <div className="o-row o-row--fluid c-button-group">
                          <div className="o-col">
                            <Skeleton width={250} height={50} />
                          </div>
                          <div className="o-col c-button-group__inline">
                            <Skeleton width={250} height={50} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
