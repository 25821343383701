import { Instance, setLivelinessChecking, types } from 'mobx-state-tree';
import { array } from 'mobx-state-tree/dist/internal';

export const AppNotification = types.model({
  identifier: types.string,
  type: types.enumeration(['form']),
  message: types.string,
  cta: types.maybe(types.string),
})

interface IAppNotification extends Instance<typeof AppNotification> {}

export const Notifications = types
  .model({
    app: types.array(AppNotification),
  })
  .views((self) => ({
    get hasNotification() {
      return true;
    }
  }))
  .actions((self) => ({
    presentNotification: function(message: string, autoDismiss?: boolean) {
        let notification: IAppNotification = { 
            identifier: Math.random().toString(16).substr(2, 8),
            type: 'form', 
            message: message, 
            cta: undefined,
        };
        self.app.push(notification);
    },
    dismissNotification: function(identifier: string) {
        let index = self.app.findIndex((notification) => notification.identifier === identifier);
        if (index > -1) {
            self.app.splice(index, 1);
        }
    }
  }))

export default Notifications;
