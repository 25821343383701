import * as React from 'react';

import { ReactComponent as IconTrash } from 'assets/svg/trash.svg';
import { ReactComponent as IconClose } from 'assets/svg/close.svg';

import api from 'api';
import { ClientObject } from 'api/endpoints/clients/index'
import { useEffect, useState } from 'react';
import useRequest from 'api/use-request';

import { Form, FormNotification } from 'components/form/form';
import { FormFieldVariant } from 'components/form/common';
import InputField, { InputFieldType } from 'components/form/input';
import { SubmitButton } from 'components/form/submit-button';
import SelectField from 'components/form/select-field';

import { useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { debug } from 'utils/debug';
import DeleteConfirmationAlert, { DeleteConfirmationAlertType } from 'components/form/delete-confirmation';
import * as quartzite from 'quartzite';
import value from '*.ico';
import queryString from 'query-string';

import { Multiselect } from 'components/controls/multiselect-control';
import { Field } from 'formik';
import classNames from 'classnames';
import { UsergroupFormObject, UsergroupFormSchema, UsergroupObject } from 'api/endpoints/usergroups';
import { DatasetObject } from 'api/endpoints/datasets';
import { DashboardObject } from 'api/endpoints/dashboards';
import { CheckboxField } from 'components/form/checkbox';

function formatDate(input: string) {
  const date = new Date(input);
  return quartzite.dateString(date);
}

export interface IUsergroupDetailsProps {
  isNew: boolean,
  nextLocation: string,
  onUpdate: () => void,
  isProfile: boolean,
}

function newUsergroupObj(clientId: string): UsergroupFormObject {
  return _.clone({
    id: '',
    name: '',
    client_id: clientId,
    datasets: [],
    dashboards: []
  });
}

export function UsergroupDetails({ isNew, nextLocation, onUpdate, isProfile }: IUsergroupDetailsProps) {
  let [initialValue, setInitialValue] = useState<UsergroupFormObject>();
  let [isUploading, setIsUploading] = useState(false);
  let [isDeleting, setIsDeleting] = useState(false);
  let [editedUsergroup, setEditedUsergroup] = useState({} as UsergroupObject);

  // require passwords when creating a new user, don't require for edits
  let validationSchema = UsergroupFormSchema
    .omit({ id: true, client_id: true })
    .nonstrict();

  let history = useHistory();
  let { clientId, usergroupId } = useParams() as {
    clientId: string | undefined,
    usergroupId: string | undefined,
  };

  let allDatasets = useRequest(api.datasets.getDatasets({ 'client_id': clientId, 'page': 1, 'limit': 1000 }));
  let allDashboards = useRequest(api.dashboards.getDashboards({ 'client_id': clientId, 'page': 1, 'limit': 1000 }));

  const submitHandler = (values: any, formikHelpers: any) => {
    const payload: UsergroupFormObject = {
      id: usergroupId ? usergroupId : '',
      name: values.name,
      client_id: values.client_id,
      datasets: values.datasets,
      dashboards: values.dashboards
    };

    let update: Promise<UsergroupObject>;
    if (isNew) {
      update = api.usergroups.newUsergroup(payload).fetch();
    } else {
      update = api.usergroups.updateUsergroup(payload).fetch();
    }

    return update
      .then((response) => {
        history.push(nextLocation);
        onUpdate();
      })
  };

  const loadUsergroup = async (usergroupId: string) => {
    let usergroup = await api.usergroups.getUsergroup({ id: usergroupId }).fetch();
    if (usergroup) {
      setEditedUsergroup(usergroup);

      let datasetsIds = usergroup.datasets.map(dataset => {
        return dataset.id;
      });
      let dashboardsIds = usergroup.dashboards.map(dashboard => {
        return dashboard.id;
      });

      setInitialValue({ ...usergroup, datasets: datasetsIds, dashboards: dashboardsIds });
    }
  }

  const initForm = () => {
    if (!usergroupId) {
      setInitialValue(newUsergroupObj(clientId ?? ''));
    } else {
      loadUsergroup(usergroupId);
    }
  }

  useEffect(initForm, []);

  useEffect(() => {
    document.body.classList.add('has-modal-open');

    return () => {
      document.body.classList.remove('has-modal-open');
    };
  });

  function deleteEditedUsergroup() {
    if (usergroupId) {
      let update = api.usergroups.deleteUsergroup({ id: usergroupId }).fetch();
      onUpdate();
      update.then((response) => {
        setIsDeleting(false);
        history.push(nextLocation);
      }).catch((error) => {
        setIsDeleting(false);
        debug(error);
      });
    }
  }

  return (
    <>
      {initialValue ? (

        <div className="c-modal__root">
          <div className="c-modal__wrapper c-modal__wrapper--lg">
            <div className="c-modal c-modal--overflow">
              <div className="c-modal__header">
                <div className="c-block c-block--spacing-t-extra-small">
                  <div className="o-container-fluid">
                    <div className="o-row o-row--fluid c-block__row u-flex-row-reverse">
                      <div className="o-col">
                        <div onClick={() => { history.goBack() }} className="c-link-cta-basic"><span>Close</span><IconClose className="o-svg-icon" /></div>
                      </div>
                      <div className="o-col u-flex-grow">
                        <p className="c-modal__headline">{isNew ? 'Add' : 'Edit'} user group</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="c-modal__main">
                <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
                  <div className="o-container-fluid">
                    <Form
                      validationSchema={validationSchema}
                      initialValues={initialValue}
                      onSubmit={submitHandler}
                      enableReinitialize
                    >
                      {({ values, errors, setFieldValue }) => (
                        < div className="o-row">
                          <div className="o-col-6@sm o-col-7@md o-col-8@lg">
                            <FormNotification />
                            <div className="u-mb-spacer-base">
                              <h6>About</h6>
                              <div className="o-row o-row--small-gutters">
                                <div className="o-col-12@md o-col-12@lg o-col-12@xl">
                                    <InputField
                                      type={InputFieldType.text}
                                      name={`name`}
                                      placeholder="Name"
                                      label="Name"
                                      variant={FormFieldVariant.fill}
                                      autocomplete={false}
                                    />
                                </div>
                                {allDatasets.data ? (
                                  <div className="o-col-12">
                                    <label htmlFor={"sites"} className={classNames('c-form-label')}>Sites</label>
                                    <Field
                                      name="datasets"
                                      id="datasets"
                                      component={Multiselect}
                                      placeholder="Sites"
                                      options={allDatasets.data.data.map((dataset: DatasetObject) => { return { value: dataset.id, label: dataset.name } })}
                                      isMulti={true}
                                    />
                                    <br />
                                  </div>

                                ) : (<div />)}

                                {allDashboards.data ? (
                                  <div className="o-col-12 u-mb-spacer-base">
                                    <label htmlFor={"dashboards"} className={classNames('c-form-label')}>Dashboards</label>
                                    <div className="o-row">
                                      {allDashboards.data.data.map((dashboard: DashboardObject) => { 
                                        return (
                                          <div className="o-col-6@md" key={'container' + dashboard.id}>
                                            <CheckboxField
                                              name={"dashboards"}
                                              label={undefined}
                                              options={dashboard.name}
                                              variant={FormFieldVariant.fill}
                                              className={'u-mb-spacer-base-small c-form-element--small'}
                                              value={dashboard.id}
                                              id={dashboard.id}
                                            />
                                          </div>  
                                        );
                                      })}
                                    </div>
                                  </div>
                                ) : (<div />)}

                              </div>
                            </div>
                          </div>
                          <div className="o-col-6@sm o-col-5@md o-col-4@lg">
                            <div className="c-card c-card--bg-light">
                              <div className="c-card__body">
                                <div className="c-card__header">
                                  <h6>{isNew ? 'Create' : 'Update'}</h6>
                                  {!isNew && (
                                    <div className="c-card__desc">
                                      {editedUsergroup && editedUsergroup.updated_at ? (
                                        <p>This user group was last updated {formatDate(editedUsergroup.updated_at)}.</p>
                                      ) : (
                                        <p></p>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="o-row o-row--fluid c-button-group">
                                  <div className="o-col">
                                    <SubmitButton disabled={isUploading}><span>{isNew ? 'Create' : 'Update'} user group</span></SubmitButton>
                                  </div>
                                  {!isNew &&
                                    <div className="o-col c-button-group__inline">
                                      <div className="c-link-cta-basic" onClick={(event) => { event.preventDefault(); setIsDeleting(true); }}>
                                        <IconTrash className="o-svg-icon" /><span>Delete user group</span>
                                      </div>
                                    </div>
                                  }
                                </div>
                              </div>
                              <DeleteConfirmationAlert
                                onDelete={deleteEditedUsergroup}
                                onCancel={() => {
                                  setIsDeleting(false);
                                }}
                                resource_label={editedUsergroup.name}
                                show={isDeleting}
                                type={DeleteConfirmationAlertType.Card}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <UserEditModalSkeleton />
      )
      }
    </>
  );
}

export function UserEditModalSkeleton() {
  return (
    <div className="c-modal__root">
      <div className="c-modal__wrapper c-modal__wrapper--lg">
        <div className="c-modal c-modal--overflow">
          <div className="c-modal__header">
            <div className="c-block c-block--spacing-t-extra-small">
              <div className="o-container-fluid">
                <div className="o-row o-row--fluid c-block__row u-flex-row-reverse">
                  <div className="o-col">
                    <Skeleton width={250} />
                  </div>
                  <div className="o-col u-flex-grow">
                    <p className="c-modal__headline"><Skeleton width={250} /></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="c-modal__main">
            <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
              <div className="o-container-fluid">
                <div className="o-row">
                  <div className="o-col-6@sm o-col-7@md o-col-8@lg">
                    <div className="u-mb-spacer-base-large">
                      <h6><Skeleton width={250} /></h6>
                      <div className="o-row o-row--small-gutters">
                        <div className="o-col-12 u-mb-spacer-base">
                          <Skeleton width={500} height={50} />
                        </div>
                        <div className="o-col-12 u-mb-spacer-base">
                          <Skeleton width={500} height={50} />
                        </div>
                        <div className="o-col-12 u-mb-spacer-base">
                          <Skeleton width={500} height={50} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="o-col-6@sm o-col-5@md o-col-4@lg">
                    <div className="c-card c-card--bg-light">
                      <div className="c-card__body">
                        <div className="c-card__header">
                          <h6><Skeleton width={250} /></h6>
                          <div className="c-card__desc">
                            <p><Skeleton count={2} /></p>
                          </div>
                        </div>
                        <div className="o-row o-row--fluid c-button-group">
                          <div className="o-col">
                            <Skeleton width={250} height={50} />
                          </div>
                          <div className="o-col c-button-group__inline">
                            <Skeleton width={250} height={50} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
