import api from 'api';
import { DashboardObject, DashboardType } from 'api/endpoints/dashboards';
import useRequest from 'api/use-request';
import classNames from 'classnames';
import useClickAway from 'hooks/use-click-away';
import * as React from 'react';
import { useEffect } from 'react';
import { useRef, useState, useContext } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import { ReactComponent as IconClose } from 'assets/svg/close.svg';
import { DatasetObject } from 'api/endpoints/datasets';

export interface IDashboardNavigationProps {
  clientId: string,
}

export function DashboardNavigation({ clientId }: IDashboardNavigationProps) {
  let [isSitePickerOpen, setIsSitePickerOpen] = useState(false);
  let [isDashboardPickerOpen, setIsDashboardPickerOpen] = useState(false);
  let [isSwitchingDataset, setIsSwitchingDataset] = useState(false);
  let [currentDataset, setCurrentDataset] = useState('');
  let [currentSiteName, setCurrentSiteName] = useState('');
  let [currentDashboardName, setCurrentDashboardName] = useState('');
  let [selectedDataset, setSelectedDataset] = useState(null);
  const currentUser = useRequest(api.users.getCurrentUser());
  let clientDashboards = useRequest(api.datasets.myDatasets({ client_id: clientId }));
  let history = useHistory();

  let { id, datasetId } = useParams() as {
    id: string | undefined,
    datasetId: string | undefined,
  };

  useEffect(() => {
    if (currentUser.data?.current_dataset && currentDataset === '') {
      setCurrentDataset(currentUser.data?.current_dataset);
    }
    setDropdowns();
  }, [currentUser])

  function setDropdowns() {
    const datasets = clientDashboards.data?.data;
    if (datasets && datasets.length > 0) {
      datasets?.forEach((dataset: DatasetObject) => {
        if (dataset.id === datasetId) {
          setCurrentSiteName(dataset.name)
          // @ts-ignore
          setSelectedDataset(dataset)
          // @ts-ignore
          dataset.dashboards?.forEach((dashboard: DatasetObject) => {
            if (dashboard.id === id) {
              setCurrentDashboardName(dashboard.name);
            }
          });
        }
      });
    }
  }

  function isCurrentDashboard(dashboardId: string) {
    return dashboardId === id;
  }

  function dashboardLink(dashboard: DashboardObject) {
    const clickHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (isSwitchingDataset) {
        event.preventDefault();
      } else {

      }
      setIsSitePickerOpen(false);
      setIsDashboardPickerOpen(false);
    };
    switch (dashboard.type) {
      case DashboardType.url:
        return <Link to={dashboard.source}>{dashboard.name}</Link>

      case DashboardType.powerbi:
        return <Link onClick={clickHandler} to={`/dashboard/p/${dashboard.id}/${datasetId}`}>{dashboard.name}</Link>

      case DashboardType.powerbipages:
        return <Link onClick={clickHandler} to={`/dashboard/p/${dashboard.id}/${datasetId}`}>{dashboard.name}</Link>

      default:
        return null;
    }
  }

  const siteContainer = useRef<HTMLDivElement>(null);
  useClickAway(siteContainer, () => { setIsSitePickerOpen(false); setIsDashboardPickerOpen(false); });

  function hasMultipleSites() {
    const datasets = clientDashboards.data?.data;
    return !!(datasets && datasets.length > 1);
  }

  function hasMultipleDashboards() {
    const datasets = clientDashboards.data?.data;
    return !!(datasets && datasets[0].dashboards && datasets[0].dashboards.length > 1);
  }

  function datasetLink(dataset: DatasetObject) {
    return <Link to={`/dashboard/${id}/${dataset.id}`} onClick={(e) => { e.preventDefault(); datasetClickHandler(`/dashboard/${id}/${dataset.id}`, dataset)}}>{dataset.name}</Link>
  }

  const datasetClickHandler = (url: any, dataset: any) => {
    setCurrentSiteName('')
    setSelectedDataset(null)
    setCurrentDashboardName('');
    url = url.split('/');
    url[2] = dataset.dashboards[0].id;
    url = url.join('/');
    history.push(url);
    setIsSitePickerOpen(false);
  };

  const datasetsPopupClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if(!hasMultipleSites()) {
      return;
    }

    if (!isSitePickerOpen) {
      setIsDashboardPickerOpen(false);
    }
    setIsSitePickerOpen(!isSitePickerOpen);
  };

  const dashboardsPopupClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if(!hasMultipleDashboards()) {
      return;
    }

    if (!isDashboardPickerOpen) {
      setIsSitePickerOpen(false);
    }
    setIsDashboardPickerOpen(!isDashboardPickerOpen);
  };

  return (
    <div className="c-site-select" ref={siteContainer}>
      <div className="c-popup__pointer c-site-select__toggle"  onClick={datasetsPopupClickHandler}>
        <span className={classNames(['c-site-select-label', {'c-site-select-label--single': !hasMultipleSites()}])}><span>{ currentSiteName }</span></span>
        <div className={classNames(['c-site-select__name', {'c-site-select__name--single': !hasMultipleDashboards()}])} onClick={dashboardsPopupClickHandler}>
          <span>{ currentDashboardName }</span>
        </div>
      </div>

      {hasMultipleSites() &&
        <div className={classNames([{ 'has-popup-open': isSitePickerOpen }])}>
          <div className="c-popup">
            <div className="c-popup__header">
              <p className="c-popup__title">Sites</p>
              <a href="#" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setIsSitePickerOpen(false); }} className="c-popup__toggle"><IconClose className="o-svg-icon" /></a>
            </div>
            <div className="c-popup__body">
              <ul className="c-page-nav__menu">
              {clientDashboards.data?.data.map((dataset, index) =>
                <li key={index} className={classNames([{
                  'is-selected': dataset.id === datasetId,
                  'disabled': isSwitchingDataset,
                }])}>
                  {datasetLink(dataset)}
                </li>
              )}
              </ul>
            </div>
          </div>
        </div>
      }

      {hasMultipleDashboards() &&
        <div className={classNames([{ 'has-popup-open': isDashboardPickerOpen }])}>
          <div className="c-popup">
            <div className="c-popup__header">
              <p className="c-popup__title">Dashboards</p>
              <a href="#" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setIsDashboardPickerOpen(false); }} className="c-popup__toggle"><IconClose className="o-svg-icon" /></a>
            </div>
            <div className="c-popup__body">
              <ul className="c-page-nav__menu">
                {/* @ts-ignore */}
                {selectedDataset?.dashboards?.map((dashboard, index) =>
                  <li key={index} className={classNames([{
                    'is-selected': isCurrentDashboard(dashboard.id),
                    'disabled': isSwitchingDataset,
                  }])}>
                    {dashboardLink(dashboard)}
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      }

    </div>
  );
}
