import * as React from 'react';

interface ILogoPreviewProps {
  newHandler: any,
  newButtonLabel: string,
  removeHandler: any,
  removeButtonLabel: string,
  filePreviewUrl: string,
  descriptionLabel: string | undefined,
  compactLayout: boolean,
  darkMode: boolean,
}

const LogoPreview: React.FunctionComponent<ILogoPreviewProps> = (props) => {
  return (
      <div className={`${props.darkMode ? "c-add--dark" : "c-add--light"} c-add c-add--link c-add--selected u-flex-nowrap@md`}>
        <div className="c-add__icon c-image-edit__container">
          <img src={props.filePreviewUrl} alt="" />
        </div>
        <div className="c-add__buttons-group">
          <div onClick={(e) => { e.preventDefault(); props.newHandler(); }} className="c-button c-button--stroke c-button--secondary"><span>{props.newButtonLabel}</span></div>
          <div onClick={(e) => { e.preventDefault(); props.removeHandler(); }} className="c-link-cta-basic"><span>{props.removeButtonLabel}</span></div>
        </div>
        {props.descriptionLabel &&
          <p className="c-note">{props.descriptionLabel}</p>
        }
      </div>

  );
};

export default LogoPreview;
