import * as React from 'react';
import {
  Route,
  Switch,
  Redirect,
  useParams,
  RouteProps
} from 'react-router-dom';

import MainLayout from 'layouts/main';
import BasicLayout from 'layouts/basic';


import NotFoundPage from 'pages/not-found';
import AccessDeniedPage from 'pages/access-denied';
import LoginPage from 'pages/login';
import ClientsPage from 'pages/clients';
import ClientPage from 'pages/client';
import UsersPage from 'pages/users';
import DashboardsPage from 'pages/dashboards';
import DashboardPage from 'pages/dashboard';
import PowerBiDashboardPage from 'pages/power-bi-dashboard';
import { useStore } from 'store';
import { Suspense } from 'react';
import AddClientPage from 'pages/client-add';
import ForgotPasswordPage from 'pages/password-forgot';
import ResetPasswordPage from 'pages/password-reset';
import ProfilePage from 'pages/profile';
import { NoDashboardsErrorPage } from 'pages/errors/no-dashboards';
import VerifyEmailPage from 'pages/verify-email';
import KioskPage from 'pages/kiosk';

// import HomePage from 'pages/home';
// import CutupLayout from 'layouts/cutup';
// import UsersPageCutup from 'pages/cutup/users';
// import DevicesPageCutup from 'pages/cutup/devices';
// import EditUserPage from 'pages/cutup/edit-user';
// import GroupsPageCutup from 'pages/cutup/groups';
// import EditGroupPage from 'pages/cutup/edit-group';
// import EditDashboardPage from 'pages/cutup/edit-dashboard';
// import LoginPageCutup from 'pages/cutup/login';
// import MessagePageCutup from 'pages/cutup/message';
// import WebkitPage from 'pages/cutup/webkit';

import { AuthorizationContext, defineAbilityFor } from 'authorizations';
import useRequest from 'api/use-request';
import api from 'api';
import { AuthenticatedRoute } from 'components/routes/authenticated';
import AuthorizedRoute from 'components/routes/authorized';
import { DatasetObject } from 'api/endpoints/datasets';
import AuthRoute from 'components/routes/auth';
import { KioskRoute } from 'components/routes/kiosk';
import { DeviceContextProvider } from 'components/layout/device-context';

const loginPath = "/login";

export interface LocationState {
  from?: {
    pathname: string;
  };
}

function firstDashboardForUser(datasets: DatasetObject[]): string|undefined {
  let url: string | undefined;

  if (datasets.length > 0 && datasets[0].dashboards && datasets[0].dashboards.length > 0) {
    url = '/dashboard/' + datasets[0].dashboards[0].id + '/' + datasets[0].id
  } else {
    url = '/nodashboards'
  }

  return url;
}

export default function Routes() {
  const store = useStore();
  const user = useRequest(api.users.getCurrentUser(), store.isLoggedIn);

  let authorizations = defineAbilityFor(
    store.isLoggedIn? user.data : undefined
  );

  return (
    <Switch>

      <AuthRoute exact path="/login">
        <BasicLayout>
          <LoginPage />
        </BasicLayout>
      </AuthRoute>
      <Route exact path="/password/forgot">
        <BasicLayout>
          <ForgotPasswordPage />
        </BasicLayout>
      </Route>
      <Route exact path="/password/reset">
        <BasicLayout>
          <ResetPasswordPage />
        </BasicLayout>
      </Route>
      <Route exact path="/verify-email">
        <BasicLayout>
          <VerifyEmailPage />
        </BasicLayout>
      </Route>

      <AuthenticatedRoute exact path="/clients">
        <MainLayout>
          <ClientsPage />
        </MainLayout>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/client/:clientId">
        <MainLayout>
          <ClientPage />
        </MainLayout>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/clients/new">
        <MainLayout>
          <AddClientPage />
        </MainLayout>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/users">
        <MainLayout>
          <UsersPage />
        </MainLayout>
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/dashboards">
        <MainLayout>
          <DashboardsPage />
        </MainLayout>
      </AuthenticatedRoute>

      <KioskRoute exact path="/kiosk/dashboard/:id/:datasetId">
        <DeviceContextProvider>
          <MainLayout kioskMode={true}>
            <DashboardPage />
          </MainLayout>
        </DeviceContextProvider>
      </KioskRoute>
      <KioskRoute exact path="/kiosk/dashboard/p/:id/:datasetId">
        <DeviceContextProvider>
          <MainLayout kioskMode={true}>
            <PowerBiDashboardPage />
          </MainLayout>
        </DeviceContextProvider>
      </KioskRoute>
      <Route exact path="/kiosk">
        <BasicLayout>
          <KioskPage />
        </BasicLayout>
      </Route>

      {/* <Route exact path="/">
        <CutupLayout>
          <HomePage />
        </CutupLayout>
      </Route>
      <Route path="/cutup/users">
        <CutupLayout>
          <UsersPageCutup />
        </CutupLayout>
      </Route>
      <Route path="/cutup/devices">
        <CutupLayout>
          <DevicesPageCutup />
        </CutupLayout>
      </Route>
      <Route path="/cutup/edit-user">
        <CutupLayout>
          <EditUserPage />
        </CutupLayout>
      </Route>
      <Route path="/cutup/groups">
        <CutupLayout>
          <GroupsPageCutup />
        </CutupLayout>
      </Route>
      <Route path="/cutup/edit-group">
        <CutupLayout>
          <EditGroupPage />
        </CutupLayout>
      </Route>
      <Route path="/cutup/dashboard">
        <CutupLayout>
          <DashboardPageCutup />
        </CutupLayout>
      </Route>
      <Route path="/cutup/edit-dashboard">
        <CutupLayout>
          <EditDashboardPage />
        </CutupLayout>
      </Route>
      <Route path="/cutup/login">
        <CutupLayout>
          <LoginPageCutup />
        </CutupLayout>
      </Route>
      <Route path="/cutup/message">
        <CutupLayout>
          <MessagePageCutup />
        </CutupLayout>
      </Route>
      <Route path="/cutup/webkit">
        <CutupLayout>
          <WebkitPage />
        </CutupLayout>
      </Route> */}

      <AuthenticatedRoute path="/">
        <AuthorizationContext.Provider value={authorizations}>
          <Switch>
            <AuthorizedRoute exact path="/clients" subject="Client" action="list">
              <MainLayout>
                <ClientsPage />
              </MainLayout>
            </AuthorizedRoute>
            <AuthorizedRoute path="/client/:clientId" subject="Client" action="read">
              <MainLayout>
                <ClientPage />
              </MainLayout>
            </AuthorizedRoute>
            <AuthorizedRoute path="/clients/new" subject="Client" action="create">
              <MainLayout>
                <AddClientPage />
              </MainLayout>
            </AuthorizedRoute>
            <AuthorizedRoute path="/users" subject="User" action="list">
              <MainLayout>
                <UsersPage />
              </MainLayout>
            </AuthorizedRoute>
            <AuthorizedRoute path="/dashboards" subject="Dashboard" action="list">
              <MainLayout>
                <DashboardsPage />
              </MainLayout>
            </AuthorizedRoute>
            <AuthorizedRoute exact path="/dashboard/:id/:datasetId" subject="Dashboard" action="read">
              <MainLayout>
                <DashboardPage />
              </MainLayout>
            </AuthorizedRoute>
            <Route exact path="/nodashboards">
              <MainLayout>
                <NoDashboardsErrorPage />
              </MainLayout>
            </Route>
            <AuthorizedRoute exact path="/dashboard/p/:id/:datasetId" subject="Dashboard" action="read">
              <MainLayout>
                <PowerBiDashboardPage />
              </MainLayout>
            </AuthorizedRoute>
            <Route exact path="/401">
              <MainLayout>
                <AccessDeniedPage />
              </MainLayout>
            </Route>
            <Route exact path="/403">
              <MainLayout>
                <AccessDeniedPage />
              </MainLayout>
            </Route>
            <Route exact path="/404">
              <MainLayout>
                <NotFoundPage />
              </MainLayout>
            </Route>
            <AuthorizedRoute path="/profile" subject="User" action="update">
              <MainLayout>
                <ProfilePage returnTo='' />
              </MainLayout>
            </AuthorizedRoute>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route path="*">
              <MainLayout>
                <NotFoundPage />
              </MainLayout>
            </Route>
          </Switch>
        </AuthorizationContext.Provider>
      </AuthenticatedRoute>
      <Route path="*">
        <MainLayout>
          <NotFoundPage />
        </MainLayout>
      </Route>
    </Switch>
  );
}
