import * as React from 'react';
import ErrorBlock, { ErrorBlockStyle } from 'components/layout/error-block';
import { PageContext } from 'components/layout/page-context';

export const NoDashboardsErrorPage: React.FunctionComponent = () => {
  const page = React.useContext(PageContext);
  React.useEffect(() => {
    page.setTitle(undefined, 'No dashboards found');
    page.setBackLink();
    page.setShowsClientSwitcher(false);
  });

  return (
    <ErrorBlock
        style={ErrorBlockStyle.NoDashboards}
        title="No dashboards available yet."
        message={'It looks like you currently have no Dashboards associated with your account.</p><p>For further assistance, please <a href="https://locusrobotics.com/get-started/support/">contact your administrator</a>.'}
        />
  );
}
