import * as React from 'react';

import classNames from 'classnames';

// Hooks
import { useContext, useEffect, useRef, useState } from 'react';

// Icons
import { ReactComponent as IconDropdown } from 'assets/svg/dropdown.svg';
import { ReactComponent as IconPen } from 'assets/svg/pen.svg';
import { ReactComponent as IconMore } from 'assets/svg/more.svg';
import { ReactComponent as IconEye } from 'assets/svg/eye.svg';
import { ReactComponent as IconTrash } from 'assets/svg/trash.svg';

import { DashboardObject, DashboardType } from 'api/endpoints/dashboards';
import { Link } from 'react-router-dom';
import { Button, ButtonScope, ButtonSizing } from 'components/button';
import useClickAway from 'hooks/use-click-away';
import { listingContext } from './listing-context';
import * as quartzite from 'quartzite';


interface IDashboardCardProps {
  dashboard: DashboardObject,
  deleteHandler: (ids: string[]) => void,
  selectHandler: (ids: string[]) => void,
}

const DashboardCard: React.FunctionComponent<IDashboardCardProps> = ({ dashboard, deleteHandler, selectHandler }) => {

  const [showActions, setShowActions] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  let [shouldShowWarning, setShouldShowWarning] = useState(false);

  const { selection } = useContext(listingContext);

  const trigger = useRef<HTMLDivElement>(null);
  useClickAway(trigger, () => setShowActions(false));

  function editDashboardLink(id: string): string {
    return `/dashboards/${id}/edit/general`;
  }

  function isSelected(id: string) {
    if (selection && selection.indexOf(id) > -1) {
      return true;
    }
    return false;
  }

  function formatDate(input: string) {
    const date = new Date(input);
    return quartzite.dateString(date);
  }

  if (!dashboard.id) return null;

  return (
    <div className="c-listing__item">
      <div ref={trigger} className={classNames(['c-card', 'c-card--link', 'c-card--bg-white', { 'is-selected': isSelected(dashboard.id) }, { 'has-popup-open': showActions, 'c-card--bg-warning': shouldShowWarning }])}>
        <div className="c-card__body">
          <div className="c-card__content">
            <p className="c-card__title"><Link to={editDashboardLink(dashboard.id)}>{dashboard.name}</Link></p>
            <div className="c-card__info">
              {dashboard.number_of_clients ? (
                <p>Clients: <strong>{dashboard.number_of_clients}</strong></p>
              ) : (
                <p>Clients: <strong>0</strong></p>
              )}
              {shouldShowWarning ? (
                <p className="u-text-warning"><strong>Update required</strong></p>
              ) : (
                <>
                  {dashboard.updated_at ? (
                    <p>Last updated: <strong>{formatDate(dashboard.updated_at)}</strong></p>
                  ) : (
                    <p>Last updated:</p>
                  )}
                </>
              )}
              
            </div>
          </div>
          <div className="c-card__footer">
            <Link to={editDashboardLink(dashboard.id)} className="c-link-cta-light c-card__link-main"><IconPen className="o-svg-icon" /><span>Edit</span></Link>
            {/* <Link to={editDashboardLink(dashboard.id)} className="c-link-cta-light c-card__link-hidden"><IconPen className="o-svg-icon" /><span>Edit</span></Link> */}
            <div className="c-card__actions c-card__link-hidden">
              <div onClick={(event) => setShowActions(!showActions)} className="c-card__actions-toggle">
                <IconMore className="o-svg-icon" />
              </div>
              <div className="c-popup">
                <div className="c-popup__header c-popup__header--sm">
                  <p className="c-popup__title">Actions</p>
                </div>
                <div className="c-popup__body">
                  <ul className="c-popup__list c-popup__list--small">
                    <li >
                      <Link to={editDashboardLink(dashboard.id)}>
                        <IconPen className="o-svg-icon" /><span>Edit Dashboard</span>
                      </Link>
                    </li>
                    <li className="c-popup__divider c-popup__divider--top">
                      <div className="c-popup__link" onClick={(event) => { event.preventDefault(); setDeleting(true); setShowActions(false) }}>
                        <IconTrash className="o-svg-icon" /><span>Delete Dashboard</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={classNames(['c-form-element', 'c-form-element--style-fill', 'c-form-element--checkbox', 'c-card__checkbox', { 'c-card__link-hidden': !isSelected(dashboard.id) }])}>
              <div className="c-form-element__field">
                <input checked={isSelected(dashboard.id)} type="checkbox" id={`check-listing-item-${dashboard.id}`} onChange={(event) => { if (dashboard.id) selectHandler([dashboard.id]); }} />
                <label htmlFor={`check-listing-item-${dashboard.id}`}></label>
              </div>
            </div>
          </div>
          <div className={classNames(['c-card__alert', 'c-card__alert--error', { 'u-hidden': !isDeleting }])}>
            <p className="c-card__alert-question">Are you sure you want to delete <strong>{dashboard.name}</strong>?</p>
            <div className="c-card__alert-options">
              <Button onClick={(event) => { if (dashboard.id) deleteHandler([dashboard.id]); setDeleting(false); }} scope={ButtonScope.white} outline sizing={ButtonSizing.small}><span>Yes</span></Button>
              <Button onClick={(event) => setDeleting(false)} scope={ButtonScope.white} outline sizing={ButtonSizing.small}><span>No</span></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
