import * as React from 'react';
// Icons
import { ReactComponent as IconAdd } from 'assets/svg/add.svg';

// Components
import ClientCard from 'components/cards/client';
import Listing from 'components/cards/listing';
import { listingContext, ListingSortOption, SortDirection } from 'components/cards/listing-context';

import api from 'api';
import useRequest from 'api/use-request';
import { useState, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { PageContext } from 'components/layout/page-context';

const ClientsIndexPage: React.FunctionComponent = () => {
  const page = useContext(PageContext);

  useEffect(() => {
    page.setTitle('Manage Clients');
    page.setBackLink();
    page.setShowsClientSwitcher(false);
  });

  let history = useHistory();
  let location = useLocation();
  let newClientLink = '/clients/new';

  let searchArgs = queryString.parse(location.search, { parseNumbers: true });
  if (searchArgs.limit === undefined) {
    searchArgs.limit = 12;
  }

  let [selection, setSelection] = useState<string[]>([]);

  let clients = useRequest(api.clients.getClients(searchArgs));

  let sort_options: Array<ListingSortOption> = [
    { key: 'name', title: 'Name, Ascending', direction: SortDirection.Ascending },
    { key: 'name', title: 'Name, Descending', direction: SortDirection.Descending },
    { key: 'created_at', title: 'Created At, Ascending', direction: SortDirection.Ascending },
    { key: 'created_at', title: 'Created At, Descending', direction: SortDirection.Descending },
    { key: 'users_count', title: 'Users Count, Ascending', direction: SortDirection.Ascending },
    { key: 'users_count', title: 'Users Count, Descending', direction: SortDirection.Descending },
    { key: 'datasets_count', title: 'Sites Count, Ascending', direction: SortDirection.Ascending },
    { key: 'datasets_count', title: 'Sites Count, Descending', direction: SortDirection.Descending },
  ];

  function deleteClients(ids: string[]) {
    let updates: Promise<unknown>[] = [];
    ids.forEach((id) => {
      updates.push(api.clients.deleteClient({ id }).fetch());
    });
    Promise.all(updates).finally(() => { 
      clients.mutate();
      setSelection([]);
    });
  }

  function toggleSelectClients(ids: string[]) {
    ids.forEach((id) => {
      const index = selection.indexOf(id);
      if (index > -1) {
        selection.splice(index, 1);
      } else {
        selection.push(id);
      }
    });
    setSelection([...selection]);
  }

  function selectAll(shouldSelect: boolean) {
    if (clients.data && clients.data?.data.length > 0) {
      clients.data?.data.forEach((client) => {
        const index = selection.indexOf(client.id);
        if (shouldSelect && index < 0) {
          selection.push(client.id);
        }
        if (!shouldSelect && index > -1) {
          selection.splice(index, 1);
        }
      });
      setSelection([...selection]);
    }
  }

  return (
    <section className="c-block c-block--spacing-b-small">
      <div className="o-container-fluid o-container--full">
        <div className="c-page-title">
          <h2 className="c-title--large">All Clients</h2>
          <div onClick={(e) => { e.preventDefault(); history.push(newClientLink) }} className="c-button c-button--stroke c-button--secondary c-button--icon u-hidden@md"><IconAdd className="o-svg-icon" /></div>
          <div onClick={(e) => { e.preventDefault(); history.push(newClientLink) }} className="c-button c-button--stroke c-button--secondary u-hidden u-block@md"><IconAdd className="o-svg-icon" /><span>Add new client</span></div>
        </div>
        <listingContext.Provider value={{ ...clients, selection, sort_options }}>
          <Listing
            deleteHandler={deleteClients}
            selectionHandler={toggleSelectClients}
            selectAllHandler={selectAll}
            settingsKey={'Clients'}
          >
            {clients.data?.data.map((client) =>
              <ClientCard
                deleteHandler={deleteClients}
                selectHandler={toggleSelectClients}
                key={client.id}
                client={client}
              />
            )}
          </Listing>
        </listingContext.Provider>
      </div>
    </section>
  );
};

export default ClientsIndexPage;
