import * as React from 'react';

import { ReactComponent as IconClose } from 'assets/svg/close.svg';
import { ReactComponent as IconMore } from 'assets/svg/more.svg';
import { ReactComponent as IconPen } from 'assets/svg/pen.svg';
import { ReactComponent as IconTrash } from 'assets/svg/trash.svg';

import * as quartzite from 'quartzite';
import { DeviceObject } from 'api/endpoints/devices';
import { useContext, useRef, useState } from 'react';
import classNames from 'classnames';
import useClickAway from 'hooks/use-click-away';
import DeleteConfirmationAlert, { DeleteConfirmationAlertType } from 'components/form/delete-confirmation';
import { listingContext } from './listing-context';
import { Link, useLocation } from 'react-router-dom';


interface IDeviceCardProps {
  device: DeviceObject,
  deleteHandler: (ids: string[]) => void,
  selectHandler: (ids: string[]) => void,
}

const DeviceCard: React.FunctionComponent<IDeviceCardProps> = ({ device, deleteHandler, selectHandler }) => {

  const [showActions, setShowActions] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { selection } = useContext(listingContext);

  const trigger = useRef<HTMLDivElement>(null);
  useClickAway(trigger, () => setShowActions(false));

  let location = useLocation();
  let editDeviceLink = location.pathname + `/${device.id}/edit`;

  function isSelected(id: string) {
    if (selection && selection.indexOf(id) > -1) {
      return true;
    }
    return false;
  }

  function formatDate(input: string) {
    const date = new Date(input);
    return quartzite.dateString(date);
  }

  return (
    <div className="c-listing__item">
      <div ref={trigger} className={classNames(['c-card', 'c-card--link', 'c-card--bg-white', { 'is-selected': isSelected(device.id) }, { 'has-popup-open': showActions }])}>
        <div className="c-card__body">
          <div className="c-card__content">
            <p className="c-card__title"><Link to={editDeviceLink}>{device.name}</Link></p>
            <div className="c-card__info">
            <p>Site: <strong>{device.dataset?.name}</strong></p>
            <p>User Group: <strong>{device.usergroup?.name}</strong></p>
            <p>Date added: <strong>{formatDate(device.created_at)}</strong></p>
            <p>Added by: <strong>{device.user?.first_name + ' ' + device.user?.last_name}</strong></p>
            </div>
          </div>
          <div className="c-card__footer">
            <Link to={editDeviceLink} className="c-link-cta-light"><IconPen className="o-svg-icon" /><span>Edit</span></Link>
            <div className="c-card__actions c-card__link-hidden">
              <div onClick={(event) => setShowActions(!showActions)} className="c-card__actions-toggle">
                <IconMore className="o-svg-icon" />
              </div>
              <div className="c-popup">
                <div className="c-popup__header c-popup__header--sm">
                  <p className="c-popup__title">Actions</p>
                </div>
                <div className="c-popup__body">
                  <ul className="c-popup__list c-popup__list--small">
                    <li >
                      <Link to={editDeviceLink}>
                        <IconPen className="o-svg-icon" /><span>Edit Device</span>
                      </Link>
                    </li>
                    <li className="c-popup__divider c-popup__divider--top">
                      <div className="c-popup__link" onClick={(event) => { event.preventDefault(); setIsDeleting(true); setShowActions(false) }}>
                        <IconTrash className="o-svg-icon" /><span>Delete Device</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={classNames('c-form-element', 'c-form-element--style-fill', 'c-form-element--checkbox', 'c-card__checkbox', { 'c-card__link-hidden': !isSelected(device.id) })}>
              <div className="c-form-element__field">
                <input checked={isSelected(device.id)} type="checkbox" id={`check-listing-item-${device.id}`} onChange={(event) => { selectHandler([device.id]); }} />
                <label htmlFor={`check-listing-item-${device.id}`}></label>
              </div>
            </div>
          </div>
          <DeleteConfirmationAlert
            onDelete={() => { deleteHandler([device.id]); setIsDeleting(false); }}
            onCancel={() => { setIsDeleting(false) }}
            resource_label={device.name}
            show={isDeleting}
            type={DeleteConfirmationAlertType.Card}
          />
        </div>
      </div>
    </div>
    
    // <tr key={device.id} className={classNames(['c-card', 'c-card--block', { 'has-popup-open': showActions }])}>
    //   <td>{device.name}<span className="c-table__cell-span">Code: {device.activation_code}</span></td>
    //   <td><div className="c-table__nowrap">{formatDate(device.created_at)}</div></td>
    //   <td className="u-pr-0">
    //     <div className="c-card__actions c-card__link-hidden">
    //       <a href="#" onClick={(event) => { event.preventDefault(); setShowActions(!showActions); }} className="c-card__actions-toggle">
    //         <IconMore className="o-svg-icon o-svg-left" />
    //       </a>
    //       <div className="c-popup">
    //         <div className="c-popup__header c-popup__header--sm">
    //           <p className="c-popup__title">Actions</p>
    //         </div>
    //         <div className="c-popup__body">
    //           <ul className="c-popup__list c-popup__list--small">
    //             <li className="">
    //               <a href="#" onClick={(event) => { event.preventDefault(); setDeleting(true); setShowActions(false) }}>
    //                 <IconClose className="o-svg-icon" /><span>Deactivate device<span>{device.name}</span></span>
    //               </a>
    //             </li>
    //           </ul>
    //         </div>
    //       </div>
    //     </div>
    //     <DeleteConfirmationAlert
    //       onDelete={() => { deleteHandler([device.id]); setDeleting(false); }}
    //       onCancel={() => { setDeleting(false); }}
    //       resource_label={device.name}
    //       show={isDeleting}
    //       type={DeleteConfirmationAlertType.Card}
    //     />
    //   </td>
    // </tr>
  );
};

export default DeviceCard;
