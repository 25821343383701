import * as auth from './endpoints/auth';
import * as clients from './endpoints/clients';
import * as users from './endpoints/users';
import * as dashboards from './endpoints/dashboards';
import * as files from './endpoints/files';
import * as password from './endpoints/password';
import * as datasets from './endpoints/datasets';
import * as usergroups from './endpoints/usergroups';
import * as devices from './endpoints/devices';
import * as service from './endpoints/service';

export default {
  auth,
  clients,
  users,
  dashboards,
  files,
  password,
  datasets,
  usergroups,
  devices,
  service,
};
