import * as React from 'react';

import { Switch, Route, useParams } from 'react-router-dom';
import { UserDetails } from 'components/cards/user-edit';
import { usersRefresh } from 'utils/users-refresh-singleton';
import { DatasetDetails } from './dataset-details';

export interface IDatasetEditModalProps {
}

const DatasetEditModal: React.FunctionComponent<IDatasetEditModalProps> = () => {
  let { clientId } = useParams() as {
    clientId: string | undefined,
    datasetId: string | undefined,
  };

  function onUpdate() {
    usersRefresh.needsRefresh = true;
  }

  return (
    <Switch>
      <Route path={`/client/:clientId/sites/:datasetId/edit`}>
        <DatasetDetails
          isNew={false}
          nextLocation={`/client/${clientId}/sites`}
          onUpdate={onUpdate}
          isProfile={false}
        />
      </Route>
      <Route path={`/client/:clientId/sites/new`}>
        <DatasetDetails
          isNew={true}
          nextLocation={`/client/${clientId}/sites`}
          onUpdate={onUpdate}
          isProfile={false}
        />
      </Route>
      <Route path={`/sites/:datasetId/edit`}>
        <DatasetDetails
          isNew={false}
          nextLocation={`/sites`}
          onUpdate={onUpdate}
          isProfile={false}
        />
      </Route>
      <Route path={`/sites/new`}>
        <DatasetDetails
          isNew={true}
          nextLocation={`/sites`}
          onUpdate={onUpdate}
          isProfile={false}
        />
      </Route>
    </Switch>
  );
};

export default DatasetEditModal;
