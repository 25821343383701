import * as React from 'react';

import { ReactComponent as IconTrash } from 'assets/svg/trash.svg';

import api from 'api';
import { useEffect, useState } from 'react';
import { CreateClientRequest, CreateClientRequestSchema, ClientObject } from 'api/endpoints/clients';
import { FileUploadResponseObject } from 'api/endpoints/files';

import { Form, FormNotification } from 'components/form/form';
import InputField, { InputFieldType } from 'components/form/input';
import { FormFieldVariant } from 'components/form/common';
import { SubmitButton } from 'components/form/submit-button';

import FileUploadIndicator from 'components/cards/file-upload-indicator';
import FileDropzoneField from 'components/form/file-dropzone-field';
import FilePreview from 'components/form/file-preview';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import DeleteConfirmationAlert, { DeleteConfirmationAlertType } from 'components/form/delete-confirmation';

import Skeleton from 'react-loading-skeleton';
import { sizedGroupLogoImageURL } from 'utils/imgix-helpers';
import { group } from 'console';
import * as quartzite from 'quartzite';
import value from '*.ico';
import { store } from 'store';
import LogoPreview from 'components/form/logo-preview';

function newClientObj(): CreateClientRequest {
  return _.clone({
    id: '0',
    name: '',
    logo: '*',
    logo_dark: '*',
  });
}

function formatDate(input: string) {
  const date = new Date(input);
  return quartzite.dateString(date);
}

export interface IClientGeneralTabProps {
  clientId: string,
  isNew: boolean
}

export default function ClientGeneralTab(props: IClientGeneralTabProps) {
  let [initialValue, setInitialValue] = useState<CreateClientRequest>();
  let [isDeleting, setIsDeleting] = useState(false);

  let [isUploading, setIsUploading] = useState(false);
  let [shouldOpenBrowseDialog, setShouldOpenBrowseDialog] = useState(false);
  let [uploadedFilePath, setUploadedFilePath] = useState('');
  let [uploadingFileName, setUploadingFileName] = useState('');

  let [isUploadingDark, setIsUploadingDark] = useState(false);
  let [shouldOpenBrowseDialogDark, setShouldOpenBrowseDialogDark] = useState(false);
  let [uploadedFilePathDark, setUploadedFilePathDark] = useState('');
  let [uploadingFileNameDark, setUploadingFileNameDark] = useState('');

  let [editedClient, setEditedClient] = useState<ClientObject>({} as ClientObject);
  let history = useHistory();

  const onUploadNewPicture = () => {
    let client = editedClient;
    client.logo_url = '*';
    setEditedClient(client);
    setShouldOpenBrowseDialog(true);
  }

  const onRemovePicture = () => {
    setUploadedFilePath('*');
    let client = editedClient;
    client.logo_url = '*';
    setEditedClient(client);
  }

  const onUploadNewPictureDark = () => {
    let client = editedClient;
    client.logo_dark_url = '*';
    setEditedClient(client);
    setShouldOpenBrowseDialog(true);
  }

  const onRemovePictureDark = () => {
    setUploadedFilePathDark('*');
    let client = editedClient;
    client.logo_dark_url = '*';
    setEditedClient(client);
  }

  const onDeleteClient = (clientId: string) => {
    api.clients.deleteClient({ id: clientId }).fetch().then((response) => {
      history.push('/clients');
    }).catch((error) => {
      // TODO: handle error
      console.log(error);
    });
  }

  const onSubmit = (values: any, formikHelpers: any) => {
    let update: Promise<ClientObject>;
    const payload: CreateClientRequest = {
      id: props.clientId,
      name: values.name,
      logo: uploadedFilePath,
      logo_dark: uploadedFilePathDark,
    };

    if (!props.isNew) {
      update = api.clients.updateClient(payload).fetch();
    } else {
      update = api.clients.newClient(payload).fetch();
    }
    return update
      .then((response) => {
        if (props.isNew) {
          history.push('/clients');
        }
        store.notifications.presentNotification('Your changes were saved.');
      })
  }

  const loadClient = async () => {
    let client = await api.clients.getClient({ id: props.clientId }).fetch();
    if (client) {
      setEditedClient(client);
      setInitialValue({ logo: '', logo_dark: '', ...client });
    }
  }


  const initForm = () => {
    if (!props.isNew) {
      loadClient();
    } else {
      setInitialValue(newClientObj());
    }
  }

  useEffect(initForm, []);

  return (
    <>
      {initialValue ? (

        <Form
          validationSchema={CreateClientRequestSchema.omit({ logo: true, logo_dark: true, logo_url: true, logo_dark_url: true }).nonstrict()}
          initialValues={initialValue}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, errors, setFieldValue }) => (

            <div className="o-row">
              <div className="o-col-8@md">
                <FormNotification />
                <div className="u-mb-spacer-base-large">
                  <h6>About</h6>
                  <InputField
                    type={InputFieldType.text}
                    name={`name`}
                    placeholder="Name"
                    label="Name"
                    variant={FormFieldVariant.fill}
                  />
                </div>

                <div className="u-mb-spacer-base-large">
                  <h6>Logo</h6>
                  <div className="o-row">
                    <div className="o-col-6@xl">  
                      <p>Light theme</p>
                      {isUploading ? (
                        <FileUploadIndicator
                          fileName={uploadingFileName}
                        />
                      ) : (
                        (editedClient.logo_url && editedClient.logo_url !== '*') ? (
                          <LogoPreview
                            filePreviewUrl={sizedGroupLogoImageURL(editedClient.logo_url)}
                            newButtonLabel={"Upload new logo"}
                            removeButtonLabel={"Remove logo"}
                            descriptionLabel={undefined}
                            newHandler={onUploadNewPicture}
                            removeHandler={onRemovePicture}
                            compactLayout={false}
                            darkMode={false}
                          />
                        ) : (
                          <FileDropzoneField
                            setFieldValue={setFieldValue}
                            dropHandler={(acceptedFiles: any[]) => {
                              if (acceptedFiles.length > 0) {
                                let formData = new FormData();
                                let file = acceptedFiles[0];
                                setUploadingFileName(file['name']);
                                formData.append('file', file);

                                setIsUploading(true);
                                api.files.uploadFile(formData, 'images').fetch().then((response) => {
                                  let uploaded_file = response as FileUploadResponseObject;
                                  setUploadedFilePath(uploaded_file.file_path);

                                  let client = editedClient;
                                  client.logo_url = uploaded_file.file_url;
                                  setEditedClient(client);
                                }).catch((error) => {
                                  console.error(error);
                                }).finally(() => {
                                  setIsUploading(false);
                                });
                              }
                            }
                            }
                            name={'logo'}
                            shouldOpenBrowseDialog={shouldOpenBrowseDialog}
                            description="Use a JPEG/PNG image no larger than 300x300 pixels."
                          />
                        )
                      )}
                    </div>

                    <div className="o-col-6@xl">
                      <p>Dark theme</p>
                      {isUploadingDark ? (
                        <FileUploadIndicator
                          fileName={uploadingFileNameDark}
                        />
                      ) : (
                        (editedClient.logo_dark_url && editedClient.logo_dark_url !== '*') ? (
                          <LogoPreview
                            filePreviewUrl={sizedGroupLogoImageURL(editedClient.logo_dark_url)}
                            newButtonLabel={"Upload new logo"}
                            removeButtonLabel={"Remove logo"}
                            descriptionLabel={undefined}
                            newHandler={onUploadNewPictureDark}
                            removeHandler={onRemovePictureDark}
                            compactLayout={false}
                            darkMode={true}
                          />
                        ) : (
                          <FileDropzoneField
                            setFieldValue={setFieldValue}
                            dropHandler={(acceptedFiles: any[]) => {
                              if (acceptedFiles.length > 0) {
                                let formData = new FormData();
                                let file = acceptedFiles[0];
                                setUploadingFileNameDark(file['name']);
                                formData.append('file', file);

                                setIsUploadingDark(true);
                                api.files.uploadFile(formData, 'images').fetch().then((response) => {
                                  let uploaded_file = response as FileUploadResponseObject;
                                  setUploadedFilePathDark(uploaded_file.file_path);

                                  let client = editedClient;
                                  client.logo_dark_url = uploaded_file.file_url;
                                  setEditedClient(client);
                                }).catch((error) => {
                                  console.error(error);
                                }).finally(() => {
                                  setIsUploadingDark(false);
                                });
                              }
                            }
                            }
                            name={'logo_dark'}
                            shouldOpenBrowseDialog={shouldOpenBrowseDialogDark}
                            description="Use a JPEG/PNG image no larger than 300x300 pixels."
                          />
                        )
                      )}
                    </div>

                  </div>
                </div>
              </div>
              <div className="o-col-4@md">
                <div className="c-card c-card--bg-light">
                  <div className="c-card__body">
                    {props.isNew ?
                      (
                        <div className="c-card__header">
                          <h6>Create</h6>
                          <div className="c-card__desc">
                            <p>You are adding a new client, you will be able to configure their dashboards and users afterwards.</p>
                          </div>
                        </div>
                      ) : (
                        <div className="c-card__header">
                          <h6>Update</h6>
                          <div className="c-card__desc">
                            {editedClient.updated_at ? (
                              <p>This client was last updated {formatDate(editedClient.updated_at)}.</p>
                            ) : (
                              <p>This is the first update for this client.</p>
                            )}
                          </div>
                        </div>
                      )
                    }
                    <div className="o-row o-row--fluid c-button-group">
                      <div className="o-col">
                        <SubmitButton disabled={isUploading}><span>{props.isNew ? 'Create' : 'Update'} client</span></SubmitButton>
                      </div>
                      <div className="o-col c-button-group__inline">
                        {props.isNew ?
                          (
                            <div onClick={(e) => { e.preventDefault(); history.goBack() }} className="c-link-cta-basic"><span>Cancel</span></div>
                          ) : (
                            <div onClick={(e) => { e.preventDefault(); setIsDeleting(true); }} className="c-link-cta-basic"><IconTrash className="o-svg-icon" /><span>Delete client</span></div>
                          )
                        }
                      </div>
                    </div>
                    <DeleteConfirmationAlert
                      resource_label={editedClient.name}
                      onDelete={() => {
                        onDeleteClient(props.clientId);
                        setIsDeleting(false);
                      }}
                      onCancel={() => {
                        setIsDeleting(false);
                      }}
                      show={isDeleting}
                      type={DeleteConfirmationAlertType.Card}
                    />
                  </div>
                </div>
              </div>
            </div>

          )}
        </Form>
      ) : (
        <ClientGeneralTabSkeleton />
      )
      }
    </>
  );
}

export function ClientGeneralTabSkeleton() {
  return (
    <div className="o-row">
      <div className="o-col-8@md">
        <div className="u-mb-spacer-base-large">
          <h6><Skeleton width={250} /></h6>
          <Skeleton height={50} />
        </div>
        <div className="u-mb-spacer-base-large">
          <h6><Skeleton width={250} /></h6>
          <Skeleton height={50} width={400} />
        </div>
      </div>
      <div className="o-col-4@md">
        <div className="c-card c-card--bg-light">
          <div className="c-card__body">
            <div className="c-card__header">
              <h6><Skeleton width={250} /></h6>
              <div className="c-card__desc">
                <p><Skeleton count={3} /></p>
              </div>
            </div>
            <div className="o-row o-row--fluid c-button-group">
              <div className="o-col">
                <Skeleton height={50} />
              </div>
              <div className="o-col c-button-group__inline">
                <Skeleton height={50} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
