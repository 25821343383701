import * as React from 'react';
// Icons
import { ReactComponent as IconAddItem } from 'assets/svg/add-item.svg';

// Components
import Listing from 'components/cards/listing';
import { listingContext, ListingSortOption, SortDirection } from 'components/cards/listing-context';

import api from 'api';
import useRequest from 'api/use-request';
import { useState, Suspense, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { usersRefresh } from 'utils/users-refresh-singleton';
import DeviceCard from './device';

export interface IDevicesListingProps {
  clientId: string
}

const DevicesListing: React.FunctionComponent<IDevicesListingProps> = ({ clientId }) => {
  let location = useLocation();
  let searchArgs = queryString.parse(location.search, { parseNumbers: true });
  if (searchArgs.client_id === undefined) {
    searchArgs.client_id = clientId;
  }
  if (searchArgs.limit === undefined) {
    searchArgs.limit = 12;
  }

  let [selection, setSelection] = useState<string[]>([]);

  const currentUser = useRequest(api.users.getCurrentUser());
  let devices = useRequest(api.devices.getDevices(searchArgs));

  let sort_options: Array<ListingSortOption> = [
    { key: 'name', title: 'Name, Ascending', direction: SortDirection.Ascending },
    { key: 'name', title: 'Name, Descending', direction: SortDirection.Descending },
    { key: 'created_at', title: 'Created At, Ascending', direction: SortDirection.Ascending },
    { key: 'created_at', title: 'Created At, Descending', direction: SortDirection.Descending },
  ];

  function deleteDevices(ids: string[]) {
    let updates: Promise<unknown>[] = [];
    ids.forEach((id) => {
      updates.push(api.devices.deleteDevice({ id }).fetch());
    });
    Promise.all(updates).finally(() => { 
      devices.mutate();
      setSelection([]);
    });
  }

  function toggleSelectDevices(ids: string[]) {
    ids.forEach((id) => {
      const index = selection.indexOf(id);
      if (index > -1) {
        selection.splice(index, 1);
      } else {
        selection.push(id);
      }
    });
    setSelection([...selection]);
  }

  function selectAll(shouldSelect: boolean) {
    if (devices.data && devices.data?.data.length > 0) {
        devices.data?.data.forEach((device) => {
        const index = selection.indexOf(device.id);
        if (shouldSelect && index < 0) {
          selection.push(device.id);
        }
        if (!shouldSelect && index > -1) {
          selection.splice(index, 1);
        }
      });
      setSelection([...selection]);
    }
  }

  useEffect(() => {
    if (usersRefresh.needsRefresh) {
    devices.mutate();
      usersRefresh.needsRefresh = false;
      setSelection([]);
    }
  });


  return (
    <>
      {(devices.data && devices.data?.data.length < 1) ? (
        <NoDevicesPlaceholder />
      ) : (
        <>
          <listingContext.Provider value={{ ...devices, selection, sort_options }}>
            <Suspense fallback={<div>Loading...</div>}>
              <Listing
                deleteHandler={deleteDevices}
                selectionHandler={toggleSelectDevices}
                selectAllHandler={selectAll}
                settingsKey={'Devices'}
              >
                {devices.data?.data.map((device) =>
                  <DeviceCard
                    deleteHandler={deleteDevices}
                    selectHandler={toggleSelectDevices}
                    key={device.id}
                    device={device}
                  />
                )}
              </Listing>
            </Suspense>
          </listingContext.Provider>
        </>
      )}
    </>
  );
};

interface iNoDevicesPlaceholderProps {
}

function NoDevicesPlaceholder() {
  let history = useHistory();
  let location = useLocation();
  let newDeviceLink = location.pathname + '/new';

  return (
    <div className="u-mb-spacer-base-large">
      <div className="c-add c-add--link u-flex-nowrap@md">
        <div className="c-add__icon">
          <IconAddItem className="o-svg-icon" />
        </div>
        <div className="c-add__body">
          <div className="c-add__title">
            <div onClick={() => { history.push(newDeviceLink) }} className="c-link-cta c-add__link"><span>Add new device</span></div>
          </div>
          <div className="c-add__desc">
            <p>You currently don't have any devices added, click here to start.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DevicesListing;
