import SisenseApiDashboardsProvider, { DashboardInfo } from 'components/dashboard/api-dashboards-provider';
import SisenseRestApiAuth from 'components/dashboard/rest-api-auth';
import * as React from 'react';

import { Switch, Route, useParams } from 'react-router-dom';
import { usersRefresh } from 'utils/users-refresh-singleton';
import { DashboardDetails } from './dashboard-details';

export interface IDashboardEditModalProps {
}

const DashboardEditModal: React.FunctionComponent<IDashboardEditModalProps> = () => {
  let { dashboardId } = useParams() as {
    dashboardId: string | undefined,
  };

  function onUpdate() {
    usersRefresh.needsRefresh = true;
  }

  return (
    <SisenseRestApiAuth>
      {(isApiAuthenticated: boolean, sisenseRequestFailed: boolean) => (
        <SisenseApiDashboardsProvider isSisenseAuthenticated={isApiAuthenticated}>
          {(dashboards: DashboardInfo[] | undefined, sisenseRequestFailed: boolean) => (
            <Switch>
              <Route path={`/dashboards/:dashboardId/edit`}>
                <DashboardDetails
                  isNew={false}
                  nextLocation={`/dashboards`}
                  onUpdate={onUpdate}
                  isProfile={false}
                  dashboards={dashboards}
                />
              </Route>
              <Route path={`/dashboards/:dashboardId/edit`}>
                <DashboardDetails
                  isNew={false}
                  nextLocation={`/dashboards`}
                  onUpdate={onUpdate}
                  isProfile={false}
                  dashboards={dashboards}
                />
              </Route>
              <Route path={`/dashboards/new`}>
                <DashboardDetails
                  isNew={true}
                  nextLocation={`/dashboards`}
                  onUpdate={onUpdate}
                  isProfile={false}
                  dashboards={dashboards}
                />
              </Route>
            </Switch>
        )}
        </SisenseApiDashboardsProvider>
      )}
    </SisenseRestApiAuth>
      
  );
};

export default DashboardEditModal;
