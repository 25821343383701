import * as React from 'react';

// Icon
import { useContext, useRef, useState } from 'react';
import { PageContext } from './page-context';
import { Link } from 'react-router-dom';
import api from 'api';
import useRequest from 'api/use-request';
import useClickAway from 'hooks/use-click-away';
import classNames from 'classnames';
import PageTitle from './page-title';

interface IClientsMenuProps {
}

const ClientsMenu: React.FunctionComponent<IClientsMenuProps> = (props) => {
  const page = useContext(PageContext);
  let user = useRequest(api.users.getCurrentUser());
  let [isOpen, setIsOpen] = useState(false);

  const trigger = useRef<HTMLDivElement>(null);
  useClickAway(trigger, () => setIsOpen(false));

  const ref = useRef(null);

  return (
    <div>
      {user.data && user.data.available_clients.length > 1 ? (
        <div className={classNames(['c-site-header__group', { 'has-popup-open': isOpen }])} ref={trigger}>
          <div ref={ref} className="c-site-header__logo c-popup__pointer" onClick={() => setIsOpen(!isOpen)}>
            <PageTitle maximumCharacters={20} containerRef={ref} />
          </div>
          <div className="c-popup">
            <div className="c-popup__header c-popup__header--sm">
              <p className="c-popup__title">Select Client</p>
            </div>
            <div className="c-popup__body">
              <ul className="c-page-nav__menu">
                {user.data?.available_clients.map((client) =>
                  <li key={client.first_dashboard_id} className={classNames([{ "is-selected": client.id === page.selectedClientId }])}>
                    <Link onClick={() => { setIsOpen(false) }} to={`/dashboard/${client.first_dashboard_id}/${client.first_dataset_id}`}><span>{client.name}</span></Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div ref={ref} className="c-site-header__logo">
          <PageTitle containerRef={ref}/>
        </div>
      )
      }
    </div>
  );
};

export default ClientsMenu;
