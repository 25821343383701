import React, { useState, useEffect } from 'react';
import Routes from 'routes';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import config from '../../../src/client/src/configuration';

function App() {
  const fetchJson = () => {
    fetch(window.location.origin + "/app-version.json")
    .then(response => {
      return response.json();
    }).then(data => {
      config.APP_VERSION = data['APP-VERSION'];
      Sentry.init({
        dsn: config.SENTRY_DSN,
        integrations: [new BrowserTracing()],
        environment: config.APP_ENVIRONMENT,
        release: config.APP_VERSION,
        tracesSampleRate: config.APP_ENVIRONMENT === 'production'? 0.05 : 0.25,
      });
    }).catch((e: Error) => {
      console.log(e.message);
    });
  }

  useEffect(() => {
    fetchJson();
    window.loader(false);
  }, []);
  return (
    <Routes />
  );
}

export default App;
