import * as React from 'react';
import { useContext } from 'react';
import { sizedGroupLogoImageURL } from 'utils/imgix-helpers';
import { PageContext } from './page-context';

export interface IPageTitleProps {
  maximumCharacters?: number,
  containerRef?: any,
}

const PageTitle: React.FunctionComponent<IPageTitleProps> = (props) => {
  const page = useContext(PageContext);
  let title = page.title;

  if (props.maximumCharacters && title.length > props.maximumCharacters) {
    title = title.substr(0, props.maximumCharacters) + '...';
  }

  let logoUrl = page.usesDarkMode ? page.logoDarkURL : page.logoURL;

  return (
    logoUrl ? (
      <img 
      src={sizedGroupLogoImageURL(logoUrl)} 
      onLoad={(event) => {
        const aspectRatio = event.currentTarget.naturalWidth / event.currentTarget.naturalHeight;
        const container = props.containerRef.current;

        container.classList.remove('c-site-header__logo--higher');
        container.classList.remove('c-site-header__logo--highest');

        if (aspectRatio < 0.7) {
          container.classList.add('c-site-header__logo--highest');
        } else if (aspectRatio < 1.5) {
          container.classList.add('c-site-header__logo--higher');
        }
      }}
      />
    ) : (
      <p>{title}</p>
    )
  );
};

export default PageTitle;
