import * as React from 'react';
import * as z from 'zod';

// Components
import { ReactComponent as IconArrow } from 'assets/svg/arrow.svg';
import { Form, FormNotification } from 'components/form/form';
import { InputField, InputFieldType } from 'components/form/input';
import { SubmitButton } from 'components/form/submit-button';

// Types
import { FormFieldVariant } from 'components/form/common';
import { ButtonScope } from 'components/button';

// Hooks
import { Link } from 'react-router-dom';
import { useSearchParam } from 'react-use';

// API
import api from 'api';
import { ResetPasswordRequest } from 'api/endpoints/password';
import { useState } from 'react';

const ResetPasswordPage: React.FunctionComponent = () => {
  let email_param = useSearchParam('email') ?? '';
  let token_param = useSearchParam('token') ?? '';
  let [resetFormSubmitted, setResetFormSubmitted] = useState(false);

  const submitHandler = (data: { email: string, password: string, password_confirmation: string }) => {
    const payload: ResetPasswordRequest = {
      email: data.email,
      password: data.password,
      password_confirmation: data.password_confirmation,
      token: token_param
    };
    return api.password.resetPassword(payload).fetch().then((response) => {
      setResetFormSubmitted(true);
    })
  };

  return (
    <>
      { resetFormSubmitted ? (
        <div className="c-login__flow-wrapper">
          <div className="c-login__flow c-login__flow--visible">
            <div className="c-block__header">
              <h2>Your password has been updated.</h2>
              <p>Login using new password below.</p>
            </div>
          </div>
          <div className="c-login__flow c-login__flow--secondary c-login__flow--visible">
            <div className="u-text-center">
              <p className="u-mb-spacer-base-small"><Link to="/login" className="c-link-cta-basic"><IconArrow className="o-svg-icon o-svg-right" /><span>Login</span></Link></p>
            </div>
          </div>
        </div>
      ) : (
          <div className="c-login__flow-wrapper">
            <div className="c-login__flow c-login__flow--visible">
              <div className="c-block__header">
                <h2>Reset your password.</h2>
                <p>Your password must be at least 8 characters long, contain at least one number, one uppercase or lowercase letter and at least one special character.</p>
              </div>
              <Form
                validationSchema={z.object({
                  email: z.string().email().nonempty(),
                  password: z.string().nonempty(),
                  password_confirmation: z.string().nonempty(),
                })}
                initialValues={{ email: email_param, password: '', password_confirmation: '' }}
                onSubmit={submitHandler}
              >
                  <FormNotification />
                  <InputField
                    type={InputFieldType.email}
                    variant={FormFieldVariant.fill}
                    name="email"
                    autoComplete="email"
                    placeholder="Your email"
                  />
                  <InputField
                    type={InputFieldType.password}
                    variant={FormFieldVariant.fill}
                    name="password"
                    autoComplete="password"
                    placeholder="Your new password"
                  />
                  <InputField
                    type={InputFieldType.password}
                    variant={FormFieldVariant.fill}
                    name="password_confirmation"
                    autoComplete="password_confirmation"
                    placeholder="Your new password confirmation"
                  />

                  <div className="c-form__footer">
                    <SubmitButton fullWidth scope={ButtonScope.primary}><span>Save</span></SubmitButton>
                  </div>
              </Form>
            </div>
            <div className="c-login__flow c-login__flow--secondary c-login__flow--visible">
              <div className="u-text-center">
                <p className="u-mb-spacer-base-small"><Link to="/login" className="c-link-cta-basic"><IconArrow className="o-svg-icon o-svg-left" /><span>Back to login</span></Link></p>
              </div>
            </div>
          </div>
        )
      }
    </>

  );
};

export default ResetPasswordPage;
