import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useRequest from 'api/use-request';
import api from 'api';
import config from '../../../../src/client/src/configuration';
import { DashboardType } from 'api/endpoints/dashboards';
import { PageContext } from 'components/layout/page-context';
import withContext from 'components/util/with-context';
import Skeleton from 'react-loading-skeleton';
import PowerBiDashboardPage from './power-bi-dashboard';


export interface IDashboardPageProps {
}

const DashboardPage = function (props: IDashboardPageProps) {
  let page = useContext(PageContext);
  let [dashboardType, setDastboardType] = useState<DashboardType | undefined>();
  let history = useHistory();

  let { id, datasetId} = useParams() as {
    id: string,
    datasetId: string,
  };

  const dashboardEntry = useRequest(api.dashboards.viewDashboard({
    id: id,
    datasetId: datasetId,
  }));

  useEffect(() => {
    if (!dashboardEntry.isValidating) {
      if (dashboardEntry.error?.code === '404') {
        history.push('/404');
      } else if (dashboardEntry.data?.type !== undefined) {
        setDastboardType(dashboardEntry.data?.type);
      }
    }
  }, [history, dashboardEntry]);

  useEffect(() => {
    if (dashboardEntry.data) {
      page.setTitle(dashboardEntry.data?.client_name);
      page.setSelectedClientId(dashboardEntry.data?.client_id);
      page.setShowsClientSwitcher(true);
      if (dashboardEntry.data?.client_logo_url) {
        page.setLogoURL(dashboardEntry.data?.client_logo_url);
        page.setLogoDarkURL(dashboardEntry.data?.client_logo_dark_url);
      }
      page.setBackLink('')
    } else {
      page.setTitle(' ');
    }
    return () => {
      page.setLogoURL(undefined);
      page.setLogoDarkURL(undefined);
    };
  }, [dashboardEntry, page])

  useEffect(() => {
    page.setShowsDashboardSelector(true);
    return () => {
      page.setShowsDashboardSelector(false);
    };
  }, []);

  return (
    <DashboardComponent dashboardType={dashboardType} />
  );
}

export default DashboardPage;

export interface IDashboardComponentProps {
  dashboardType: DashboardType | undefined;
}

const DashboardComponent = function ({ dashboardType }: IDashboardComponentProps) {
  switch (dashboardType) {
    case DashboardType.powerbi:
          return (
            <PowerBiDashboardPage />
          )
      break;
    case DashboardType.powerbipages:
      return (
        <PowerBiDashboardPage />
      )
      break;
    case DashboardType.url:
      return (
        <div>Generic Iframe dashboard</div>
      )
      break;
    default:
      return (
        <div></div>
      )
      break;
  }

}
