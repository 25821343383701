import * as React from 'react';

import useScript from 'hooks/use-script';
import config from '../../configuration';
import { isFunction } from "lodash";
import { useEffect } from 'react';
import api from 'api';
import { HttpError } from 'api/http-client';

interface ISisenseRestApiAuthProps {
}

const SisenseRestApiAuth: React.FunctionComponent<ISisenseRestApiAuthProps> = ({ children }) => {

  const [sisenseRequestFailed, setSisenseRequestFailed] = React.useState(false);
  const [isSisenseAuthenticated, setIsSisenseAuthenticated] = React.useState(false);
  
  return (
    <>
      {children
        ? isFunction(children)
          ? (children as (isApiAuthenticated: boolean, sisenseRequestFailed: boolean) => React.ReactNode)(isSisenseAuthenticated, sisenseRequestFailed)
          : children
        : null}
    </>
  );
};

export default SisenseRestApiAuth;
