import config from '../../configuration';
import * as React from 'react';
import { createContext, ReactElement, useState } from 'react';
import { SisenseSDK, SisenseJS, SisenseCommon } from './dashboard-types'

export type ISisenseContext = {
  instance?: SisenseJS.App;
  setInstance: (instance: SisenseJS.App) => void;
  clearableFilters: string[];
  addClearableFilter: (sisenseFilterId: string) => void;
  removeClearableFilter: (sisenseFilterId: string) => void;
  isFilterClearable: (sisenseFilterId: string) => boolean;
  canClearFilters: boolean;
  setCanClearFilters: (value: boolean) => void;
  frame?: SisenseSDK.Frame;
  setFrame: (sisenseFrame: SisenseSDK.Frame) => void;
  currentDashboard?: SisenseJS.Dashboard | SisenseSDK.DashboardInfo;
  setCurrentDashboard: (dashboard: SisenseJS.Dashboard | SisenseSDK.DashboardInfo | undefined) => void;
  filters?: SisenseJS.DashboardFilter[];
  setFilters: (filters: SisenseJS.DashboardFilter[] | undefined) => void;
  updateFiltersCallBack: (filters: SisenseCommon.JaqlObject[]) => void;
  isSisenseJS: () => boolean;
  isSisenseSDK: () => boolean;
  refresh?: () => void;
}

export const SisenseContext = createContext({} as ISisenseContext);

export type ISisenseContextProvider = {
  children: ReactElement;
}

export const SisenseContextProvider = ({ children }: ISisenseContextProvider) => {

  const [instance, setInstance] = useState<SisenseJS.App>();
  const [frame, setFrame] = useState<SisenseSDK.Frame>();
  const [currentDashboard, setCurrentDashboard] = useState<SisenseJS.Dashboard | SisenseSDK.DashboardInfo>();
  const [filters, setFilters] = useState<SisenseJS.DashboardFilter[]>();
  const [clearableFilters, setClearableFilters] = useState<string[]>([]);
  const [canClearFilters, setCanClearFilters] = useState(false);

  function updateFiltersCallBack(filters: SisenseCommon.JaqlObject[]) {

  }

  function addClearableFilter(filterId: string) {
    setClearableFilters([...clearableFilters, filterId]);
  }

  function removeClearableFilter(filterId: string) {
    setClearableFilters([...clearableFilters.filter((filter) => filter !== filterId)]);
  }

  function isFilterClearable(filterId: string) {
    return (clearableFilters.filter((filter) => filter === filterId)).length? true : false;
  }

  function isSisenseJS() {
    return _isSisenseJS(currentDashboard);
  }

  function isSisenseSDK() {
    return _isSisenseSDK(currentDashboard);
  }

  function _isSisenseJS(currentDashboard: any): currentDashboard is SisenseJS.Dashboard {
    return ((currentDashboard as SisenseJS.Dashboard).$$model)? true : false;
  }

  function _isSisenseSDK(currentDashboard: any): currentDashboard is SisenseSDK.DashboardInfo {
    return ((currentDashboard as SisenseSDK.DashboardInfo).title)? true : false;
  }


  const initialContext: ISisenseContext = {
    instance,
    setInstance,
    frame,
    setFrame,
    currentDashboard,
    setCurrentDashboard,
    filters,
    setFilters,
    updateFiltersCallBack,
    isSisenseJS,
    isSisenseSDK,
    clearableFilters,
    addClearableFilter,
    removeClearableFilter,
    isFilterClearable,
    canClearFilters,
    setCanClearFilters,
    // setDarkMode,
  }

  return (
    <SisenseContext.Provider value={initialContext}>
      {children}
    </SisenseContext.Provider>
  );
};
