import * as React from 'react';

import { Switch, Route, useParams } from 'react-router-dom';
import { UserDetails } from 'components/cards/user-edit';
import { usersRefresh } from 'utils/users-refresh-singleton';
import { DeviceDetails } from './device-details';

export interface IDeviceEditModalProps {
}

const DeviceEditModal: React.FunctionComponent<IDeviceEditModalProps> = () => {
  let { clientId } = useParams() as {
    clientId: string | undefined,
    deviceId: string | undefined,
  };

  function onUpdate() {
    usersRefresh.needsRefresh = true;
  }

  return (
    <Switch>
      <Route path={`/client/:clientId/devices/:deviceId/edit`}>
        <DeviceDetails
          isNew={false}
          nextLocation={`/client/${clientId}/devices`}
          onUpdate={onUpdate}
        />
      </Route>
      <Route path={`/client/:clientId/devices/new`}>
        <DeviceDetails
          isNew={true}
          nextLocation={`/client/${clientId}/devices`}
          onUpdate={onUpdate}
        />
      </Route>
    </Switch>
  );
};

export default DeviceEditModal;
